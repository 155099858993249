import { Fragment } from "react"

const ProgressLoading = () => {
  return (
    <Fragment>
      <div className="">
        <div className="bg-white px-2 py-5 flex justify-center items-center">
          <div className="w-8 h-8 border-4 border-dashed rounded-full animate-spin border-[#10a37f] mr-3"></div>
          <span>Please wait</span>
        </div>
      </div>
    </Fragment>
  )
}

export default ProgressLoading
