import { useState } from "react"
import ApiService from "../../../services/ApiService"
import Dialog from "../../../components/Dialog"
import Button from "../../../components/Button"
import FileSelector from "../../../components/FileSelector"
import useApiHandler from "../../../hooks/useApiHandler"
import Alert from "../../../components/Alert"
import useToast from "hooks/useToast"

const UploadDocuments = ({ open, setOpen, getDocuments = () => {} }) => {
  const { loading, request, error, message } = useApiHandler()
  const { renderSuccessToast } = useToast()
  const [documents, setDocuments] = useState([])

  const uploadDocuments = async () => {
    const formData = new FormData()
    for (let i = 0; i < documents.length; i++) {
      formData.append("files", documents[i])
    }
    await request({
      api: ApiService.addFinancialDocuments,
      body: formData,
      config: { displayError: false },
    })
    setDocuments([])
    getDocuments()
    setOpen(false)
    renderSuccessToast("Added new documents")
  }

  const onSelectDocuments = (e) => {
    setDocuments([...e.target.files])
  }

  return (
    <Dialog open={open} header="Upload" size="lg">
      <Dialog.Content>
        <FileSelector
          label="Documents (you can choose multiple)"
          multiple
          onSelected={onSelectDocuments}
          description="Choose files to upload"
          onClear={() => setDocuments([])}
        />

        {error && <Alert type="danger"> {message} </Alert>}
      </Dialog.Content>
      <Dialog.Footer>
        <Button
          disabled={documents.length === 0}
          className="sm:ml-3 w-full"
          onClick={uploadDocuments}
          loading={loading}
          loadingText="Uploading..."
        >
          Continue
        </Button>
        <Button variant="secondary" onClick={() => setOpen(false)} className="w-full">
          Cancel
        </Button>
      </Dialog.Footer>
    </Dialog>
  )
}

export default UploadDocuments
