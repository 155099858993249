import { Fragment } from "react"

const Switch = ({ label, onChange = () => {}, checked = false }) => {
  return (
    <Fragment>
      <div>
        {label && <label className="block text-sm font-medium text-gray-700">{label}</label>}
        <label className="relative inline-flex items-center mt-1 cursor-pointer">
          <input
            type="checkbox"
            className="sr-only peer"
            checked={checked || false}
            onChange={onChange}
          />
          <div className="w-11 h-6 bg-gray-200 rounded-full peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-[#10a37f]"></div>
        </label>
      </div>
    </Fragment>
  )
}

export default Switch
