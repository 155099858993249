import { useCallback, useEffect } from "react"
import { useFormik } from "formik"
import { useParams } from "react-router-dom"
import ApiService from "../../services/ApiService"
import Loader from "../../components/Loader"
import TextField from "../../components/TextField"
import Alert from "../../components/Alert"
import * as Yup from "yup"
import useApiHandler from "../../hooks/useApiHandler"
import Button from "../../components/Button"
import Card from "../../components/Card"

const ResetPassword = () => {
  const { token } = useParams()
  const {
    loading: passResetLoading,
    error: passResetError,
    message: passResetMessage,
    request: requestPassReset,
  } = useApiHandler()

  const {
    loading: verifyLinkLoading,
    error: verifyLinkError,
    message: verifyLinkMessage,
    data: verifyLinkData,
    request: requestVerifyLink,
  } = useApiHandler()

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      new_password: "",
      con_new_password: "",
    },
    validationSchema: Yup.object().shape({
      new_password: Yup.string().required("Required"),
      con_new_password: Yup.string()
        .required("Required")
        .min(3, "too short")
        .max(10, "too long")
        .oneOf([Yup.ref("new_password"), null], "Passwords must match"),
    }),
    onSubmit: (values) => {
      passwordReset(values)
    },
  })

  const verify_link = useCallback(async () => {
    requestVerifyLink({
      api: ApiService.verify_reset_password_link,
      body: { token },
      config: { displayError: false },
    })
  }, [requestVerifyLink, token])

  useEffect(() => {
    verify_link()
  }, [verify_link])

  const passwordReset = async () => {
    const user_id = verifyLinkData?.data?.userId

    requestPassReset(
      {
        api: ApiService.reset_password,
        body: {
          user_id,
          new_password: formik.values.new_password,
        },
        config: { displayError: false },
      },
      (_, err) => {
        if (err) return

        window.location.href = "/signin"
      }
    )
  }

  return (
    <section>
      <Loader open={passResetLoading} />
      <div className="flex flex-col items-center px-6 py-8 mx-auto lg:py-0 my-[6rem]">
        <Card className="sm:max-w-md shadow">
          <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
            {verifyLinkLoading && (
              <div className="flex justify-center">
                <div className="w-8 h-8 border-4 border-dashed rounded-full animate-spin border-[#10a37f]"></div>
              </div>
            )}
            {!verifyLinkLoading && verifyLinkError && <Alert type="danger"> {verifyLinkMessage} </Alert>}

            {!verifyLinkLoading && !verifyLinkError && (
              <>
                <h1 className="text-xl font-medium leading-tight text-gray-700 md:text-2xl">
                  Reset Password
                </h1>
                <form className="space-y-4 md:space-y-6">
                  <div className="col-span-6 sm:col-span-3">
                    <TextField
                      label="New password"
                      name="new_password"
                      type="password"
                      value={formik.values.new_password}
                      onChange={formik.handleChange}
                      error={formik.errors.new_password}
                      touched={formik.touched.new_password}
                      onBlur={formik.handleBlur}
                    />
                  </div>
                  <div className="col-span-6 sm:col-span-3">
                    <TextField
                      label="Confirm new password"
                      name="con_new_password"
                      type="password"
                      value={formik.values.con_new_password}
                      onChange={formik.handleChange}
                      error={formik.errors.con_new_password}
                      touched={formik.touched.con_new_password}
                      onBlur={formik.handleBlur}
                    />
                  </div>

                  {!passResetLoading && !passResetError && passResetMessage !== null && (
                    <Alert>{passResetMessage}</Alert>
                  )}

                  {!passResetLoading && passResetError && passResetMessage !== null && (
                    <Alert type="danger">{passResetMessage}</Alert>
                  )}

                  <Button onClick={formik.handleSubmit}>Reset Password</Button>
                </form>
              </>
            )}
          </div>
        </Card>
      </div>
    </section>
  )
}

export default ResetPassword
