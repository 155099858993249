import { useState } from "react"
import CustomDialog from "components/Dialog"
import Button from "components/Button"
import SelectMenu from "components/SelectMenu"
import { logTypes, logStatus, requestMethods } from "./helpers"
import SelectDate from "components/SelectDate"
import moment from "moment"

const Filters = ({ getLogs = () => {} }) => {
  const [openFilters, setOpenFilters] = useState(false)

  const [logName, setlogName] = useState(null)
  const [status, setstatus] = useState(null)
  const [requestType, setRequestType] = useState(null)
  const [startDate, setstartDate] = useState(null)
  const [endDate, setendDate] = useState(null)

  const onApply = () => {
    let filterObj = {}

    if (logName) filterObj["name"] = logName
    if (status !== null) filterObj["requestStatus"] = status
    if (requestType) filterObj["requestType"] = requestType
    if (startDate) filterObj["startDate"] = startDate
    if (endDate) filterObj["endDate"] = endDate

    getLogs(filterObj)
    setOpenFilters(false)
  }

  const onResetFilters = () => {
    setlogName(null)
    setstatus(null)
    setRequestType(null)
    setstartDate(null)
    setendDate(null)

    getLogs()
  }

  return (
    <section>
      <Button variant="secondary" onClick={() => setOpenFilters(true)}>
        Filters
      </Button>

      {(logName || status !== null || requestType || startDate || endDate) && (
        <span className="text-sm m-4 text-gray-700 cursor-pointer" onClick={onResetFilters}>
          Clear
        </span>
      )}

      {openFilters && (
        <CustomDialog open={openFilters} header="Filters" size="lg" exceptions={["SET_OVERFLOW_UNSET"]}>
          <CustomDialog.Content>
            <form className="space-y-4 md:space-y-6">
              <div>
                <SelectMenu
                  label="Log Type"
                  items={logTypes}
                  value={logName}
                  onChange={(data) => {
                    setlogName(data.value)
                  }}
                />
              </div>
              <div>
                <SelectMenu
                  label="Status"
                  items={logStatus}
                  value={status}
                  onChange={(data) => {
                    setstatus(data.value)
                  }}
                />
              </div>
              <div>
                <SelectMenu
                  label="Request Method"
                  items={requestMethods}
                  value={requestType}
                  onChange={(data) => {
                    setRequestType(data.value)
                  }}
                />
              </div>
              <div>
                <SelectDate
                  label="Date Range"
                  name="dateRange"
                  value={[startDate, endDate]}
                  options={{ mode: "range" }}
                  onChange={({ date }) => {
                    const [startDate, endDate] = date
                    setstartDate(moment(startDate).format("YYYY-MM-DD"))
                    setendDate(moment(endDate).format("YYYY-MM-DD"))
                  }}
                />
              </div>
            </form>
          </CustomDialog.Content>
          <CustomDialog.Footer>
            <Button
              onClick={onApply}
              className="sm:ml-3 w-full"
              // loading={loading}
            >
              Apply
            </Button>
            <Button onClick={() => setOpenFilters(false)} variant="secondary" className="w-full">
              Cancel
            </Button>
          </CustomDialog.Footer>
        </CustomDialog>
      )}
    </section>
  )
}

export default Filters
