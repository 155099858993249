const BackButton = ({ onClick = () => {}, currentPath }) => {
  return (
    <>
      <div className="container mx-auto px-4 pt-8 pb-3">
        {currentPath !== "/gallery" && (
          <button
            onClick={onClick}
            title="Delete project"
            className="text-gray-700 px-2 py-1 border border-gray-300 rounded-lg hover:bg-gray-200 transition duration-100"
          >
            Back
          </button>
        )}
      </div>
    </>
  )
}

export default BackButton
