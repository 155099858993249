import { Dialog } from "@headlessui/react"
import { ExclamationTriangleIcon } from "@heroicons/react/24/outline"
import CustomDialog from "../../../components/Dialog"
import Button from "../../../components/Button"

export default function Modal({ open, setOpen, text }) {
  return (
    <CustomDialog open={open}>
      <CustomDialog.Content>
        <div className="sm:flex sm:items-start">
          <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-yellow-100 sm:mx-0 sm:h-10 sm:w-10 shadow">
            <ExclamationTriangleIcon className="h-6 w-6 text-yellow-600" aria-hidden="true" />
          </div>
          <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
            <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
              Warning
            </Dialog.Title>
            <div className="mt-2">
              <p className="text-sm text-gray-500">{text}</p>
            </div>
          </div>
        </div>
      </CustomDialog.Content>
      <CustomDialog.Footer>
        <Button variant="secondary" onClick={() => setOpen(false)}>
          Close
        </Button>
      </CustomDialog.Footer>
    </CustomDialog>
  )
}
