import SelectMenu from "components/SelectMenu"
import { useEffect, useState } from "react"
import ApiService from "services/ApiService"
import Loader from "components/Loader"
import Table from "components/Table/Table"
import Button from "components/Button"
import useApiHandler from "hooks/useApiHandler"

const Volunteers = () => {
  const { request: requestEvents, loading: loadingEvents } = useApiHandler()

  const { request: requestVolunteers, loading: loadingVolunteers } = useApiHandler()

  const [events, setEvents] = useState([])
  const [volunteers, setVolunteers] = useState([])
  const [selectEvtErr, setSelectEvtErr] = useState(null)
  const [eventId, setEventId] = useState(null)

  const columns = [
    {
      name: "Name",
      selector: "name",
      cell: (row) => <span>{row.name}</span>,
    },
    {
      name: "Email",
      selector: "email",
    },
    {
      name: "Phone",
      selector: "phone",
    },
    {
      name: "Event Name",
      selector: "eventName",
    },
  ]

  useEffect(() => {
    getEvents()
  }, [])

  const getEvents = async () => {
    requestEvents(
      {
        api: ApiService.getAllEvents,
        config: {
          displayError: true,
        },
      },
      (res, err) => {
        if (err) return

        let eventsItems = [
          {
            name: "General",
            value: "General",
          },
        ]
        res.data?.events.map((evts) => {
          eventsItems.push({
            name: evts["eventName"],
            value: evts["_id"],
          })
        })
        setEvents(eventsItems)
      }
    )
  }

  const getVolunteers = async () => {
    if (eventId === null) {
      setSelectEvtErr("Please select an event.")
      return
    }

    requestVolunteers(
      {
        api: ApiService.getVolunteersByEventId,
        body: eventId,
        config: {
          displayError: true,
        },
      },
      (res, err) => {
        if (err) return

        let volnters = []
        res.data?.volunteers.forEach((it) => {
          volnters.push({
            name: it["name"],
            email: it["email"],
            phone: it["phone"],
            eventName: it["event"] ? it["event"]["eventName"] : "General",
          })
        })
        setVolunteers(volnters)
      }
    )
  }

  return (
    <section>
      <Loader open={loadingEvents || loadingVolunteers} />

      <header className="bg-white shadow">
        <div className="mx-auto py-3 px-4 sm:px-6 lg:px-8 flex justify-between">
          <h1 className="text-2xl font-bold tracking-tight text-gray-900">Volunteers</h1>
        </div>
      </header>

      <div className="mx-8 my-4">
        <div className="max-w-[20rem]">
          <SelectMenu
            label="Choose an event"
            items={events}
            value={eventId}
            onChange={(data) => {
              setEventId(data.value)
              setSelectEvtErr(null)
            }}
            hasError={selectEvtErr}
            error={selectEvtErr}
            required
          />

          <Button onClick={getVolunteers} className="mt-3">
            Show List
          </Button>
        </div>

        <Table columns={columns} data={volunteers} />
      </div>
    </section>
  )
}

export default Volunteers
