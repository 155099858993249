import { useState } from "react"
import { useFormik } from "formik"
import TextField from "components/TextField"
import * as Yup from "yup"
import ApiService from "services/ApiService"
import useApiHandler from "hooks/useApiHandler"
import useToast from "hooks/useToast"
import Dialog from "components/Dialog"
import FileSelector from "components/FileSelector"
import Alert from "components/Alert"
import ProgressLoading from "components/ProgressLoading"
import Button from "components/Button"

const AddFolder = ({ open, setOpen, type, folderInfo = null, getFolders = () => {} }) => {
  const { request, loading } = useApiHandler()

  const { renderSuccessToast } = useToast()

  const [images, setImages] = useState([])
  const [uploadError, setUploadError] = useState(null)

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      folderName: "",
    },
    validationSchema: Yup.object().shape({
      folderName: Yup.string().required("Required"),
    }),
    onSubmit: () => {
      addFolderOrFiles()
    },
  })

  const addFolderOrFiles = async () => {
    let api = null
    let body = null

    if (type === "folder") {
      body = {
        folderName: formik.values.folderName,
        path: folderInfo.path,
        folderId: folderInfo._id,
      }
      api = ApiService.addFolder
    }

    if (type === "file") {
      if (images.length <= 0) {
        setUploadError("Please choose images")
        return
      }
      const formData = new FormData()
      formData.append("folderId", folderInfo?._id)

      for (let i = 0; i < images.length; i++) {
        formData.append("files", images[i])
      }

      api = ApiService.uploadGalleryFiles
      body = formData
    }

    if (!api && !body) return

    request(
      {
        api: api,
        body: body,
        config: {
          displayError: false,
        },
      },
      (_, err) => {
        if (err) {
          setUploadError(err?.response?.data?.message)
          return
        }

        formik.resetForm()
        setImages([])
        setOpen(false)
        getFolders(folderInfo.path)
        setUploadError(null)
        renderSuccessToast(type === "folder" ? "Folder Added" : "Images Uploaded")
      }
    )
  }

  const onSelectImages = (e) => {
    setImages([...e.target.files])
  }

  return (
    <Dialog open={open} header={type === "folder" ? "Create Folder" : "Upload Images"}>
      <Dialog.Content>
        {!loading && type === "file" && (
          <FileSelector
            description="Choose images to upload"
            multiple={true}
            preview={false}
            onSelected={onSelectImages}
            onClear={() => setImages([])}
            accept="image/*"
            helpText="(Multiple files accepted)"
          />
        )}

        {!loading && type === "folder" && (
          <div>
            <TextField
              label="Folder Name"
              name="folderName"
              type="text"
              value={formik.values.folderName}
              onChange={formik.handleChange}
              error={formik.errors.folderName}
              touched={formik.touched.folderName}
              onBlur={formik.handleBlur}
              required
            />
          </div>
        )}

        <Alert type="error">{uploadError}</Alert>
        {loading && <ProgressLoading />}
      </Dialog.Content>
      <Dialog.Footer>
        <Button
          className="sm:ml-3 w-full"
          onClick={type === "folder" ? formik.handleSubmit : addFolderOrFiles}
        >
          Continue
        </Button>
        <Button
          variant="secondary"
          className="w-full"
          onClick={() => {
            setImages([])
            setUploadError(null)
            formik.resetForm()
            setOpen(false)
          }}
        >
          Cancel
        </Button>
      </Dialog.Footer>
    </Dialog>
  )
}

export default AddFolder
