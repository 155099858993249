import { Fragment, useState, forwardRef, useImperativeHandle } from "react"

const FileSelector = forwardRef(
  (
    {
      label,
      helpText = "",
      preview = false,
      description = "Choose a file to upload",
      onSelected = () => {},
      onClear = () => {},
      accept = "",
      multiple,
      disabled = false,
      required = false,
      error = false,
      maxSize = 50
    },
    ref
  ) => {
    const [file, setFile] = useState(null)
    const [files, setFiles] = useState([])
    const [previewUrl, setPreviewUrl] = useState(null)
    const [localError, setLocalError] = useState(error)

    // const onSelectFile = (e) => {
    //   if (multiple) {
    //     setFiles([...e.target.files])
    //   } else setFile(e.target.files[0])
    //   if (!multiple && e.target.files && e.target.files[0]) {
    //     setPreviewUrl(URL.createObjectURL(e.target.files[0]))
    //   }
    //   onSelected(e)
    // }

    const maxFileSize = maxSize * 1024 * 1024; // 50 MB

    const onSelectFile = (e) => {
      const selectedFiles = e.target.files;
      if (multiple) {
        const totalSize = Array.from(selectedFiles).reduce((acc, file) => acc + file.size, 0);
        if (totalSize > maxFileSize) {
          setLocalError(`Total file size exceeds the ${maxSize} MB limit.`);
          // setFiles([]); // Optionally clear the file selection
        } else {
          setLocalError("");
          setFiles(Array.from(selectedFiles));
        }
      } else {
        const singleFile = selectedFiles[0];
        if (singleFile.size > maxFileSize) {
          setLocalError("File size exceeds 50 MB limit.");
          setFile(null);
          setPreviewUrl(null);
        } else {
          setLocalError("");
          setFile(singleFile);
          setPreviewUrl(URL.createObjectURL(singleFile));
        }
      }
      onSelected(e);
    };

    const clearFiles = () => {
      onClear()
      setFile(null)
      setFiles([])
    }

    useImperativeHandle(ref, () => ({
      clearFiles,
      localError
    }))

    return (
      <Fragment>
        <label className="block text-sm font-medium text-gray-700 !mb-1">
          {label && (
            <>
              {label} {required && <span className="text-md text-red-500">*</span>}{" "}
            </>
          )}{" "}
          {helpText !== "" && <span className="text-xs">{helpText}</span>}
          <br />
          <span className="text-xs text-[#10a37f]">{'"Please attach your documents below. The total size of all files combined should be less than 50MB."'}</span>
        </label>

        <div
          className={`relative border-2 border-dashed rounded-md p-6 !mt-0 flex flex-col justify-center items-center ${
            localError ? "border-red-500" : "border-gray-400"
          }`}
        >
          <div className="text-gray-600">
            <span className="mt-1 text-sm leading-normal">
              {!multiple && Single(file, setFile, setPreviewUrl, clearFiles, description)}
              {multiple && Multiple(files, setFiles, clearFiles, description)}
            </span>
          </div>
          <input
            id="file-upload"
            name="file-upload"
            type="file"
            className="hidden disabled:cursor-not-allowed"
            onChange={onSelectFile}
            accept={accept}
            multiple={multiple}
            disabled={disabled}
          />

          {!multiple && preview && previewUrl && (
            <img width={"20%"} height={"20%"} alt="preview" src={previewUrl} />
          )}
        </div>
        {localError && <span className="text-red-600 text-xs">{localError}</span>}
      </Fragment>
    )
  }
)

const Single = (file, setFile, setPreviewUrl, clearFiles, description) => {
  return (
    <Fragment>
      {!file && (
        <>
          <label htmlFor="file-upload" className="text-blue-500 cursor-pointer hover:underline">
            {description}
          </label>
        </>
      )}
      {file && (
        <>
          Selected file {"->"} {file.name} <br />
          <label htmlFor="file-upload" className="text-blue-500 cursor-pointer hover:underline mt-5">
            change
          </label>
          <label
            onClick={() => {
              setFile(null)
              setPreviewUrl(null)
              clearFiles()
            }}
            className="text-red-500 cursor-pointer hover:underline ml-5"
          >
            Clear
          </label>
        </>
      )}
    </Fragment>
  )
}

const Multiple = (files, setFiles, clearFiles = () => {}, description) => {
  return (
    <Fragment>
      {files.length === 0 && (
        <>
          <label htmlFor="file-upload" className="text-blue-500 cursor-pointer hover:underline">
            {description}
          </label>
        </>
      )}
      {files.length > 0 && (
        <>
          Selected Files: <br />
          <ul className="max-w-md space-y-1 text-gray-500 list-disc list-inside dark:text-gray-400">
            {files && files.map((doc, docindex) => <li key={docindex}>{doc.name}</li>)}
          </ul>
          <label htmlFor="file-upload" className="text-blue-500 cursor-pointer hover:underline mt-5">
            change
          </label>
          <label
            onClick={() => {
              setFiles([])
              clearFiles()
            }}
            className="text-red-500 cursor-pointer hover:underline ml-5"
          >
            Clear
          </label>
        </>
      )}
    </Fragment>
  )
}

export default FileSelector
