import { useEffect, useState, useImperativeHandle, useRef, forwardRef } from "react"
import { useFormik } from "formik"
import TextField from "../../../components/TextField"
import TextArea from "../../../components/Textarea"
import SelectMenu from "../../../components/SelectMenu"
import SelectDate from "../../../components/SelectDate"
import * as Yup from "yup"
import ApiService from "../../../services/ApiService"
import moment from "moment"
import commonFuctions from "../../../utils/CommonFunctions"
import useApiHandler from "hooks/useApiHandler"
import useToast from "hooks/useToast"
import Button from "components/Button"
import Alert from "components/Alert"
import FileSelector from "components/FileSelector"
import CustomDialog from "components/Dialog"
import EventUtils from "../utils"

const AddEvent = ({ open, setOpen, getAllEvents = () => {} }) => {
  const { loading, request, error, message } = useApiHandler()

  const { renderSuccessToast } = useToast()

  const [eventType, setEventType] = useState(null)
  const [eventTypeError, setEventTypeError] = useState(null)

  const [eventStartDate, setEventStartDate] = useState(null)
  const [eventStartDateError, setEventStartDateError] = useState(false)
  const [eventEndDate, setEventEndDate] = useState(null)
  const [eventEndDateError, setEventEndDateError] = useState(false)
  const [minDates, setMinDates] = useState({
    eventStartDate: "today",
    eventEndDate: "today",
  })
  const [dateError, setDateError] = useState(false)
  const [flyer, setFlyer] = useState(null)

  const [allFieldsValidated, setAllFieldsValidated] = useState(false)

  // Event Cost
  const [costs, setCosts] = useState([
    {
      name: "Event Cost",
      value: "default",
      cost: "",
      touched: false,
      error: false,
    },
  ])

  const costsRef = useRef(null)

  useEffect(() => {
    if (eventType && eventType.value) {
      let _costs = []
      EventUtils.categories[eventType.value].forEach((item) => {
        _costs.push({
          name: item["name"] + " Cost",
          value: item["key"],
          cost: "",
          touched: false,
          error: false,
        })
      })
      setCosts(_costs)
    }
  }, [eventType])

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      eventName: "",
      eventDescription: "",
      eventOrganizers: "",
      // eventCost: "",
      eventVenue: "",
      maxPlayers: "",
      websiteUrl: "",
      geoLocation: ''
    },
    validationSchema: Yup.object().shape({
      eventName: Yup.string().required("Required"),
      eventDescription: Yup.string().required("Required"),
      eventOrganizers: Yup.string().required("Required"),
      // eventCost: eventType?.value === "tennis" ? null : Yup.string().required("Required"),
      eventVenue: Yup.string().required("Required"),
      maxPlayers: Yup.string().required("Required"),
    }),
    onSubmit: () => {
      if (!allFieldsValidated) return

      addEvent()
    },
  })

  const validateOtherFields = () => {
    let hasIssues = false

    // Validate event type
    if (eventType == null || eventType?.value === null) {
      setEventTypeError("Required")
      hasIssues = true
    }

    // Validate event costs
    hasIssues = costsRef.current.validateFields()

    // Validate event start date
    if (eventStartDate === null) {
      setEventStartDateError("Required")
      hasIssues = true
    }

    // Validate event end date
    if (eventEndDate === null) {
      setEventEndDateError("Required")
      hasIssues = true
    }

    setAllFieldsValidated(!hasIssues)

    return hasIssues
  }

  const addEvent = async () => {
    try {
      const formData = new FormData()
      formData.append("eventName", formik.values.eventName.trim())
      formData.append("eventDescription", formik.values.eventDescription.trim())
      formData.append("eventType", JSON.stringify(eventType))
      formData.append("eventStartDate", commonFuctions.formatDate(eventStartDate))
      formData.append("eventEndDate", commonFuctions.formatDate(eventEndDate))
      formData.append("eventOrganizers", formik.values.eventOrganizers.trim())
      // formData.append("eventCost", formik.values.eventCost)
      formData.append("eventCost", JSON.stringify(costsRef.current.getCosts()))
      formData.append("eventVenue", formik.values.eventVenue.trim())
      formData.append("geoLocation", formik.values.geoLocation.trim())
      formData.append("maxPlayers", formik.values.maxPlayers)
      formData.append("websiteUrl", formik.values.websiteUrl.trim())
      formData.append("flyer", flyer)

      request(
        {
          api: ApiService.addEvent,
          body: formData,
          config: {
            displayError: false,
          },
        },
        (_, err) => {
          if (err) return

          formik.resetForm()
          getAllEvents()
          setOpen(false)
          setFlyer(null)
          setEventStartDate(null)
          setEventEndDate(null)
          setEventType(null)

          renderSuccessToast("Event Created")
        }
      )
    } catch (error) {
      console.log(error)
    }
  }

  const onSelectFlyer = (e) => {
    setFlyer(e.target.files[0])
  }

  return (
    <CustomDialog open={open} header="Add Event" size="lg">
      <CustomDialog.Content>
        <form className="space-y-4 md:space-y-6">
          <div>
            <SelectMenu
              label="Event Type"
              items={EventUtils.eventTypes}
              value={eventType?.value}
              onChange={(data) => {
                setEventType(data)
                setEventTypeError(null)
              }}
              hasError={eventTypeError}
              error={eventTypeError}
              required
            />
          </div>
          <div>
            <TextField
              label="Event Name"
              name="eventName"
              type="text"
              value={formik.values.eventName}
              onChange={formik.handleChange}
              error={formik.errors.eventName}
              touched={formik.touched.eventName}
              onBlur={formik.handleBlur}
              required
              disabled={!eventType}
            />
          </div>
          <div>
            <TextArea
              label="Event description"
              name="eventDescription"
              type="text"
              value={formik.values.eventDescription}
              onChange={formik.handleChange}
              error={formik.errors.eventDescription}
              touched={formik.touched.eventDescription}
              onBlur={formik.handleBlur}
              required
              disabled={!eventType}
            />
          </div>

          <div className="grid grid-cols-1 gap-6 mt-4 sm:grid-cols-2">
            <div>
              <SelectDate
                label="Event Start Date"
                name="causeStartDate"
                value={eventStartDate}
                onChange={({ date }) => {
                  setEventStartDate(date)
                  setEventStartDateError(null)
                  setDateError(null)
                  setMinDates({
                    ...minDates,
                    eventEndDate: moment(date).toDate(),
                  })
                }}
                options={{
                  minDate: minDates["eventStartDate"],
                }}
                hasError={eventStartDateError}
                error={eventStartDateError}
                required
                disabled={!eventType}
              />
            </div>
            <div>
              <SelectDate
                label="Event End Date"
                name="eventEndDate"
                value={eventEndDate}
                onChange={({ date }) => {
                  if (!moment(date).isSameOrAfter(moment(eventStartDate))) {
                    alert("Error")
                  }
                  setEventEndDate(date)
                  setEventEndDateError(null)
                }}
                options={{
                  minDate: minDates["eventEndDate"],
                  onOpen: [
                    function (selectedDates, dateStr, instance) {
                      if (eventStartDate === null) {
                        setDateError(true)
                        instance.close()
                      }
                    },
                  ],
                }}
                hasError={eventEndDateError}
                error={eventEndDateError}
                required
                disabled={!eventType}
              />
              {dateError && (
                <div className="mt-1 text-xs text-red-400">
                  Please select <b>Event Start Date</b>
                </div>
              )}
            </div>
          </div>

          <div>
            <TextField
              label="Event organizers"
              name="eventOrganizers"
              type="text"
              value={formik.values.eventOrganizers}
              onChange={formik.handleChange}
              error={formik.errors.eventOrganizers}
              touched={formik.touched.eventOrganizers}
              onBlur={formik.handleBlur}
              required
              disabled={!eventType}
            />
          </div>

          <RenderCost
            costs={costs}
            setCosts={setCosts}
            eventType={eventType}
            ref={costsRef}
            disabled={!eventType}
          />

          <div>
            <TextField
              label="Event Venue"
              name="eventVenue"
              type="text"
              value={formik.values.eventVenue}
              onChange={formik.handleChange}
              error={formik.errors.eventVenue}
              touched={formik.touched.eventVenue}
              onBlur={formik.handleBlur}
              required
              disabled={!eventType}
            />
          </div>
          <div>
            <TextField
              label="Geo Location"
              name="geoLocation"
              type="text"
              value={formik.values.geoLocation}
              onChange={formik.handleChange}
              error={formik.errors.geoLocation}
              touched={formik.touched.geoLocation}
              onBlur={formik.handleBlur}
              disabled={!eventType}
            />
          </div>
          <div>
            <TextField
              label="Max players"
              name="maxPlayers"
              type="text"
              value={formik.values.maxPlayers}
              onChange={formik.handleChange}
              error={formik.errors.maxPlayers}
              touched={formik.touched.maxPlayers}
              onBlur={formik.handleBlur}
              required
              disabled={!eventType}
            />
          </div>
          <div>
            <TextField
              label="Website url"
              name="websiteUrl"
              type="text"
              value={formik.values.websiteUrl}
              onChange={formik.handleChange}
              error={formik.errors.websiteUrl}
              touched={formik.touched.websiteUrl}
              onBlur={formik.handleBlur}
              disabled={!eventType}
            />
          </div>

          <FileSelector
            label="Flyer"
            onSelected={onSelectFlyer}
            onClear={() => setFlyer(null)}
            disabled={!eventType}
          />
        </form>

        {error && <Alert type="danger">{message}</Alert>}
      </CustomDialog.Content>
      <CustomDialog.Footer>
        <Button
          onClick={() => {
            validateOtherFields()
            formik.handleSubmit()
          }}
          className="sm:ml-3 w-full"
          loading={loading}
        >
          Continue
        </Button>
        <Button
          variant="secondary"
          onClick={() => {
            formik.resetForm()
            setOpen(false)
          }}
          className="w-full"
        >
          Cancel
        </Button>
      </CustomDialog.Footer>
    </CustomDialog>
  )
}

const RenderCost = forwardRef(({ costs, disabled }, ref) => {
  const [fields, setFields] = useState([])

  useEffect(() => {
    setFields(costs)
  }, [costs])

  useImperativeHandle(ref, () => ({
    validateFields() {
      let hasError = false
      setFields(
        fields.map((it) => {
          if (it["cost"] === "" || it["cost"] === 0) {
            it["error"] = "Required"
            it["touched"] = true
            hasError = true
          } else {
            it["error"] = false
          }
          return it
        })
      )
      return hasError
    },
    getCosts() {
      const mapped = fields.map((item) => ({
        [item.value]: item.cost,
      }))
      return Object.assign({}, ...mapped)
    },
  }))

  return (
    <div className="grid grid-cols-1 gap-6 mt-4 sm:grid-cols-2">
      {fields.map((currField, idx) => (
        <div key={idx}>
          <TextField
            label={currField.name}
            name={currField.value}
            type="number"
            value={currField.cost}
            onChange={(e) => {
              setFields(
                fields.map((it) => {
                  if (it.value === currField.value) {
                    it["cost"] = e.target.value
                    if (it["cost"] === "") it["error"] = "Required"
                    else it["error"] = false
                  }
                  return it
                })
              )
            }}
            error={currField.error}
            touched={currField.touched}
            onBlur={() => {
              setFields(
                fields.map((it) => {
                  if (it.value === currField.value) {
                    it["touched"] = true
                    if (currField.cost === "") it["error"] = "Required"
                  }
                  return it
                })
              )
            }}
            required
            disabled={disabled}
          />
        </div>
      ))}
    </div>
  )
})

export default AddEvent
