import { useFormik } from "formik"
import * as Yup from "yup"
import TextField from "../../../../components/TextField"
import { useState } from "react"
import commonFunctions from "utils/CommonFunctions"
import Button from "components/Button"
import RadioButton from "components/RadioButton"

const Run = ({ registerEvent, event = null }) => {
  const [category, setCategory] = useState(null)
  const [categoryError, setCategoryError] = useState(false)
  const [registerThrough, setRegisterThrough] = useState(null)
  const costType = "default"

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      primaryContactName: "",
      email: "",
      confirmEmail: "",
      phone: "",
      alternatePhone: "",
    },
    validationSchema: Yup.object().shape({
      primaryContactName: Yup.string().required("Required"),
      email: Yup.string().required("Required"),
      confirmEmail: Yup.string()
        .required("Required")
        .oneOf([Yup.ref("email"), null], "Email must match"),
      phone: Yup.string().required("Required"),
    }),
    onSubmit: (values) => {
      if (category === null) {
        setCategoryError(true)
      } else {
        values["registerThrough"] = registerThrough
        values["teamType"] = category
        values["eventCost"] = event.eventCost[costType]
        values["phone"] = commonFunctions.phoneNumberToText(values["phone"])
        values["alternatePhone"] = commonFunctions.phoneNumberToText(values["alternatePhone"])
        registerEvent(values)
      }
    },
  })

  return (
    <section>
      <form className="space-y-4 md:space-y-6">
        <div className="grid grid-cols-1 gap-6 mt-4 sm:grid-cols-2">
          <div>
            <TextField
              label="Name of primary contact"
              name="primaryContactName"
              type="text"
              value={formik.values.primaryContactName}
              onChange={formik.handleChange}
              error={formik.errors.primaryContactName}
              touched={formik.touched.primaryContactName}
              onBlur={formik.handleBlur}
              required
            />
          </div>
        </div>
        <div className="grid grid-cols-1 gap-6 mt-4 sm:grid-cols-2">
          <div>
            <TextField
              label="Email"
              name="email"
              type="text"
              value={formik.values.email}
              onChange={formik.handleChange}
              error={formik.errors.email}
              touched={formik.touched.email}
              onBlur={formik.handleBlur}
              required
            />
          </div>
          <div>
            <TextField
              label="Confirm Email"
              name="confirmEmail"
              type="text"
              value={formik.values.confirmEmail}
              onChange={formik.handleChange}
              error={formik.errors.confirmEmail}
              touched={formik.touched.confirmEmail}
              onBlur={formik.handleBlur}
              required
            />
          </div>

          <div>
            <TextField
              label="Phone number"
              name="phone"
              type="text"
              placeholder="(XXX) XXX-XXXX"
              phoneNumberFormat={true}
              value={formik.values.phone}
              onChange={formik.handleChange}
              error={formik.errors.phone}
              touched={formik.touched.phone}
              onBlur={formik.handleBlur}
              required
            />
          </div>
          <div>
            <TextField
              label="Alternate phone number"
              name="alternatePhone"
              type="text"
              placeholder="(XXX) XXX-XXXX"
              phoneNumberFormat={true}
              value={formik.values.alternatePhone}
              onChange={formik.handleChange}
              error={formik.errors.alternatePhone}
              touched={formik.touched.alternatePhone}
              onBlur={formik.handleBlur}
            />
          </div>
        </div>

        <div>
          <label htmlFor={"category"} className="block text-sm font-medium text-gray-700 mb-3">
            Participant Category
            <span className="text-md text-red-500">*</span>
          </label>
          <div className="flex items-center mb-2">
            <RadioButton
              name="6K"
              value="6K"
              onChange={(e) => {
                setCategory(e.target.value)
                setCategoryError(false)
              }}
              error={categoryError}
            >
              6K
            </RadioButton>
          </div>
          <div className="flex items-center">
            <RadioButton
              name="10K"
              value="10K"
              onChange={(e) => {
                setCategory(e.target.value)
                setCategoryError(false)
              }}
              error={categoryError}
            >
              10K
            </RadioButton>
          </div>
          {categoryError && <span className="text-red-600 text-xs">Required</span>}
        </div>
      </form>

      <div className="bg-gray-50 py-3 sm:flex sm:flex-row-reverse">
        <Button
          onClick={() => {
            setRegisterThrough("OFFLINE")
            formik.handleSubmit()
          }}
          className="ml-3"
        >
          Register Offline
        </Button>
        <Button
          onClick={() => {
            setRegisterThrough("PAYPAL")
            formik.handleSubmit()
          }}
        >
          Register With Paypal
        </Button>
      </div>
    </section>
  )
}

export default Run
