import Table from "components/Table/Table"
import moment from "moment"
import { useState, useEffect } from "react"
import ApiService from "services/ApiService"
import Loader from "components/Loader"
import ConfirmationWindow from "components/ConfirmationWindow"
import useApiHandler from "hooks/useApiHandler"
import useToast from "hooks/useToast"

const DeleteProject = () => {
  const {
    request: requestProjects,
    loading: projectsLoading,
    data: projectsData,
    error: projectsError,
    message: projectsErrorMessage,
  } = useApiHandler()

  const { request: requestDeleteProject, loading: deleteProjectLoading } = useApiHandler()

  const { renderSuccessToast } = useToast()

  const [openConfirmationWindow, setOpenCOnfirmationWindow] = useState(false)
  const [currProjectId, setCurrProjectId] = useState(null)

  const columns = [
    {
      name: "Project",
      selector: "project",
      cell: (row) => <span>{row.projectName}</span>,
    },
    {
      name: "Start Date",
      selector: "projectStartDate",
      cell: (row) => (
        <span>
          <strong>{moment.utc(row.startDate).format("DD-MMM-YYYY")}</strong>
        </span>
      ),
    },
    {
      name: "End Date",
      selector: "projectEndDate",
      cell: (row) => (
        <span>
          <strong>{moment.utc(row.endDate).format("DD-MMM-YYYY")}</strong>
        </span>
      ),
    },
    {
      name: "Status",
      selector: "status",
      cell: (row) => (
        <span
          className={`${row.isActive ? "bg-green-200" : "bg-red-200"} px-2 py-1 rounded-xl ${
            row.isActive ? "text-green-800" : "text-red-800"
          }`}
        >
          {row.isActive ? "Active" : "In-active"}
        </span>
      ),
    },
    {
      name: "Actions",
      selector: "action",
      cell: (row) => (
        <div className="flex items-center space-x-2">
          <button
            title="Delete project"
            className="text-gray-700 px-2 py-1 border border-gray-300 rounded-lg shadow hover:bg-gray-200 transition duration-100"
            // onClick={() => onDelete(row.projectId)}
            onClick={() => {
              setCurrProjectId(row.projectId)
              setOpenCOnfirmationWindow(true)
            }}
          >
            Delete
          </button>
        </div>
      ),
    },
  ]

  useEffect(() => {
    getProjects()
    // eslint-disable-next-line
  }, [])

  const getProjects = async () => {
    requestProjects({
      api: ApiService.getProjects,
      config: {
        displayError: false,
      },
    })
  }

  const onDelete = async (projectId) => {
    requestDeleteProject(
      {
        api: ApiService.deleteProject,
        body: projectId,
        config: {
          displayError: true,
        },
      },
      (_, err) => {
        if (err) return

        getProjects()
        renderSuccessToast("Project deleted")
      }
    )
  }

  return (
    <section>
      <Loader open={deleteProjectLoading} />
      <ConfirmationWindow
        open={openConfirmationWindow}
        close={() => setOpenCOnfirmationWindow(false)}
        okCallback={() => onDelete(currProjectId)}
        message="Please confirm. This action cannot be undone."
      />

      <header className="bg-white shadow">
        <div className="mx-auto py-3 px-4 sm:px-6 lg:px-8 flex justify-between">
          <h1 className="text-2xl font-bold tracking-tight text-gray-900">Delete Project</h1>
        </div>
      </header>

      <div className="lg:mx-[10rem] px-5">
        <Table
          columns={columns}
          data={projectsData?.projects}
          loading={projectsLoading}
          error={projectsError}
          errorMessage={projectsErrorMessage}
        />
      </div>
    </section>
  )
}

export default DeleteProject
