import { useSelector } from "react-redux"
import { useState } from "react"
import Menu from "./Menu"
import Upload from "./Upload"

const Index = ({ getFolders = () => {}, folderInfo = null }) => {
  const authState = useSelector((state) => state.auth)

  const [folderOrFile, setFolderOrFile] = useState("folder")
  const [openDialog, setOpenDialog] = useState(false)

  const uploadActionHandler = (type) => {
    try {
      setFolderOrFile(type)
      setOpenDialog(true)
    } catch (error) {}
  }

  return (
    <>
      {authState?.isLoggedIn && authState?.isAdmin && (
        <div className="container mx-auto px-4">
          <Menu onClick={uploadActionHandler} />
          <Upload
            type={folderOrFile}
            open={openDialog}
            setOpen={setOpenDialog}
            folderInfo={folderInfo}
            getFolders={getFolders}
          />
        </div>
      )}
    </>
  )
}

export default Index
