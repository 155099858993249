import { useNavigate } from "react-router-dom"

import { useDispatch } from "react-redux"
import { useFormik } from "formik"
import "flatpickr/dist/themes/airbnb.css"
import { useEffect } from "react"
import Loader from "../../components/Loader"
import TextField from "../../components/TextField"

import ApiService from "../../services/ApiService"
import { setUser } from "../../redux/auth"
import * as Yup from "yup"
import useApiHandler from "../../hooks/useApiHandler"
import Card from "../../components/Card"
import Alert from "../../components/Alert"
import Button from "../../components/Button"

const SignIn = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const { loading, error, message, data, request, errorCode } = useApiHandler()

  useEffect(() => {
    if (data) {
      dispatch(setUser(data))
      navigate("/")
    }
  }, [data, dispatch, navigate])

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      userName: "",
      password: "",
    },
    validationSchema: Yup.object().shape({
      userName: Yup.string().required("Username is required"),
      password: Yup.string().required("Password is required"),
    }),
    onSubmit: (values) => {
      login(values)
    },
  })

  const login = async (values) => {
    const { userName, password } = values
    const loginData = {
      userName: userName,
      password: password,
    }

    request(
      {
        api: ApiService.login,
        body: loginData,
        config: { returnResponse: true },
      },
      (response, err) => {
        if (err) return

        if (response.status === 200) {
          localStorage.setItem("access-token", response.data.token)
        }
      }
    )
  }

  const navigateTo = (to) => {
    navigate(to)
  }

  return (
    <section>
      <Loader open={loading} />

      <div className="flex flex-col items-center px-6 py-8 mx-auto lg:py-0 my-[6rem]">
        <Card className="sm:max-w-md shadow">
          <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
            <h1 className="text-xl font-medium leading-tight text-gray-700 md:text-2xl">
              Sign in to your account
            </h1>

            <form className="space-y-4 md:space-y-6">
              <div className="col-span-6 sm:col-span-3">
                <TextField
                  label="Username/Email"
                  name="userName"
                  type="text"
                  value={formik.values.userName}
                  onChange={formik.handleChange}
                  error={formik.errors.userName}
                  touched={formik.touched.userName}
                  onBlur={formik.handleBlur}
                />
              </div>
              <div className="col-span-6 sm:col-span-3">
                <TextField
                  label="Password"
                  name="password"
                  type="password"
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  error={formik.errors.password}
                  touched={formik.touched.password}
                  onBlur={formik.handleBlur}
                />
              </div>

              {error && !errorCode && <Alert type="danger">{message}</Alert>}

              {error && errorCode && (
                <Alert type="danger">
                  {message} <br />
                  {errorCode === "VERIFY_EMAIL" && (
                    <span
                      className="cursor-pointer underline"
                      onClick={() => {
                        let navPath = "/send-verify-email"
                        if (formik.values.userName !== "") {
                          navPath += `?input=${formik.values.userName}`
                        }
                        navigateTo(navPath)
                      }}
                    >
                      Send Verification Mail
                    </span>
                  )}
                  {errorCode === "MEMBERSHIP_EXPIRED" && (
                    <span
                      className="cursor-pointer underline"
                      onClick={() => navigateTo(`/membership-renewal?input=${formik.values.userName}`)}
                    >
                      Click here to renewal
                    </span>
                  )}
                </Alert>
              )}

              <Button className="w-full" fullWidth onClick={formik.handleSubmit}>
                Sign In
              </Button>

              <span className="text-sm font-light text-gray-500">
                <div className="my-2">
                  <a
                    href="/forgot-password"
                    className="text-sm font-medium text-primary-600 hover:underline"
                  >
                    Forgot password?
                  </a>
                </div>
                Don’t have an account yet?{" "}
                <a href="/signup" className="font-medium text-primary-600 hover:underline">
                  Sign up
                </a>
              </span>
            </form>
          </div>
        </Card>
      </div>
    </section>
  )
}

export default SignIn
