import { useEffect, useState } from "react"
import { useFormik } from "formik"
import TextField from "../../../components/TextField"
import TextArea from "../../../components/Textarea"
import * as Yup from "yup"
import ApiService from "../../../services/ApiService"
import CustomDialog from "../../../components/Dialog"
import Button from "../../../components/Button"
import useApiHandler from "../../../hooks/useApiHandler"
import useToast from "../../../hooks/useToast"

export default function UpdateProfile({ open, setOpen, profile, profileUpdateCallback }) {
  const [user, setUser] = useState(null)
  const { request, loading } = useApiHandler()
  const { renderSuccessToast } = useToast()

  useEffect(() => {
    if (profile) setUser(profile)
  }, [profile])

  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      firstName: user?.firstName || "",
      lastName: user?.lastName || "",
      userName: user?.userName || "",
      phone: user?.phone || "",
      addressLine: user?.address?.addressLine1 || "",
      city: user?.address?.city || "",
      state: user?.address?.state || "",
      country: user?.address?.country || "",
      zipcode: user?.address?.zipcode || "",
    },
    validationSchema: Yup.object().shape({
      firstName: Yup.string().required("Firstname is required"),
      lastName: Yup.string().required("Lastname is required"),
      userName: Yup.string().required("Username is required"),
      phone: Yup.string()
        .required("Phone is required")
        .matches(phoneRegExp, "Phone number is not valid")
        .min(10, "too short")
        .max(10, "too long"),
    }),
    onSubmit: (values) => {
      updateProfile()
    },
  })

  const updateProfile = async () => {
    const { firstName, lastName, userName, phone, addressLine, state, country, zipcode, city } =
      formik.values

    const data = {
      _id: user["_id"],
      firstName: firstName,
      lastName: lastName,
      userName: userName,
      phone: phone,
      addressLine1: addressLine,
      city: city,
      state: state,
      country: country,
      zipcode: zipcode,
      isActive: user?.isActive,
    }

    request(
      {
        api: ApiService.updateProfile,
        body: data,
        config: {
          displayError: true,
        },
      },
      (_, err) => {
        if (err) return

        setOpen(false)
        renderSuccessToast("Profile updated.")
        profileUpdateCallback()
      }
    )
  }

  return (
    <CustomDialog heading="Update your profile" open={open} size="lg">
      <CustomDialog.Content>
        <form className="space-y-4 md:space-y-6">
          <div className="grid grid-cols-1 gap-6 mt-4 sm:grid-cols-2">
            <div>
              <TextField
                label="First Name "
                name="firstName"
                type="text"
                value={formik.values.firstName}
                onChange={formik.handleChange}
                error={formik.errors.firstName}
                touched={formik.touched.firstName}
                onBlur={formik.handleBlur}
              />
            </div>

            <div>
              <TextField
                label="Last Name"
                name="lastName"
                type="text"
                value={formik.values.lastName}
                onChange={formik.handleChange}
                error={formik.errors.lastName}
                touched={formik.touched.lastName}
                onBlur={formik.handleBlur}
              />
            </div>

            <div>
              <TextField
                label="User Name"
                name="userName"
                type="text"
                value={formik.values.userName}
                onChange={formik.handleChange}
                error={formik.errors.userName}
                touched={formik.touched.userName}
                onBlur={formik.handleBlur}
              />
            </div>

            <div>
              <TextField
                label="Phone"
                name="phone"
                type="text"
                value={formik.values.phone}
                onChange={formik.handleChange}
                error={formik.errors.phone}
                touched={formik.touched.phone}
                onBlur={formik.handleBlur}
              />
            </div>
          </div>

          <div>
            <div>
              <TextArea
                label="Address Line"
                name="addressLine"
                type="text"
                value={formik.values.addressLine}
                onChange={formik.handleChange}
                error={formik.errors.addressLine}
                touched={formik.touched.addressLine}
                onBlur={formik.handleBlur}
                rows={3}
              />
            </div>
          </div>

          <div className="grid grid-cols-1 gap-6 mt-4 sm:grid-cols-2">
            <div>
              <TextField
                label="City"
                name="city"
                type="text"
                value={formik.values.city}
                onChange={formik.handleChange}
                error={formik.errors.city}
                touched={formik.touched.city}
                onBlur={formik.handleBlur}
              />
            </div>

            <div>
              <TextField
                label="State"
                name="state"
                type="text"
                value={formik.values.state}
                onChange={formik.handleChange}
                error={formik.errors.state}
                touched={formik.touched.state}
                onBlur={formik.handleBlur}
              />
            </div>

            <div>
              <TextField
                label="Country"
                name="country"
                type="text"
                value={formik.values.country}
                onChange={formik.handleChange}
                error={formik.errors.country}
                touched={formik.touched.country}
                onBlur={formik.handleBlur}
              />
            </div>

            <div>
              <TextField
                label="Zip"
                name="zipcode"
                type="text"
                value={formik.values.zipcode}
                onChange={formik.handleChange}
                error={formik.errors.zipcode}
                touched={formik.touched.zipcode}
                onBlur={formik.handleBlur}
              />
            </div>
          </div>
        </form>
      </CustomDialog.Content>
      <CustomDialog.Footer>
        <Button
          loading={loading}
          loadingText="Updating..."
          onClick={formik.handleSubmit}
          className="sm:ml-3 w-full"
        >
          Update
        </Button>

        <Button variant="secondary" onClick={() => setOpen(false)} className="w-full">
          Cancel
        </Button>
      </CustomDialog.Footer>
    </CustomDialog>
  )
}
