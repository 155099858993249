import { useCallback } from "react"
import { useSelector, useDispatch } from "react-redux"
import Menu from "./Menu"
import moment from "moment"
import { useEffect, useState } from "react"
import ApiService from "../../../services/ApiService"
import Loader from "../../../components/Loader"
import Constants from "../../../utils/Constants"
import { setUser } from "../../../redux/auth"
import Card from "../../../components/Card"
import Alert from "../../../components/Alert"

import useApiHandler from "../../../hooks/useApiHandler"
import { useNavigate } from "react-router-dom"

const MyMembership = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const authState = useSelector((state) => state.auth)
  const { user } = authState
  const { payment } = user

  const { ...renewalPaypalResults } = useApiHandler()
  const { ...renewalOfflineResults } = useApiHandler()
  const { ...getProfileResults } = useApiHandler()

  const getMyProfile = useCallback(async () => {
    getProfileResults.request(
      {
        api: ApiService.getMyProfile,
        body: authState["user"]["_id"],
      },
      (response, err) => {
        if (err) return

        dispatch(setUser(response.data))
      }
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    getMyProfile()
  }, [getMyProfile])

  const onClickPaypal = async () => {
    const body = {
      payment: {
        transactionType: Constants.MEMBERSHIP_TRANSACTION_TYPE_PAYPAL,
        amount: Constants.MEMBERSHIP_AMOUNT,
        intent: Constants.MEMBERSHIP_INTENT,
        currency: Constants.MEMBERSHIP_CURRENCY,
        paymentType: Constants.MEMBERSHIP_PAYMENT_TYPE_RENEWAL,
      },
      email: user["email"],
    }

    renewalPaypalResults.request(
      {
        api: ApiService.membershipRenewal,
        body: body,
        config: {
          continuousLoading: true,
        },
      },
      (response, err) => {
        if (err) return

        let links = response?.data?.payment?.links || []
        let link = ""
        for (let i = 0; i < links.length; i++) {
          if (links[i].rel === "approval_url") {
            link = links[i].href
          }
        }
        window.open(link, "_self")
      }
    )
  }

  const onClickOffline = () => {
    const body = {
      email: authState["user"]["email"],
      payment: {
        transactionType: Constants.MEMBERSHIP_TRANSACTION_TYPE_OFFLINE,
        amount: Constants.MEMBERSHIP_AMOUNT,
        intent: Constants.MEMBERSHIP_INTENT,
        currency: Constants.MEMBERSHIP_CURRENCY,
        paymentType: Constants.MEMBERSHIP_PAYMENT_TYPE_RENEWAL,
      },
    }

    renewalOfflineResults.request(
      {
        api: ApiService.membershipRenewalOffline,
        body: body,
        config: {
          displayError: false,
        },
      },
      (_, err) => {
        if (err) return

        navigate("/membership-renewal/offline")
      }
    )
  }

  return (
    <section>
      <Loader
        open={renewalPaypalResults.loading || getProfileResults.loading || renewalOfflineResults.loading}
      />

      <header className="bg-white shadow">
        <div className="mx-auto py-3 px-4 sm:px-6 lg:px-8 flex justify-between">
          <h1 className="text-xl font-medium leading-tight text-gray-700 md:text-2xl">My Membership</h1>
        </div>
      </header>

      <Card className="w-[80%] p-5">
        {!getProfileResults.error && (
          <div className="text-gray-700">
            <div className="grid md:grid-cols-2 text-md">
              <div className="grid grid-cols-2">
                <div className="px-4 py-2 font-semibold">Membership Expiry:</div>
                <div className="px-4 py-2">
                  {" "}
                  {moment(user.memberShipExpireDate).format("DD-MMM-YYYY")}{" "}
                </div>
              </div>
              <div className="grid grid-cols-2">
                <div className="px-4 py-2 font-semibold">Last Renewal:</div>
                <div className="px-4 py-2">
                  {moment(payment["paymentCreatedDate"]).format("DD-MMM-YYYY")}
                </div>
              </div>
              <div className="grid grid-cols-2 mt-4">
                <div className="px-4 py-2 font-semibold">Membership Fee:</div>
                <div className="px-4 py-2">{payment["amount"]}</div>
              </div>
              <div className="grid grid-cols-2 mt-4">
                <div className="px-4 py-2 font-semibold">Last Payment Mode:</div>
                <div className="px-4 py-2">{payment["transactionType"]}</div>
              </div>
            </div>

            <div className="mt-6">
              <Menu onClickPaypal={onClickPaypal} onClickOffline={onClickOffline} />
            </div>
          </div>
        )}

        {getProfileResults.error && (
          <Alert type="danger" className="mt-5">
            {getProfileResults.message}
          </Alert>
        )}
        {renewalPaypalResults.error && (
          <Alert type="danger" className="mt-5">
            {renewalPaypalResults.message}
          </Alert>
        )}
        {renewalOfflineResults.error && (
          <Alert type="danger" className="mt-5">
            {renewalOfflineResults.message}
          </Alert>
        )}
      </Card>
    </section>
  )
}

export default MyMembership
