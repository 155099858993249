import moment from "moment"
import Img from "../../../assets/about_image_comp.jpg"
import { useNavigate } from "react-router-dom"
import { useState } from "react"
import ApiService from "services/ApiService"
import Loader from "components/Loader"
import ConfirmationWindow from "components/ConfirmationWindow"
import useToast from "hooks/useToast"
import useApiHandler from "hooks/useApiHandler"
import Button from "components/Button"

const CauseCard = ({ cause, getCauses, admin }) => {
  const { request: requestStatusChange, loading: statusChangeLoading } = useApiHandler()

  const { request: requestVote, loading: voteLoading, message: voteErrorMessage } = useApiHandler()

  const navigate = useNavigate()

  const { renderErrorToast, renderSuccessToast } = useToast()

  const [openConfirmWindow, setOpenConfirmWindow] = useState(false)

  const changeStatus = async (status) => {
    requestStatusChange(
      {
        api: ApiService.changeCauseStatus,
        body: {
          causeId: cause._id,
          status: status,
        },
        config: {
          displayError: false,
        },
      },
      (_, err) => {
        if (err) {
          renderErrorToast(err?.response?.data?.message)
          return
        }

        getCauses()
        renderSuccessToast("Cause status updated")
      }
    )
  }

  const handleVote = async () => {
    requestVote(
      {
        api: ApiService.voteForCause,
        body: {
          causeId: cause._id,
          causeWindow: cause.causeWindow,
        },
        config: {
          displayError: false,
        },
      },
      (_, err) => {
        if (err) {
          let { message } = err?.response?.data
          renderErrorToast(message)
          return
        }

        getCauses()
        renderSuccessToast("Voted successfully")
      }
    )
  }

  return (
    <div className="bg-white overflow-hidden border border-gray-200 rounded-lg shadow">
      <Loader open={statusChangeLoading || voteLoading} />
      <ConfirmationWindow
        open={openConfirmWindow}
        close={() => setOpenConfirmWindow(false)}
        okCallback={handleVote}
        message="This action cannot be undo."
      />

      <img src={Img} alt="Card" className="w-full object-cover object-center h-48 sm:h-64" />
      <div className="px-4 py-2 sm:p-4">
        <span>
          <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 line-clamp-1">
            {cause.causeName}
          </h5>
        </span>
        <p className="mb-3 font-normal text-gray-700 ">
          Goal: ${cause.causeFund} Raised: ${cause.fundRaised}
        </p>
        <p className="text-gray-600 leading-relaxed mb-3 line-clamp-2">{cause.aboutTheCause}</p>

        <div className="flex">
          <Button onClick={() => navigate(`/view-cause/${cause._id}`)}>Read more</Button>
        </div>

        {cause.causeLevel === "FUNDING" && (
          <div className="flex mt-2">
            <Button
              onClick={() => navigate(`/donate?dt=1&df=${cause._id}`)}
              bg="bg-[#F7BE38]"
              textColor="text-black"
            >
              Donate
            </Button>
            {admin && (
              <Button onClick={() => changeStatus("COMPLETED")} className="ml-2" bg="bg-[#40bb40]">
                Set Completed
              </Button>
            )}
          </div>
        )}

        {cause.causeLevel === "APPROVED_FOR_VOTING" && (
          <>
            <div className="mt-3">
              Voting start date: {moment.utc(cause.voteStartDate).format("DD-MMM-YYYY")}{" "}
            </div>
            <div>Voting end date: {moment.utc(cause.voteEndDate).format("DD-MMM-YYYY")} </div>
            {admin && (
              <>
                {/* {moment() > moment(cause.voteEndDate) && ( */}
                {cause.isVotingCompleted && (
                  <>
                    <div className="flex mt-2">
                      <Button onClick={() => changeStatus("LOST")} bg="bg-red-500" fullWidth>
                        REJECT
                      </Button>
                      <Button onClick={() => changeStatus("FUNDING")} fullWidth className="ml-2">
                        APPROVE
                      </Button>
                    </div>
                    <div style={{ marginTop: "1rem" }}>
                      <b> Votes: {cause.voteCount != null ? cause.voteCount : 0} </b>
                    </div>
                  </>
                )}
              </>
            )}

            {!admin && (
              <>
                {/* {moment() > moment(cause.voteEndDate) && ( */}
                {cause.isVotingCompleted && (
                  <div className="mt-3">
                    <div style={{ fontSize: "12px", color: "blue" }}>
                      Voting is completed. Admin is reviewing the cause.
                    </div>
                    <div className="mt-2">
                      <b> Votes: {cause.voteCount != null ? cause.voteCount : 0} </b>
                    </div>
                  </div>
                )}
              </>
            )}

            {/* {moment() >= moment(cause.voteStartDate) && moment() <= moment(cause.voteEndDate) && ( */}
            {cause.isVotingStarted && (
              <div className="mt-3">
                <Button onClick={() => setOpenConfirmWindow(true)} bg="bg-[#10a37f]">
                  Vote
                </Button>
                <div className="mt-2">
                  <b> Votes: {cause.voteCount != null ? cause.voteCount : 0} </b>
                </div>
              </div>
            )}
          </>
        )}

        {admin && cause.causeLevel === "SUBMITTED" && (
          <div className="flex mt-2">
            <Button onClick={() => changeStatus("DECLINED_FOR_VOTING")} bg="bg-red-500" fullWidth>
              REJECT
            </Button>
            <Button onClick={() => changeStatus("APPROVED_FOR_VOTING")} fullWidth className="ml-2">
              APPROVE
            </Button>
          </div>
        )}
      </div>
    </div>
  )
}

export default CauseCard
