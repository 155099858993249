import { useCallback, useEffect, useState } from "react"
import UploadDocuments from "./UploadDocuments"
import Loader from "../../../components/Loader"
import ApiService from "../../../services/ApiService"
import Button from "../../../components/Button"
import Card from "../../../components/Card"
import useApiHandler from "../../../hooks/useApiHandler"

const FinancialDocuments = () => {
  const [openUploadDocs, setOpenUploadDocs] = useState(false)

  const { loading, request } = useApiHandler()

  const [documents, setDocuments] = useState([])

  const getDocuments = useCallback(() => {
    request(
      {
        api: ApiService.getFinancialDocuments,
      },
      (res, err) => {
        if (err) return

        setDocuments(res?.data?.data)
      }
    )
  }, [request])

  useEffect(() => {
    getDocuments()
  }, [getDocuments])

  const downloadMedia = async (filename) => {
    ApiService.downloadMedia(filename)
  }

  return (
    <section>
      <Loader open={loading} />
      <UploadDocuments open={openUploadDocs} setOpen={setOpenUploadDocs} getDocuments={getDocuments} />

      <header className="bg-white shadow">
        <div className="mx-auto py-3 px-4 sm:px-6 lg:px-8 flex justify-between">
          <h1 className="text-2xl font-bold tracking-tight text-gray-900">Financial Documents</h1>
        </div>
      </header>

      <div className="container mx-auto mt-10 px-5">
        <Button onClick={() => setOpenUploadDocs(true)}>Upload documents</Button>

        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
          {documents &&
            documents.map((doc, index) => (
              <Card className="px-4 py-2" key={index}>
                <span className="flex justify-between">
                  <div>
                    <span> {doc.originalFilename} </span>
                  </div>
                  <div
                    className="cursor-pointer text-blue-500"
                    onClick={() => downloadMedia(doc.fileName)}
                  >
                    Download
                  </div>
                </span>
              </Card>
            ))}
        </div>

        {!loading && documents && documents.length === 0 && (
          <Card className="text-center p-5">
            <h3 className="text-lg font-medium text-gray-700">No documents found</h3>
          </Card>
        )}
      </div>
    </section>
  )
}

export default FinancialDocuments
