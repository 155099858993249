import { Fragment, forwardRef } from "react"

const RadioButton = forwardRef(({ name = "", onChange = () => {}, error = false, children, disabled = false }, ref) => {

  const getTextColor = () => {
    return disabled ? 'text-gray-500' : 'text-gray-900'
  }

  return (
    <Fragment>
      <input
        id={name}
        disabled={disabled}
        type="radio"
        value={name}
        name="default-radio"
        onChange={onChange}
        className="w-4 h-4 text-[#10a37f] bg-gray-100 border-gray-300 focus:ring-[#10a37f] focus:ring-2 disabled:cursor-not-allowed"
        ref={ref}
      />
      <label
        htmlFor={name}
        className={`ml-2 text-sm font-medium ${error ? "text-red-600" : getTextColor()}`}
      >
        {children}
      </label>
    </Fragment>
  );
});

export default RadioButton
