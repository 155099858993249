import ServerPaginationTable from "components/Table/ServerPaginationTable/index"
import moment from "moment"
import ViewJson from "./ViewJson"
import { useState } from "react"

const LogsTable = ({ logs, ...props }) => {
  const columns = [
    {
      name: "Date",
      selector: "createdDate",
      cell: (row) => <div>{moment(row["createdAt"]).format("DD-MMM-YYYY")}</div>,
    },
    {
      name: "Username",
      selector: "user",
      cell: row => <div>{row?.userInfo?.firstName} {row?.userInfo?.lastName}</div>
    },
    {
      name: "Name",
      selector: "name",
      cell: (row) => <strong>{row.name}</strong>,
    },
    {
      name: "Status",
      selector: "status",
      cell: (row) => (
        <div className="flex-shrink-0 flex">
          {row.success && (
            <p className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
              Success
            </p>
          )}
          {!row.success && (
            <p className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-800">
              Failed
            </p>
          )}
        </div>
      ),
    },
    {
      name: "Payload",
      selector: "payload",
      cell: (row) => (
        <button
          onClick={() => {
            onOpenJsonView(row.payload)
            setCVON("Payload")
          }}
        >
          View Payload
        </button>
      ),
    },
    {
      name: "Api Response",
      selector: "apiresponse",
      cell: (row) => (
        <button
          onClick={() => {
            onOpenJsonView(row.apiresponse)
            setCVON("Response")
          }}
        >
          View Response
        </button>
      ),
    },
    {
      name: "Error",
      selector: "error",
      cell: (row) => <span>{row.error ? row.error : "--"}</span>,
    },
    {
      name: "API",
      selector: "api",
      cell: (row) => (
        <div className="flex text-center w-full">
          <span className="py-1 px-2 bg-yellow-100 text-yellow-800 rounded-md text-sm">
            {row.method}
          </span>
          <span className=" ml-3 py-1 px-2 bg-blue-100 rounded-md text-center relative font-medium">
            {" "}
            <b>{row.api}</b>
          </span>
        </div>
      ),
    },
    
  ]

  const [openJsonView, setOpenJsonView] = useState(false)
  const [jsonObject, setJsonObject] = useState(null)
  const [currentViewingObjectName, setCVON] = useState("")

  const onOpenJsonView = (json) => {
    if (json) {
      setJsonObject(JSON.parse(json))
      setOpenJsonView(true)
    }
  }

  return (
    <section>
      <ViewJson
        open={openJsonView}
        setOpen={setOpenJsonView}
        data={jsonObject}
        name={currentViewingObjectName}
      />
      <ServerPaginationTable {...props} columns={columns} data={logs} />
    </section>
  )
}

export default LogsTable
