import React, { Fragment } from "react"
import { Dialog, Transition } from "@headlessui/react"

const CustomDialog = ({
  header = "",
  open,
  onClose = () => {},
  children,
  size = "md",
  exceptions = [],
}) => {
  const [content, footer] = extractContentAndFooter(children)

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel
                className={`relative transform ${
                  exceptions.includes("SET_OVERFLOW_UNSET") ? "overflow-unset" : "overflow-hidden"
                } rounded-[5px] bg-white text-left shadow-md transition-all sm:my-8 w-full ${getSize(
                  size
                )}`}
              >
                <div className="bg-white">
                  <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
                    {header && (
                      <h1 className="text-xl font-medium leading-tight tracking-tight text-gray-900 md:text-2xl">
                        {header}
                      </h1>
                    )}
                    {content}
                  </div>
                </div>
                {footer && (
                  <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                    {footer}
                  </div>
                )}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

const getSize = (size) => {
  if (size === "lg") return "sm:max-w-[40rem]"
  if (size === "sm") return "sm:max-w-[25rem]"
  if (size === "xl") return "sm:max-w-[85%] 2xl:max-w-[60%]"
  return "sm:max-w-lg"
}

const Content = ({ children }) => <>{children}</>
const Footer = ({ children }) => <>{children}</>

const extractContentAndFooter = (children) => {
  let content, footer

  React.Children.forEach(children, (child) => {
    if (child.type === Content) {
      content = child
    } else if (child.type === Footer) {
      footer = child
    }
  })

  return [content, footer]
}

CustomDialog.Content = Content
CustomDialog.Footer = Footer

export default CustomDialog
