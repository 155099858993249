import Button from "../../components/Button"
import Alert from "../../components/Alert"
import { useNavigate } from "react-router-dom"

const SignUpCancel = () => {
  const navigate = useNavigate()

  const backTo = () => {
    navigate("/signup")
  }

  return (
    <>
      <div className="flex flex-row  justify-center px-6 py-8 mx-auto lg:py-0 my-[6rem]">
        <div className="w-full bg-white rounded-lg shadow border md:mt-0 sm:max-w-[50rem] xl:p-0">
          <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
            <div className="mt-3 text-center flex flex-col items-center">
              <Alert type="danger">Your payment has been cancelled.</Alert>
              <div className="mt-2 px-7 py-3">
                <Button onClick={backTo}>Back to Signup</Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default SignUpCancel
