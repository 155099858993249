import { useEffect, useState } from "react"
import { useFormik } from "formik"
import TextField from "components/TextField"
import TextArea from "components/Textarea"
import * as Yup from "yup"
import ApiService from "services/ApiService"
import Dialog from "components/Dialog"
import FileSelector from "components/FileSelector"
import Alert from "components/Alert"
import useApiHandler from "hooks/useApiHandler"
import Button from "components/Button"
import useToast from "hooks/useToast"

export default function UpdateProject({ open, setOpen, project, projectUpdateCallback }) {
  const { request, loading, error, message } = useApiHandler()

  const { renderSuccessToast } = useToast()

  const [data, setData] = useState(null)
  const [projectBackground, setProjectBackground] = useState(null)

  useEffect(() => {
    if (project) setData(project)
  }, [project])

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      projectName: data?.projectName,
      description: data?.description,
      aboutTheCause: data?.aboutTheCause,
      whatWeDo: data?.whatWeDo,
      goalAmount: data?.fund,
    },
    validationSchema: Yup.object().shape({
      projectName: Yup.string().required("Project name is required"),
      description: Yup.string().required("Description is required"),
      aboutTheCause: Yup.string().required("About the tause is required"),
      whatWeDo: Yup.string().required("Plan of action is required"),
      goalAmount: Yup.string().required("Goal amount is required"),
    }),
    onSubmit: () => {
      updateProject()
    },
  })

  const updateProject = async () => {
    try {
      const { projectName, description, aboutTheCause, whatWeDo, goalAmount } = formik.values

      const formData = new FormData()
      formData.append("id", data["_id"])
      formData.append("projectName", projectName)
      formData.append("description", description)
      formData.append("aboutTheCause", aboutTheCause)
      formData.append("whatWeDo", whatWeDo)
      formData.append("goalAmount", goalAmount)
      formData.append("projectBackground", projectBackground)
      console.log(projectBackground)

      request(
        {
          api: ApiService.updateProject,
          body: formData,
          config: {
            displayError: false,
          },
        },
        (_, err) => {
          if (err) return

          renderSuccessToast("Project updated")
          projectUpdateCallback()
          setOpen(false)
        }
      )
    } catch (error) {
      console.error(error)
    }
  }

  const onSelectProjectBackground = (e) => {
    setProjectBackground(e.target.files[0])
  }

  return (
    <Dialog open={open} header="Update project" size="xl">
      <Dialog.Content>
        <form className="space-y-4 md:space-y-6">
          <div className="grid grid-cols-1 gap-6 mt-4 sm:grid-cols-2">
            <div>
              <TextField
                label="Project Name "
                name="projectName"
                type="text"
                value={formik.values.projectName}
                onChange={formik.handleChange}
                error={formik.errors.projectName}
                touched={formik.touched.projectName}
                onBlur={formik.handleBlur}
                required
              />
            </div>

            <div>
              <TextField
                label="Description"
                name="description"
                type="text"
                value={formik.values.description}
                onChange={formik.handleChange}
                error={formik.errors.description}
                touched={formik.touched.description}
                onBlur={formik.handleBlur}
                required
              />
            </div>

            <div>
              <TextArea
                label="About the cause"
                name="aboutTheCause"
                type="text"
                required
                value={formik.values.aboutTheCause}
                onChange={formik.handleChange}
                error={formik.errors.aboutTheCause}
                touched={formik.touched.aboutTheCause}
                onBlur={formik.handleBlur}
              />
            </div>

            <div>
              <TextArea
                label="Plan of action"
                name="whatWeDo"
                type="text"
                value={formik.values.whatWeDo}
                onChange={formik.handleChange}
                error={formik.errors.whatWeDo}
                touched={formik.touched.whatWeDo}
                onBlur={formik.handleBlur}
              />
            </div>

            <div>
              <TextField
                label="Goal amount"
                name="goalAmount"
                type="text"
                value={formik.values.goalAmount}
                onChange={formik.handleChange}
                error={formik.errors.goalAmount}
                touched={formik.touched.goalAmount}
                onBlur={formik.handleBlur}
                required
              />
            </div>
          </div>

          <FileSelector
            label="Project background image"
            onSelected={onSelectProjectBackground}
            description="Choose file to upload"
            onClear={() => setProjectBackground(null)}
          />
        </form>

        {error && <Alert type="danger">{message}</Alert>}
      </Dialog.Content>
      <Dialog.Footer>
        <Button
          className="sm:ml-3 w-full"
          loading={loading}
          loadingText="Updating..."
          onClick={formik.handleSubmit}
        >
          Upload
        </Button>
        <Button variant="secondary" onClick={() => setOpen(false)} className="w-full">
          Cancel
        </Button>
      </Dialog.Footer>
    </Dialog>
  )
}
