import { useRef, useState } from "react"
import * as XLSX from "xlsx"
import moment from "moment"
import ApiService from "services/ApiService"
import Dialog from "components/Dialog"
import FileSelector from "components/FileSelector"
import Alert from "components/Alert"
import useApiHandler from "hooks/useApiHandler"
import Button from "components/Button"
import useToast from "hooks/useToast"

export default function Modal({ open, setOpen, onUploadComplete }) {
  const { loading, request, error, message } = useApiHandler()
  const { renderSuccessToast } = useToast()

  const cancelButtonRef = useRef(null)

  const [file, setFile] = useState(null)

  const onChangeFile = (e) => {
    setFile(e.target.files[0])
  }

  const onSelectFile = () => {
    if (!file) return

    const ExcelDateToJSDate = (date) => {
      return new Date((date - (25567 + 1)) * 86400 * 1000)
    }

    const reader = new FileReader()
    const rABS = !!reader.readAsBinaryString

    reader.onload = (e) => {
      const bstr = e.target.result
      const wb = XLSX.read(bstr, { type: rABS ? "binary" : "array" })

      const wsname = wb.SheetNames[0]
      const ws = wb.Sheets[wsname]

      const data = XLSX.utils.sheet_to_json(ws)

      for (let i = 0; i < data.length; i++) {
        let projectStartDate = ExcelDateToJSDate(data[i]["projectStartDate"])
        let projectEndDate = ExcelDateToJSDate(data[i]["projectEndDate"])
        data[i]["projectStartDate"] = moment(projectStartDate).format("YYYY-MM-DD")
        data[i]["projectEndDate"] = moment(projectEndDate).format("YYYY-MM-DD")
      }
      uploadFile(data)
    }

    if (rABS) {
      reader.readAsBinaryString(file)
    } else {
      reader.readAsArrayBuffer(file)
    }
  }

  const uploadFile = async (projects) => {
    request(
      {
        api: ApiService.uploadPreviousProjects,
        body: projects,
        config: {
          displayError: false,
        },
      },
      (_, err) => {
        if (err) return

        renderSuccessToast("Previous projects uploaded")
        onUploadComplete()
        setOpen(false)
      }
    )
  }

  return (
    <Dialog open={open} header="Upload previous projects" size="md">
      <Dialog.Content>
        <FileSelector
          onSelected={onChangeFile}
          description="Choose files to upload"
          onClear={() => setFile(null)}
        />

        {error && <Alert type="danger"> {message} </Alert>}
      </Dialog.Content>
      <Dialog.Footer>
        <Button
          disabled={file === null}
          className="sm:ml-3 w-full"
          onClick={onSelectFile}
          loading={loading}
          loadingText="Uploading..."
        >
          Upload
        </Button>
        <Button
          variant="secondary"
          onClick={() => setOpen(false)}
          className="w-full"
          ref={cancelButtonRef}
        >
          Cancel
        </Button>
      </Dialog.Footer>
    </Dialog>
  )
}
