import { useEffect, useRef, useState } from "react"
import { CSVLink } from "react-csv"

import { BiCheck, BiX } from "react-icons/bi"
import Table from "components/Table/Table"
import FilterMenu from "components/Menu/FilterMenu"

import ApiService from "services/ApiService"
import moment from "moment"
import Loader from "components/Loader"
import Button from "components/Button"
import useToast from "hooks/useToast"
import useApiHandler from "hooks/useApiHandler"

const Payments = () => {
  const {
    request: requestPayments,
    loading: loadingPayments,
    error: paymentsError,
    message: paymentsErrorMessage,
  } = useApiHandler()

  const { request: requestUpdateStatus, loading: loadingUpdateStatus } = useApiHandler()

  const { renderSuccessToast } = useToast()

  const [payments, setPayments] = useState([])
  const [filterPaymentResults, setFilterPaymentResults] = useState([])

  useEffect(() => {
    if (payments.length > 0) {
      setFilterPaymentResults(payments)
    }
  }, [payments])

  const filterItems = ["Completed", "Pending", "Denied"]

  const csvHeaders = [
    { label: "Name", key: "name" },
    { label: "Email", key: "email" },
    { label: "Phone", key: "phone" },
    { label: "Amount", key: "amount" },
    { label: "Mode", key: "mode" },
    { label: "Payment", key: "payment" },
    { label: "Description", key: "desc" },
    { label: "Transaction ID", key: "transactionId" },
    { label: "Status", key: "status" },
  ]
  const csvLinkRef = useRef()
  const columns = [
    {
      name: "Name",
      selector: "name",
      cell: (row) => <span>{row.name}</span>,
    },
    {
      name: "Status",
      selector: "status",
      cell: (row) => (
        <div className="flex-shrink-0 flex">
          {row.status === "PENDING" && (
            <p className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-yellow-100 text-yellow-800">
              {row.status}
            </p>
          )}
          {row.status === "COMPLETED" && (
            <p className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
              {row.status}
            </p>
          )}
          {row.status === "DENIED" && (
            <p className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-800">
              {row.status}
            </p>
          )}
        </div>
      ),
    },
    {
      name: "Actions",
      selector: "actions",
      cell: (row) => (
        <div className="flex text-center w-full">
          {row.status === "PENDING" && (
            <>
              <div
                className="bg-red-600 rounded-full mx-1 cursor-pointer"
                onClick={() => updatePaymentStatus(row["paymentId"], "DENIED")}
              >
                <BiX color="white" size={28} />
              </div>
              <div
                className="bg-green-600 rounded-full mx-1 cursor-pointer"
                onClick={() => updatePaymentStatus(row["paymentId"], "COMPLETED")}
              >
                <BiCheck color="white" size={28} />
              </div>
            </>
          )}
          {row.status === "DENIED" && (
            <div
              className="bg-green-600 rounded-full mx-1 cursor-pointer"
              onClick={() => updatePaymentStatus(row["paymentId"], "COMPLETED")}
            >
              <BiCheck color="white" size={28} />
            </div>
          )}
          {row.status === "COMPLETED" && <>No actions</>}
        </div>
      ),
    },
    {
      name: "Email",
      selector: "email",
    },
    {
      name: "Phone",
      selector: "phone",
    },
    {
      name: "Amount",
      selector: "amount",
    },
    {
      name: "Mode",
      selector: "mode",
    },
    {
      name: "Payment",
      selector: "payment",
    },
    {
      name: "Desc",
      selector: "desc",
    },
    {
      name: "Transaction ID",
      selector: "transactionId",
    },
    {
      name: "Created Date",
      selector: "createdDate",
      cell: (row) => <div>{moment(row["createdDate"]).format("DD-MMM-YYYY")}</div>,
    },
  ]

  useEffect(() => {
    getAllPayments()
  }, [])

  const getAllPayments = async () => {
    requestPayments(
      {
        api: ApiService.getAllPayments,
        config: {
          displayError: false,
        },
      },
      (res, err) => {
        if (err) return

        setPayments(res.data.payments)
      }
    )
  }

  const updatePaymentStatus = async (paymentId, status) => {
    requestUpdateStatus(
      {
        api: ApiService.updatePaymentStatus,
        body: {
          paymentId: paymentId,
          paymentStatus: status,
        },
      },
      (_, err) => {
        if (err) return

        getAllPayments()
        renderSuccessToast("Payment Updated")
      }
    )
  }

  const filterWithStatus = (filters) => {
    if (filters.length > 0) {
      let _payments = [...payments]
      let filteredPayments = []
      for (let i = 0; i < _payments.length; i++) {
        if (filters.includes(_payments[i].status.toLowerCase())) {
          filteredPayments.push(_payments[i])
        }
      }
      setFilterPaymentResults(filteredPayments)
    } else {
      setFilterPaymentResults(payments)
    }
  }

  const onFilter = (filters) => {
    let filterItems = []
    for (let i = 0; i < filters.length; i++) {
      filterItems.push(filters[i].toLowerCase())
    }
    filterWithStatus(filterItems)
  }

  return (
    <div>
      <Loader open={loadingUpdateStatus} />

      <header className="bg-white shadow">
        <div className="mx-auto py-3 px-4 sm:px-6 lg:px-8 flex justify-between">
          <h1 className="text-2xl font-bold tracking-tight text-gray-900">Payments</h1>

          <div className="flex items-center">
            <FilterMenu items={filterItems} onFilter={onFilter} />
            <div className="ml-3">
              <Button variant="secondary" onClick={() => csvLinkRef.current.link.click()}>
                Export
              </Button>
              <CSVLink
                headers={csvHeaders}
                filename="Payments.csv"
                data={filterPaymentResults}
                ref={csvLinkRef}
              />
            </div>
          </div>
        </div>
      </header>

      <div className="mx-5">
        <Table
          name="Payments"
          columns={columns}
          data={filterPaymentResults}
          loading={loadingPayments}
          error={paymentsError}
          errorMessage={paymentsErrorMessage}
        />
      </div>
    </div>
  )
}

export default Payments
