import { Fragment, useEffect, useState, useRef, useImperativeHandle, forwardRef } from "react"
import { Dialog, Transition } from "@headlessui/react"
import { useFormik } from "formik"
import TextField from "components/TextField"
import TextArea from "components/Textarea"
import SelectDate from "components/SelectDate"
import Button from "components/Button"
import Alert from "components/Alert"
import FileSelector from "components/FileSelector"
import * as Yup from "yup"
import ApiService from "services/ApiService"
import useApiHandler from "hooks/useApiHandler"
import useToast from "hooks/useToast"
import moment from "moment"
import commonFunctions from "utils/CommonFunctions"
import EventUtils from "../utils"

const EditEvent = ({ open, setOpen, refreshEvent = () => {}, event = null, restart = false }) => {
  const { request, loading, error, message } = useApiHandler()

  const { renderSuccessToast } = useToast()

  const [eventStartDate, setEventStartDate] = useState(null)
  const [eventStartDateError, setEventStartDateError] = useState(false)
  const [eventEndDate, setEventEndDate] = useState(null)
  const [eventEndDateError, setEventEndDateError] = useState(false)
  const [minDates, setMinDates] = useState({
    eventStartDate: "today",
    eventEndDate: "today",
  })
  const [dateError, setDateError] = useState(false)
  const [flyer, setFlyer] = useState(null)

  const [eventType, setEventType] = useState(null)

  const [allFieldsValidated, setAllFieldsValidated] = useState(false)

  const [costs, setCosts] = useState([
    {
      name: "Event Cost",
      value: "default",
      cost: "",
      touched: false,
      error: false,
    },
  ])
  const costsRef = useRef(null)

  useEffect(() => {
    if (event && !restart) {
      setEventStartDate(moment.utc(event?.eventStartDate).format("YYYY-MM-DD"))
      setEventEndDate(moment.utc(event?.eventEndDate).format("YYYY-MM-DD"))
      setEventType(event.eventType)

      let _costs = []
      EventUtils.categories[event.eventType.value].forEach((item) => {
        _costs.push({
          name: item["name"] + " Cost",
          value: item["key"],
          cost: event.eventCost[item.key],
          touched: false,
          error: false,
        })
      })
      setCosts(_costs)
    }
    // eslint-disable-next-line
  }, [open])

  const onSelectFlyer = (e) => {
    setFlyer(e.target.files[0])
  }

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      eventName: event?.eventName,
      eventDescription: event?.eventDescription,
      // eventCost: event?.eventCost,
      maxPlayers: event?.maxPlayers,
      geoLocation: event?.geoLocation,
      eventVenue: event?.eventVenue
    },
    validationSchema: Yup.object().shape({
      eventName: Yup.string().required("Required"),
      eventDescription: Yup.string().required("Required"),
      // eventCost: eventType?.value === "tennis" ? null : Yup.string().required("Required"),
      maxPlayers: Yup.string().required("Required"),
    }),
    onSubmit: () => {
      if (!allFieldsValidated) return

      editEvent()
    },
  })

  const validateOtherFields = () => {
    let hasIssues = false

    // Validate event costs
    hasIssues = costsRef.current.validateFields()

    // Validate event start date
    if (eventStartDate === null) {
      setEventStartDateError("Required")
      hasIssues = true
    }

    // Validate event end date
    if (eventEndDate === null) {
      setEventEndDateError("Required")
      hasIssues = true
    }

    setAllFieldsValidated(!hasIssues)

    return hasIssues
  }

  const editEvent = async () => {
    try {
      const formData = new FormData()
      formData.append("eventId", event._id)
      formData.append("eventName", formik.values.eventName)
      formData.append("eventDescription", formik.values.eventDescription)
      formData.append("eventVenue", formik.values.eventVenue)
      formData.append("geoLocation", formik.values.geoLocation)
      formData.append("eventStartDate", commonFunctions.formatDate(moment(eventStartDate)))
      formData.append("eventEndDate", commonFunctions.formatDate(moment(eventEndDate)))
      formData.append("eventCost", JSON.stringify(costsRef.current.getCosts()))
      formData.append("maxPlayers", formik.values.maxPlayers)
      formData.append("restart", restart)
      formData.append("flyer", flyer)

      request(
        {
          api: ApiService.editEvent,
          body: formData,
          config: {
            displayError: false,
          },
        },
        (_, err) => {
          if (err) return

          formik.resetForm()
          refreshEvent()
          setOpen(false)
          setEventStartDate(null)
          setEventEndDate(null)

          renderSuccessToast("Event Updated")
        }
      )
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={() => {}}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-start justify-center p-4 sm:mt-[6%] sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-md bg-white text-left shadow-md transition-all sm:my-8 w-full sm:max-w-[40em] h-full">
                <div className="bg-white">
                  <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
                    <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl">
                      Update Event
                    </h1>
                    <form className="space-y-4 md:space-y-6">
                      <div>
                        <TextField
                          label="Event Name "
                          name="eventName"
                          type="text"
                          value={formik.values.eventName}
                          onChange={formik.handleChange}
                          error={formik.errors.eventName}
                          touched={formik.touched.eventName}
                          onBlur={formik.handleBlur}
                          required
                        />
                      </div>
                      <div>
                        <TextArea
                          label="Event description"
                          name="eventDescription"
                          type="text"
                          value={formik.values.eventDescription}
                          onChange={formik.handleChange}
                          error={formik.errors.eventDescription}
                          touched={formik.touched.eventDescription}
                          onBlur={formik.handleBlur}
                          required
                        />
                      </div>
                      <div className="grid grid-cols-1 gap-6 mt-4 sm:grid-cols-2">
                        <div>
                          <SelectDate
                            label="Event Start Date"
                            name="causeStartDate"
                            value={eventStartDate}
                            placeholder={eventStartDate}
                            onChange={({ date }) => {
                              setEventStartDate(date)
                              setEventStartDateError(null)
                              setDateError(null)
                              // var duration = moment.duration({ days: 1 })
                              setMinDates({
                                ...minDates,
                                // eventEndDate: moment(date).add(duration).toDate(),
                                eventEndDate: moment(date).toDate(),
                              })
                            }}
                            options={{
                              minDate: minDates["eventStartDate"],
                            }}
                            hasError={eventStartDateError}
                            error={eventStartDateError}
                            required
                          />
                        </div>
                        <div>
                          <SelectDate
                            label="Event Start Date"
                            name="eventEndDate"
                            value={eventEndDate}
                            placeholder={eventEndDate}
                            onChange={({ date }) => {
                              setEventEndDate(date)
                              setEventEndDateError(null)
                            }}
                            options={{
                              minDate: minDates["eventEndDate"],
                              onOpen: [
                                function (selectedDates, dateStr, instance) {
                                  if (eventStartDate === null) {
                                    setDateError(true)
                                    instance.close()
                                  }
                                },
                              ],
                            }}
                            hasError={eventEndDateError}
                            error={eventEndDateError}
                            required
                          />
                          {dateError && (
                            <div className="mt-1 text-xs text-red-400">
                              Please select <b>Event Start Date</b>
                            </div>
                          )}
                        </div>
                      </div>

                      <RenderCost
                        costs={costs}
                        setCosts={setCosts}
                        eventType={eventType}
                        ref={costsRef}
                        disabled={!eventType}
                      />

                      <div>
                      <TextField
                          label="Event Venue"
                          name="eventVenue"
                          type="text"
                          value={formik.values.eventVenue}
                          onChange={formik.handleChange}
                          error={formik.errors.eventVenue}
                          touched={formik.touched.eventVenue}
                          onBlur={formik.handleBlur}
                        />
                      </div>

<div>
                        <TextField
                          label="Geo Location"
                          name="geoLocation"
                          type="text"
                          value={formik.values.geoLocation}
                          onChange={formik.handleChange}
                          error={formik.errors.geoLocation}
                          touched={formik.touched.geoLocation}
                          onBlur={formik.handleBlur}
                        />
                      </div>

                      <div>
                        <TextField
                          label="Max players"
                          name="maxPlayers"
                          type="text"
                          value={formik.values.maxPlayers}
                          onChange={formik.handleChange}
                          error={formik.errors.maxPlayers}
                          touched={formik.touched.maxPlayers}
                          onBlur={formik.handleBlur}
                          required
                        />
                      </div>

                      <FileSelector
                        label="Flyer"
                        onSelected={onSelectFlyer}
                        onClear={() => setFlyer(null)}
                      />
                    </form>
                  </div>
                </div>

                {error && <Alert type="danger">{message}</Alert>}

                <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                  <Button
                    onClick={() => {
                      validateOtherFields()
                      formik.handleSubmit()
                    }}
                    className="ml-3"
                    loading={loading}
                    loadingText="Updating...."
                  >
                    Continue
                  </Button>
                  <Button
                    variant="secondary"
                    onClick={() => {
                      formik.resetForm()
                      refreshEvent()
                      setOpen(false)
                    }}
                  >
                    Cancel
                  </Button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

const RenderCost = forwardRef(({ costs, disabled }, ref) => {
  const [fields, setFields] = useState([])

  useEffect(() => {
    setFields(costs)
  }, [costs])

  useImperativeHandle(ref, () => ({
    validateFields() {
      let hasError = false
      setFields(
        fields.map((it) => {
          if (it["cost"] === "" || it["cost"] === 0) {
            it["error"] = "Required"
            it["touched"] = true
            hasError = true
          } else {
            it["error"] = false
          }
          return it
        })
      )
      return hasError
    },
    getCosts() {
      const mapped = fields.map((item) => ({
        [item.value]: item.cost,
      }))
      return Object.assign({}, ...mapped)
    },
  }))

  return (
    <div className="grid grid-cols-1 gap-6 mt-4 sm:grid-cols-2">
      {fields.map((currField, idx) => (
        <div key={idx}>
          <TextField
            label={currField.name}
            name={currField.value}
            type="number"
            value={currField.cost}
            onChange={(e) => {
              setFields(
                fields.map((it) => {
                  if (it.value === currField.value) {
                    it["cost"] = e.target.value
                    if (it["cost"] === "") it["error"] = "Required"
                    else it["error"] = false
                  }
                  return it
                })
              )
            }}
            error={currField.error}
            touched={currField.touched}
            onBlur={() => {
              setFields(
                fields.map((it) => {
                  if (it.value === currField.value) {
                    it["touched"] = true
                    if (currField.cost === "") it["error"] = "Required"
                  }
                  return it
                })
              )
            }}
            required
            disabled={disabled}
          />
        </div>
      ))}
    </div>
  )
})

export default EditEvent
