import { Fragment, useEffect, useState } from "react"
import { Dialog, Transition } from "@headlessui/react"
import SelectMenu from "../../../components/SelectMenu"
import ApiService from "../../../services/ApiService"
import { HandleDate } from "../../../utils/Helpers"
import Button from "components/Button"
import useApiHandler from "hooks/useApiHandler"
import ProgressLoading from "components/ProgressLoading"

const PreviousCauses = ({ open, setOpen }) => {
  const { request, loading } = useApiHandler()

  const [selectSubmission, setSelectSubmission] = useState(null)
  const [selectSubmissionError, setSelectSubmissionError] = useState(null)

  useEffect(() => {
    if (open) {
      getAllSubmissions()
    }
    // eslint-disable-next-line
  }, [open])

  const [dropdownItems, setDropdwonItems] = useState([])

  const getAllSubmissions = async () => {
    request(
      {
        api: ApiService.getAllSubmissions,
        body: { onlyPrevious: false },
        config: {
          displayError: true,
        },
      },
      (res, err) => {
        if (err) return

        let submissions = res.data.submissions
        setDropdwonItems(
          submissions.map((sub) => {
            return {
              name: HandleDate(sub["causeStartDate"]) + " ----- " + HandleDate(sub["causeEndDate"]),
              value: sub._id,
            }
          })
        )
      }
    )
  }

  const getCauses = async () => {
    if (selectSubmission == null || selectSubmission.value == null) {
      setSelectSubmissionError("Required")
      return
    }
    window.location.href = "/admin/submitted-causes?windowId=" + selectSubmission.value
    closeDialog()
  }

  const closeDialog = () => {
    setSelectSubmission(null)
    setOpen(false)
  }

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={() => {}}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-start justify-center p-4 sm:mt-[6%] sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-unset rounded-md bg-white text-left shadow-md transition-all sm:my-8 w-full sm:max-w-[40em] h-full">
                <div className="bg-white">
                  <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
                    <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl">
                      Preview Causes
                    </h1>
                    <form className="space-y-4 md:space-y-6">
                      {loading && <ProgressLoading />}

                      {!loading && (
                        <div>
                          <SelectMenu
                            label="Cause windows"
                            items={dropdownItems}
                            onChange={(data) => {
                              setSelectSubmission(data)
                              setSelectSubmissionError(null)
                            }}
                            value={selectSubmission?.value}
                            hasError={selectSubmissionError}
                            error={selectSubmissionError}
                            required
                          />
                        </div>
                      )}
                    </form>
                  </div>
                </div>

                {!loading && (
                  <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                    <Button onClick={getCauses} className="sm:ml-3 w-full">
                      Get causes
                    </Button>
                    <Button variant="secondary" onClick={closeDialog} className="w-full">
                      Cancel
                    </Button>
                  </div>
                )}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

export default PreviousCauses
