import { Fragment } from "react"

const Card = ({ children, className = "" }) => {
  return (
    <Fragment>
      <div className="flex justify-center w-full my-[2rem]">
        <div className="w-full max-w-[80rem] mx-2 h-full">
          <div className={`w-[100%] mx-auto bg-white rounded-lg border border-gray-200 ${className}`}>
            {/* <div className="p-4 mb-4 bg-white border border-gray-200 rounded-lg shadow-sm sm:p-6 xl:mb-0"> */}
            {children}
          </div>
        </div>
      </div>
    </Fragment>
  )
}

export default Card
