import { useCallback, useEffect } from "react"
import ApiService from "../../services/ApiService"
import useApiHandler from "../../hooks/useApiHandler"
import Button from "../../components/Button"
import Alert from "../../components/Alert"

const SignUpSuccess = () => {
  const { request, loading, message, error } = useApiHandler()
  console.log(error, message)

  const executePayment = useCallback(async () => {
    var queryString = window.location.search
    var params = new URLSearchParams(queryString)
    var paymentId = params.get("paymentId")
    var payerId = params.get("PayerID")

    var retrievedObject = localStorage.getItem("userInfo")
    var userInfo = JSON.parse(retrievedObject)

    let body = {
      payerId: payerId,
      paymentId: paymentId,
      userInfo: userInfo,
    }

    request({
      api: ApiService.signUpSuccess,
      body: body,
      config: {
        displayError: false,
      },
    })
  }, [request])

  useEffect(() => {
    executePayment()
  }, [executePayment])

  const backTo = () => {
    window.location.href = "/signup"
  }
  const backToSignIn = () => {
    window.location.href = "/signin"
  }

  return (
    <>
      <div className="flex flex-row  justify-center px-6 py-8 mx-auto lg:py-0 my-[6rem]">
        <div className="w-full bg-white rounded-lg shadow border md:mt-0 sm:max-w-[50rem] xl:p-0">
          <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
            <div className="mt-3 text-center flex flex-col items-center">
              {loading && (
                <>
                  <div className="w-8 h-8 border-4 border-dashed rounded-full animate-spin border-blue-700"></div>
                  <h3 className="text-lg leading-6 font-medium text-gray-900">
                    Completing your payment please wait.
                  </h3>{" "}
                </>
              )}

              {!loading && !error && (
                <>
                  <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      className="text-green-600"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M5 13l4 4L19 7"
                      ></path>
                    </svg>
                  </div>

                  <h3 className="text-lg leading-6 font-medium text-gray-900">Thanks for registering</h3>
                  <div className="mt-2 px-7 py-3">
                    <p className="text-sm text-gray-500"></p>
                    <Alert type="success">
                      Account has been successfully registered. Transaction ID will be sent to you in the
                      mail
                    </Alert>
                    <div className="mt-5">
                      <Button onClick={backToSignIn}>Go to Singin</Button>
                    </div>
                  </div>
                </>
              )}

              {!loading && error && (
                <>
                  <Alert type="danger">{message}</Alert>
                  <div className="mt-2 px-7 py-3">
                    <Button onClick={backTo}>Back to Signup</Button>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default SignUpSuccess
