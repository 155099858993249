class Constants {
  // HOST_API = "http://localhost:8081/api/"
  HOST_API = "https://dhvanifoundation.org/api/"

  MEMBERSHIP_AMOUNT = 100
  MEMBERSHIP_TRANSACTION_TYPE_PAYPAL = "Paypal"
  MEMBERSHIP_TRANSACTION_TYPE_OFFLINE = "Offline"
  MEMBERSHIP_PAYMENT_TYPE = "Membership"
  MEMBERSHIP_PAYMENT_TYPE_RENEWAL = "Membership-Renewal"
  MEMBERSHIP_INTENT = "SALE"
  MEMBERSHIP_CURRENCY = "USD"

  EVENT_REGISTRATION_PAYMENT_TYPE = "Event-Registration"
  EVENT_REGISTRATION_TRANSACTION_TYPE_PAYPAL = "Paypal"
  EVENT_REGISTRATION_TRANSACTION_TYPE_OFFLINE = "Offline"
  INTENT = "SALE"
  CURRENCY = "USD"

  DONATION_PAYMENT_TYPE = "Donation"
  DONATION_TRANSACTION_TYPE_PAYPAL = "Paypal"
  DONATION_TRANSACTION_TYPE_OFFLINE = "Offline"
  DONATION_INTENT = "SALE"
  DONATION_CURRENCY = "USD"

  ADDRESS = "3910 Old Highway 94 S, STE 110 | Saint Charles, MO 63304"
}

export default new Constants()
