import { useSelector } from "react-redux"
import { useState, useRef } from "react"
import ApiService from "services/ApiService"
import { useFormik } from "formik"
import Loader from "components/Loader"
import TextField from "components/TextField"
import TextArea from "components/Textarea"
import * as Yup from "yup"
import useApiHandler from "hooks/useApiHandler"
import useToast from "hooks/useToast"
import Button from "components/Button"
import FileSelector from "components/FileSelector"
import commonFunctions from "utils/CommonFunctions"

const AddCause = () => {
  const { request, loading } = useApiHandler()

  const { renderSuccessToast } = useToast()

  const authState = useSelector((state) => state.auth)

  const [documents, setDocuments] = useState([])
  const [documentsError, setDocumentsError] = useState(null)

  const documentsRef = useRef()

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      causeTitle: "",
      email: authState?.user?.email,
      aboutTheCause: "",
      causeDescription: "",
      causeBudget: "",
      phoneNumber: "",
      websiteUrl: "",
    },
    validationSchema: Yup.object().shape({
      causeTitle: Yup.string().required("Required"),
      email: Yup.string().required("Required"),
      aboutTheCause: Yup.string().required("Required"),
      causeDescription: Yup.string().required("Required"),
      causeBudget: Yup.number().typeError('Must be a number').required("Required"),
      phoneNumber: Yup.string().required("Required"),
    }),
    onSubmit: (values) => {
      if (documentsRef.current.localError) return
      addCause(values)
    },
  })

  // console.log(formik.errors)
  // console.log(formik.touched)

  const addCause = async (values) => {
    try {
      let { causeTitle, email, aboutTheCause, causeDescription, causeBudget, phoneNumber, websiteUrl } =
        values

      const formData = new FormData()
      formData.append("causeTitle", causeTitle)
      formData.append("email", email)
      formData.append("aboutTheCause", aboutTheCause)
      formData.append("causeDescription", causeDescription)
      formData.append("causeBudget", causeBudget)
      formData.append("phoneNumber", commonFunctions.phoneNumberToText(phoneNumber))
      formData.append("websiteUrl", websiteUrl)
      formData.append("status", false)

      for (let i = 0; i < documents.length; i++) {
        formData.append("files", documents[i])
      }

      request(
        {
          api: ApiService.addCause,
          body: formData,
          config: {
            displayError: true,
          },
        },
        (_, err) => {
          if (err) return

          formik.resetForm()
          documentsRef.current.clearFiles()
          renderSuccessToast("Cause submitted")
        }
      )
    } catch (error) {
      console.error(error)
    }
  }

  const onSelectDocuments = (e) => {
    setDocuments([...e.target.files])
  }

  return (
    <section>
      <Loader open={loading} />

      <header className="bg-white shadow">
        <div className="mx-auto py-3 px-4 sm:px-6 lg:px-8 flex justify-between">
          <h1 className="text-2xl font-bold tracking-tight text-gray-900">Submit Cause</h1>
        </div>
      </header>

      <div className="flex justify-center w-full my-[2rem]">
        <div className="w-full max-w-[80rem] mx-2 h-full">
          <div className=" w-[100%] mx-auto bg-white rounded-lg shadow border p-5">
            <form className="space-y-4 m-4 md:space-y-6">
              <div className="grid grid-cols-1 gap-6 mt-4 sm:grid-cols-2">
                <div>
                  <TextField
                    label="Cause Title"
                    name="causeTitle"
                    type="text"
                    value={formik.values.causeTitle}
                    onChange={formik.handleChange}
                    error={formik.errors.causeTitle}
                    touched={formik.touched.causeTitle}
                    onBlur={formik.handleBlur}
                    required
                  />
                </div>

                <div>
                  <TextField
                    label="Your Email"
                    disabled={true}
                    name="email"
                    type="text"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    error={formik.errors.email}
                    touched={formik.touched.email}
                    onBlur={formik.handleBlur}
                    required
                  />
                </div>

                <div>
                  <TextArea
                    label="About The Cause"
                    name="aboutTheCause"
                    type="text"
                    value={formik.values.aboutTheCause}
                    onChange={formik.handleChange}
                    error={formik.errors.aboutTheCause}
                    touched={formik.touched.aboutTheCause}
                    onBlur={formik.handleBlur}
                    required
                  />
                </div>

                <div>
                  <TextArea
                    label="Cause Description"
                    name="causeDescription"
                    type="text"
                    value={formik.values.causeDescription}
                    onChange={formik.handleChange}
                    error={formik.errors.causeDescription}
                    touched={formik.touched.causeDescription}
                    onBlur={formik.handleBlur}
                    required
                  />
                </div>

                <div>
                  <TextField
                    label="Cause Budget"
                    helpText="(Please enter the amount in USD)"
                    name="causeBudget"
                    type="text"
                    value={formik.values.causeBudget}
                    onChange={formik.handleChange}
                    error={formik.errors.causeBudget}
                    touched={formik.touched.causeBudget}
                    onBlur={formik.handleBlur}
                    required
                  />
                </div>

                <div>
                  <TextField
                    label="Phone Number"
                    name="phoneNumber"
                    type="text"
                    placeholder="(XXX) XXX-XXXX"
                    phoneNumberFormat={true}
                    value={formik.values.phoneNumber}
                    onChange={formik.handleChange}
                    error={formik.errors.phoneNumber}
                    touched={formik.touched.phoneNumber}
                    onBlur={formik.handleBlur}
                    required
                  />
                </div>

                <div>
                  <TextField
                    label="Website Url"
                    name="websiteUrl"
                    type="text"
                    value={formik.values.websiteUrl}
                    onChange={formik.handleChange}
                    error={formik.errors.websiteUrl}
                    touched={formik.touched.websiteUrl}
                    onBlur={formik.handleBlur}
                  />
                </div>
              </div>

              <FileSelector
                label="Documents"
                helpText="(Multiple files accepted)"
                description="Choose files"
                onSelected={onSelectDocuments}
                onClear={() => setDocuments([])}
                ref={documentsRef}
                multiple
                maxSize="50"
              />

              <div className="text-end">
                <Button onClick={formik.handleSubmit}>Submit</Button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  )
}

export default AddCause
