import Button from "../../../components/Button"
import Alert from "../../../components/Alert"
import Card from "../../../components/Card"
import { useNavigate } from "react-router-dom"
import { useSelector } from "react-redux"

const RenewalCancel = () => {
  const navigate = useNavigate()
  const auth = useSelector((state) => state.auth)

  const backTo = () => {
    if (auth.isLoggedIn) {
      navigate("/my-membership")
    } else {
      navigate("/signin")
    }
  }

  return (
    <>
      <div className="flex flex-row  justify-center px-6 py-8 mx-auto lg:py-0 my-[6rem]">
        <Card className="sm:max-w-[50rem]">
          <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
            <div className="mt-3 text-center flex flex-col items-center">
              <Alert type="danger">Your payment has been cancelled.</Alert>
              <div className="mt-2 px-7 py-3">
                <Button onClick={backTo}>Go back</Button>
              </div>
            </div>
          </div>
        </Card>
      </div>
    </>
  )
}

export default RenewalCancel
