import { useFormik } from "formik"
import TextField from "../../../components/TextField"
import * as Yup from "yup"
import ApiService from "../../../services/ApiService"
import CustomDialog from "../../../components/Dialog"
import Button from "../../../components/Button"
import Alert from "../../../components/Alert"
import useApiHandler from "../../../hooks/useApiHandler"
import useToast from "../../../hooks/useToast"

export default function ChangePassword({ open, setOpen, user_id }) {
  const { loading, request, error, message } = useApiHandler()
  const { renderSuccessToast } = useToast()

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      curr_password: "",
      new_password: "",
      con_new_password: "",
    },
    validationSchema: Yup.object().shape({
      curr_password: Yup.string().required("Required"),
      new_password: Yup.string().required("Required"),
      con_new_password: Yup.string()
        .required("Required")
        .min(3, "too short")
        .max(10, "too long")
        .oneOf([Yup.ref("new_password"), null], "Passwords must match"),
    }),
    onSubmit: () => {
      change_password()
    },
  })

  const change_password = async () => {
    const { curr_password, new_password } = formik.values

    const data = {
      user_id,
      curr_password,
      new_password,
    }

    request(
      {
        api: ApiService.change_password,
        body: data,
        config: {
          displayError: false,
        },
      },
      (_, err) => {
        if (err) return

        formik.resetForm()
        setOpen(false)
        renderSuccessToast("Password changed.")
      }
    )
  }

  return (
    <CustomDialog open={open} header="Change Password" size="sm">
      <CustomDialog.Content>
        <form className="space-y-4 md:space-y-6">
          <div className="grid grid-cols-1 gap-6 mt-4">
            <div>
              <TextField
                label="Current Password"
                name="curr_password"
                type="password"
                value={formik.values.curr_password}
                onChange={formik.handleChange}
                error={formik.errors.curr_password}
                touched={formik.touched.curr_password}
                onBlur={formik.handleBlur}
                required
              />
            </div>

            <div>
              <TextField
                label="New Password"
                name="new_password"
                type="password"
                value={formik.values.new_password}
                onChange={formik.handleChange}
                error={formik.errors.new_password}
                touched={formik.touched.new_password}
                onBlur={formik.handleBlur}
                required
              />
            </div>

            <div>
              <TextField
                label="Confirm New Password"
                name="con_new_password"
                type="password"
                value={formik.values.con_new_password}
                onChange={formik.handleChange}
                error={formik.errors.con_new_password}
                touched={formik.touched.con_new_password}
                onBlur={formik.handleBlur}
                required
              />
            </div>
          </div>
        </form>
        {!loading && error && <Alert type="danger">{message}</Alert>}
      </CustomDialog.Content>
      <CustomDialog.Footer>
        <Button
          loading={loading}
          loadingText="Updating..."
          onClick={formik.handleSubmit}
          className="sm:ml-3 w-full"
        >
          Change
        </Button>
        <Button
          variant="secondary"
          onClick={() => {
            formik.resetForm()
            setOpen(false)
          }}
          className="w-full"
        >
          Cancel
        </Button>
      </CustomDialog.Footer>
    </CustomDialog>
  )
}
