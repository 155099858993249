import { useState, useEffect } from "react"
import ApiService from "services/ApiService"
import Dialog from "components/Dialog"
import Button from "components/Button"
import Alert from "components/Alert"
import Folder from "./folder"
import useApiHandler from "hooks/useApiHandler"
import useToast from "hooks/useToast"

const DeleteFolders = ({ open, setOpen, reload = () => {}, currentPath, getFolders = () => {} }) => {
  const { loading, request } = useApiHandler()

  const { renderSuccessToast } = useToast()

  const [availableFolders, setAvailableFolders] = useState([])
  const [selectedFolders, setSelectedFolders] = useState([])
  const [deleteError, setDeleteError] = useState(null)

  const deleteFolders = async () => {
    if (selectedFolders.length === 0) {
      setDeleteError("Please select folders to delete")
      return
    }
    request(
      {
        api: ApiService.delete_folders,
        body: selectedFolders,
        config: {
          displayError: false,
        },
      },
      (_, err) => {
        if (err) {
          setDeleteError(err?.response?.data?.message)
          return
        }

        reload(currentPath)
        setSelectedFolders([])
        setOpen(false)
        renderSuccessToast("Selected folders deleted!")
      }
    )
  }

  const onSelectedFolder = (index) => {
    const updatedFolders = [...availableFolders]
    updatedFolders[index] = {
      ...updatedFolders[index],
      active: true,
    }
    setAvailableFolders(updatedFolders)

    const selectedFolderId = availableFolders[index]._id
    setSelectedFolders((prevSelectedFolders) => [...prevSelectedFolders, selectedFolderId])
  }

  useEffect(() => {
    if (open) {
      setAvailableFolders(getFolders())
    }
  }, [open])

  return (
    <Dialog open={open} header={"Delete Folders"} size="xl">
      <Dialog.Content>
        <span>Please select folders to delete</span>

        <Alert type="warning">
          Note: If a folder is deleted. All the files associated with the folder also deleted.
        </Alert>

        <Folder folders={availableFolders} onSelect={onSelectedFolder} />

        {!loading && <Alert type="error">{deleteError}</Alert>}
      </Dialog.Content>
      <Dialog.Footer>
        <Button
          className="sm:ml-3 w-full"
          onClick={deleteFolders}
          loading={loading}
          loadingText="deleting...."
        >
          Continue
        </Button>
        <Button
          variant="secondary"
          className="w-full"
          onClick={() => {
            setSelectedFolders([])
            setDeleteError(null)
            setOpen(false)
          }}
        >
          Cancel
        </Button>
      </Dialog.Footer>
    </Dialog>
  )
}

export default DeleteFolders
