import { useEffect, useState } from "react"
import { useFormik } from "formik"
import TextField from "components/TextField"
import TextArea from "components/Textarea"
import * as Yup from "yup"
import ApiService from "services/ApiService"
import CustomDialog from "components/Dialog"
import Button from "components/Button"
import Switch from "components/Switch"
import useApiHandler from "hooks/useApiHandler"
import useToast from "hooks/useToast"
import Alert from "components/Alert"

export default function EditMember({ open, setOpen, member, memberCallback = () => {} }) {
  const { request, loading, error, message } = useApiHandler()

  const { renderSuccessToast } = useToast()

  const [user, setUser] = useState(null)
  const [isUserActive, setIsUserActive] = useState(false)
  const [isAdmin, setIsAdmin] = useState(false)

  useEffect(() => {
    if (member) {
      setUser(member)
      setIsUserActive(member["isActive"])
      setIsAdmin(member["isAdmin"])
    }
  }, [member])

  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      firstName: user?.firstName || "",
      lastName: user?.lastName || "",
      userName: user?.userName || "",
      phone: user?.phone || "",
      addressLine: user?.address?.addressLine1 || "",
      city: user?.address?.city || "",
      state: user?.address?.state || "",
      country: user?.address?.country || "",
      zipcode: user?.address?.zipcode || "",
    },
    validationSchema: Yup.object().shape({
      firstName: Yup.string().required("Required"),
      lastName: Yup.string().required("Required"),
      userName: Yup.string().required("Required"),
      phone: Yup.string()
        .required("Required")
        .matches(phoneRegExp, "Phone number is not valid")
        .min(10, "too short")
        .max(10, "too long"),
    }),
    onSubmit: (values) => {
      updateMember()
    },
  })

  const updateMember = async () => {
    const { firstName, lastName, userName, phone, addressLine, state, country, zipcode, city } =
      formik.values

    const data = {
      _id: user["_id"],
      firstName: firstName,
      lastName: lastName,
      userName: userName,
      phone: phone,
      addressLine1: addressLine,
      city: city,
      state: state,
      country: country,
      zipcode: zipcode,
      isActive: isUserActive,
      isAdmin: isAdmin,
    }

    request(
      {
        api: ApiService.updateProfile,
        body: data,
        config: {
          displayError: false,
        },
      },
      (_, err) => {
        if (err) return

        memberCallback()
        setOpen(false)
        renderSuccessToast("Member updated.")
      }
    )
  }

  return (
    <CustomDialog open={open} header="Edit Member" size="lg">
      <CustomDialog.Content>
        <form className="space-y-4 md:space-y-6">
          <div className="grid grid-cols-1 gap-6 mt-4 sm:grid-cols-2">
            <div>
              <TextField
                label="First Name "
                name="firstName"
                type="text"
                value={formik.values.firstName}
                onChange={formik.handleChange}
                error={formik.errors.firstName}
                touched={formik.touched.firstName}
                onBlur={formik.handleBlur}
              />
            </div>

            <div>
              <TextField
                label="Last Name"
                name="lastName"
                type="text"
                value={formik.values.lastName}
                onChange={formik.handleChange}
                error={formik.errors.lastName}
                touched={formik.touched.lastName}
                onBlur={formik.handleBlur}
              />
            </div>

            <div>
              <TextField
                label="User Name"
                name="userName"
                type="text"
                value={formik.values.userName}
                onChange={formik.handleChange}
                error={formik.errors.userName}
                touched={formik.touched.userName}
                onBlur={formik.handleBlur}
              />
            </div>

            <div>
              <TextField
                label="Phone"
                name="phone"
                type="text"
                value={formik.values.phone}
                onChange={formik.handleChange}
                error={formik.errors.phone}
                touched={formik.touched.phone}
                onBlur={formik.handleBlur}
              />
            </div>
          </div>

          <div>
            <div>
              <TextArea
                label="Address Line"
                name="addressLine"
                type="text"
                rows={3}
                value={formik.values.addressLine}
                onChange={formik.handleChange}
                error={formik.errors.addressLine}
                touched={formik.touched.addressLine}
                onBlur={formik.handleBlur}
              />
            </div>
          </div>

          <div className="grid grid-cols-1 gap-6 mt-4 sm:grid-cols-2">
            <div>
              <TextField
                label="City"
                name="city"
                type="text"
                value={formik.values.city}
                onChange={formik.handleChange}
                error={formik.errors.city}
                touched={formik.touched.city}
                onBlur={formik.handleBlur}
              />
            </div>

            <div>
              <TextField
                label="State"
                name="state"
                type="text"
                value={formik.values.state}
                onChange={formik.handleChange}
                error={formik.errors.state}
                touched={formik.touched.state}
                onBlur={formik.handleBlur}
              />
            </div>

            <div>
              <TextField
                label="Country"
                name="country"
                type="text"
                value={formik.values.country}
                onChange={formik.handleChange}
                error={formik.errors.country}
                touched={formik.touched.country}
                onBlur={formik.handleBlur}
              />
            </div>

            <div>
              <TextField
                label="Zip"
                name="zipcode"
                type="text"
                value={formik.values.zipcode}
                onChange={formik.handleChange}
                error={formik.errors.zipcode}
                touched={formik.touched.zipcode}
                onBlur={formik.handleBlur}
              />
            </div>
          </div>

          {error && <Alert type="danger">{message}</Alert>}

          <Switch
            label="Member Status"
            checked={isUserActive || false}
            onChange={(e) => setIsUserActive(e.target.checked)}
          />

          <Switch
            label="Make member as Admin"
            checked={isAdmin || false}
            onChange={(e) => setIsAdmin(e.target.checked)}
          />
        </form>
      </CustomDialog.Content>
      <CustomDialog.Footer>
        <Button
          className="sm:ml-3 w-full"
          onClick={formik.handleSubmit}
          loading={loading}
          loadingText="Updating...."
        >
          Edit Member
        </Button>
        <Button onClick={() => setOpen(false)} variant="secondary" className="w-full">
          Cancel
        </Button>
      </CustomDialog.Footer>
    </CustomDialog>
  )
}
