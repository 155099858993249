import { useFormik } from "formik"
import TextField from "../../../components/TextField"
import TextArea from "../../../components/Textarea"
import * as Yup from "yup"
import ApiService from "../../../services/ApiService"
import CustomDialog from "components/Dialog"
import Button from "components/Button"
import useApiHandler from "hooks/useApiHandler"
import useToast from "hooks/useToast"
import Alert from "components/Alert"

export default function UpdateCause({ open, setOpen, cause, causeUpdateCallback }) {
  const { request, loading, error, message } = useApiHandler()

  const { renderSuccessToast } = useToast()

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      causeName: cause?.causeName,
      causeFund: cause?.causeFund,
      aboutTheCause: cause?.aboutTheCause,
      causeDescription: cause?.causeDescription,
    },
    validationSchema: Yup.object().shape({
      causeName: Yup.string().required("Required"),
      causeFund: Yup.string().required("Required"),
      aboutTheCause: Yup.string().required("Required"),
      causeDescription: Yup.string().required("Required"),
    }),
    onSubmit: () => {
      updateCause()
    },
  })

  const updateCause = async () => {
    const { causeName, causeFund, aboutTheCause, causeDescription } = formik.values

    const body = {
      id: cause["_id"],
      causeName: causeName,
      causeFund: causeFund,
      aboutTheCause: aboutTheCause,
      causeDescription: causeDescription,
    }

    request(
      {
        api: ApiService.updateCause,
        body: body,
        config: {
          displayError: false,
        },
      },
      (_, err) => {
        if (err) return

        causeUpdateCallback()
        setOpen(false)
        renderSuccessToast("Cause updated")
      }
    )
  }

  return (
    <CustomDialog open={open} header="Update cause" size="lg">
      <CustomDialog.Content>
        <form className="space-y-4 md:space-y-6">
          <div>
            <TextField
              label="Cause Name"
              name="causeName"
              type="text"
              value={formik.values.causeName}
              onChange={formik.handleChange}
              error={formik.errors.causeName}
              touched={formik.touched.causeName}
              onBlur={formik.handleBlur}
              required
            />
          </div>

          <div>
            <TextField
              label="Cause Fund"
              name="causeFund"
              type="text"
              value={formik.values.causeFund}
              onChange={formik.handleChange}
              error={formik.errors.causeFund}
              touched={formik.touched.causeFund}
              onBlur={formik.handleBlur}
              required
            />
          </div>

          <div>
            <TextArea
              label="About the cause"
              name="aboutTheCause"
              type="text"
              value={formik.values.aboutTheCause}
              onChange={formik.handleChange}
              error={formik.errors.aboutTheCause}
              touched={formik.touched.aboutTheCause}
              onBlur={formik.handleBlur}
              required
            />
          </div>

          <div>
            <TextArea
              label="Cause description"
              name="causeDescription"
              type="text"
              value={formik.values.causeDescription}
              onChange={formik.handleChange}
              error={formik.errors.causeDescription}
              touched={formik.touched.causeDescription}
              onBlur={formik.handleBlur}
              required
            />
          </div>

          {error && <Alert type="danger">{message}</Alert>}
        </form>
      </CustomDialog.Content>
      <CustomDialog.Footer>
        <Button
          onClick={() => {
            formik.handleSubmit()
          }}
          className="sm:ml-3 w-full"
          loading={loading}
          loadingText="Updating...."
        >
          Update
        </Button>
        <Button
          variant="secondary"
          onClick={() => {
            formik.resetForm()
            setOpen(false)
          }}
          className="w-full"
        >
          Cancel
        </Button>
      </CustomDialog.Footer>
    </CustomDialog>
  )
}
