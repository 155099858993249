import { useEffect, useState } from "react"
import Loader from "../../../components/Loader"
import ApiService from "../../../services/ApiService"
import ProjectCard from "../ListItem"
import Card from "../../../components/Card"
import useApiHandler from "hooks/useApiHandler"

const PresentProjects = () => {
  const { request, loading } = useApiHandler()

  const [projects, setProjects] = useState([])

  const getPreviousProjects = async () => {
    request(
      {
        api: ApiService.getPresentProjects,
        config: {
          displayError: true,
        },
      },
      (res, err) => {
        if (err) return

        setProjects(res.data.projects)
      }
    )
  }

  useEffect(() => {
    getPreviousProjects()
    // eslint-disable-next-line
  }, [])

  return (
    <section>
      <Loader open={loading} />

      <header className="bg-white shadow">
        <div className="mx-auto py-3 px-4 sm:px-6 lg:px-8 flex justify-between">
          <h1 className="text-2xl font-bold tracking-tight text-gray-900">Present Projects</h1>
        </div>
      </header>

      <div className="mx-auto max-w-7xl py-6 px-4 sm:px-6 lg:max-w-[100rem] lg:px-8">
        <div className="mt-6 grid grid-cols-1 gap-y-10 gap-x-6 sm:grid-cols-2 lg:grid-cols-4 xl:gap-x-8">
          {projects.map((project, index) => (
            <ProjectCard project={project} key={index} />
          ))}
        </div>

        {!loading && projects.length === 0 && (
          <Card className="text-center p-5">
            <h3 className="text-lg font-medium text-gray-700 ">No projects found</h3>
          </Card>
        )}
      </div>
    </section>
  )
}

export default PresentProjects
