import { useEffect, useState } from "react"
import Table from "components/Table/Table"
import ApiService from "services/ApiService"
import moment from "moment"
import Upload from "./Upload"
import EditMember from "./EditMember"
import Button from "components/Button"
import useApiHandler from "hooks/useApiHandler"

const Members = () => {
  const { request, loading, error, message } = useApiHandler()

  const [openUploadModel, setOpenUploadModel] = useState(false)
  const [editMember, setEditMember] = useState(false)
  const [member, setMember] = useState(null)

  const columns = [
    {
      name: "Name",
      selector: "name",
      cell: (row) => <span>{row.name}</span>,
    },
    {
      name: "Username",
      selector: "userName",
    },
    {
      name: "Email",
      selector: "email",
    },
    {
      name: "Phone",
      selector: "phone",
    },
    {
      name: "Membership Expire Date",
      selector: "memberShipExpireDate",
      cell: (row) => (
        <span>
          <strong>{moment(row.memberShipExpireDate).format("DD-MMM-YYYY")}</strong> <br />
          {row.daysToExpireMembership > 0 && (
            <span className={"text-green-600"}>Expires in {row.daysToExpireMembership} days</span>
          )}
          {row.daysToExpireMembership <= 0 && <span className={"text-red-600"}>Expired</span>}
        </span>
      ),
    },
    {
      name: "Status",
      selector: "status",
      // cell: (row) => <span>{row.isActive ? "Active" : "In-active"}</span>,
      cell: (row) => (
        <>
          <span
            className={`${row.isActive ? "bg-green-200" : "bg-red-200"} px-2 py-1 rounded-xl ${
              row.isActive ? "text-green-800" : "text-red-800"
            }`}
          >
            <strong>{row.isActive ? "Active" : "In-active"}</strong>
          </span>
          {row.isAdmin && (
            <span className={"bg-yellow-200 px-2 ml-1 py-1 rounded-xl text-yellow-800"}>
              <strong>Admin</strong>
            </span>
          )}
        </>
      ),
    },
    {
      name: "Actions",
      selector: "action",
      cell: (row) => (
        <div className="flex items-center space-x-2">
          <button
            onClick={() => {
              setMember(row)
              setEditMember(true)
            }}
            title="Edit Profile"
            className="text-gray-700 px-2 py-1 border border-gray-300 rounded-lg hover:bg-gray-200 transition duration-100"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-5 h-5"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L6.832 19.82a4.5 4.5 0 01-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 011.13-1.897L16.863 4.487zm0 0L19.5 7.125"
              />
            </svg>
          </button>
        </div>
      ),
    },
  ]

  const [members, setMembers] = useState([])

  useEffect(() => {
    getAllMembers()
  }, [])

  const getAllMembers = async () => {
    request(
      {
        api: ApiService.getAllMembers,
        config: {
          displayError: false,
        },
      },
      (res, err) => {
        if (err) return

        setMembers(res?.data?.members)
      }
    )
  }

  const closeModal = () => {
    setMember(null)
    setEditMember(false)
  }

  return (
    <div>
      <Upload open={openUploadModel} setOpen={setOpenUploadModel} onUploadComplete={getAllMembers} />

      <EditMember
        open={editMember}
        member={member}
        setOpen={closeModal}
        memberCallback={getAllMembers}
      />

      <header className="bg-white shadow">
        <div className="mx-auto py-3 px-4 sm:px-6 lg:px-8 flex justify-between">
          <h1 className="text-2xl font-bold tracking-tight text-gray-900">Members</h1>

          <div className="flex items-center">
            <div className="ml-3">
              <Button variant="secondary" onClick={() => setOpenUploadModel(true)}>
                Upload Members (Excel)
              </Button>
            </div>
          </div>
        </div>
      </header>

      <div className="mx-5">
        <Table columns={columns} data={members} loading={loading} error={error} errorMessage={message} />
      </div>
    </div>
  )
}

export default Members
