import useApiHandler from "hooks/useApiHandler"
import ApiService from "services/ApiService"
import useToast from "hooks/useToast"
import { useEffect, useState } from "react"
import Card from "components/Card"
import Alert from "components/Alert"
import Loader from "components/Loader"

const ViewSponsers = () => {
  const { request, loading } = useApiHandler()

  const { renderErrorToast, renderSuccessToast } = useToast()

  const [sponsers, setSponsers] = useState([])

  useEffect(() => {
    getSponsers()
  }, [])

  const getSponsers = () => {
    request(
      {
        api: ApiService.getSponsers,
        config: {
          displayError: false,
        },
      },
      (res, err) => {
        if (err) {
          renderErrorToast(err?.response?.data?.message)
          return
        }

        setSponsers(res?.data?.sponsers)
      }
    )
  }

  const onDelete = async (sponserId) => {
    request(
      {
        api: ApiService.removeSonser,
        body: sponserId,
        config: {
          displayError: false,
        },
      },
      (res, err) => {
        if (err) {
          renderErrorToast(err?.response?.data?.message)
          return
        }

        getSponsers()
        renderSuccessToast("Sponser removed")
      }
    )
  }

  return (
    <div>
      <Loader open={loading} />
      <Card className="max-w-lg mx-auto bg-white p-8 rounded shadow mt-10">
        <button
          onClick={() => (window.location.href = "/sponsers")}
          title="Delete project"
          className="text-gray-700 px-2 py-1 border border-gray-300 rounded-lg hover:bg-gray-200 transition duration-100"
        >
          Back
        </button>

        <h1 className="text-2xl font-bold mb-6">Sponsers List</h1>

        <ul>
          {sponsers.map((sponser, index) => (
            <li className="flex flex-grow items-center justify-between border-gray-300 py-2" key={index}>
              <div className="flex items-center space-x-10">
                <img
                  className="w-16 h-16 object-cover rounded"
                  src={ApiService.getMedia(sponser.image)}
                  alt={sponser.name}
                />
                <span className="font-medium">{sponser.name}</span>
              </div>
              <button
                className="text-red-500 hover:text-red-700 focus:outline-none"
                onClick={() => onDelete(sponser._id)}
              >
                Delete
              </button>
            </li>
          ))}
        </ul>

        {!loading && sponsers.length === 0 && <Alert type="warning">No Sponsers</Alert>}
      </Card>
    </div>
  )
}

export default ViewSponsers
