import Breadcrumb from "./components/Breadcrumb"
import { useLocation } from "react-router-dom"
import { useEffect, useState } from "react"
import ApiService from "../../../services/ApiService"
import Loader from "../../../components/Loader"
import DeleteFolders from "./DeleteFolders/index"
import useApiHandler from "hooks/useApiHandler"
import Folder from "./helpers/folder"
import EditButton from "./helpers/editButton"
import Images from "./helpers/images"
import ViewImage from "./helpers/viewImage"
import BackButton from "./helpers/backButton"
import UploadOptions from "./UploadOptions"

const Gallery = () => {
  const { request: requestFolders, loading: loadingFolders } = useApiHandler()

  const { pathname } = useLocation()
  const dynamicPart = decodeURIComponent(pathname)

  const [open_folders_edit, set_open_folders_edit] = useState(false)
  const [currentPath, setCurrentPath] = useState("/gallery")

  const [galleryInfo, setGaleryInfo] = useState(null)

  useEffect(() => {
    getFolders(dynamicPart)
    setCurrentPath(dynamicPart)
  }, [dynamicPart])

  const getFolders = async (path) => {
    requestFolders(
      {
        api: ApiService.getFolders,
        body: path,
        config: {
          displayError: false,
        },
      },
      (res, err) => {
        if (err) return

        setGaleryInfo(res.data.data)
      }
    )
  }

  // const onClickFolder = async (path) => {
  //     console.log(path)
  //     setCurrentPath(path)
  //     getFolders(path)
  // }

  const onClickFolder = async (path) => {
    window.location.href = path
    // setCurrentPath(path)
    // getFolders(path)
  }

  const onClickBack = () => {
    let splitItems = currentPath.split("/")
    splitItems.pop()
    window.location.href = splitItems.join("/")
  }

  const loadFoldersToPopup = () => {
    return galleryInfo?.folders
  }

  return (
    <section>
      <Loader open={loadingFolders} />
      <DeleteFolders
        open={open_folders_edit}
        setOpen={set_open_folders_edit}
        currentPath={currentPath}
        reload={getFolders}
        getFolders={loadFoldersToPopup}
      />

      <BackButton onClick={onClickBack} />
      <Breadcrumb currentFolder={galleryInfo} />

      <UploadOptions folderInfo={galleryInfo} getFolders={getFolders} />

      <div className="container mx-auto px-4 py-8">
        <div className="text-xl flex">
          <span>Folders</span>
          <EditButton onClick={() => set_open_folders_edit(true)} />
        </div>
        <hr className="my-4 border-gray-200" />

        <Folder folders={galleryInfo?.folders} onClick={onClickFolder} />
      </div>

      <div className="container mx-auto px-4 py-8">
        <div className="text-xl flex">
          <span>Images</span>
        </div>
        <hr className="my-4 border-gray-200" />

        <Images images={galleryInfo?.images} />
      </div>

      <ViewImage currentPath={currentPath} getFolders={getFolders} />
    </section>
  )
}

export default Gallery
