import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { useNavigate, useParams } from "react-router-dom"
import Img from "assets/volunteer/group-hands.jpg"
import Button from "components/Button"
import Loader from "components/Loader"
import ApiService from "services/ApiService"
import { useFormik } from "formik"
import * as Yup from "yup"
import TextArea from "components/Textarea"
import TextField from "components/TextField"
import UpdateProject from "./UpdateProject"
import useApiHandler from "hooks/useApiHandler"
import useToast from "hooks/useToast"
import commonFunctions from "utils/CommonFunctions"

const ViewProject = () => {
  const navigate = useNavigate()

  const { renderSuccessToast } = useToast()

  const { request, loading, data } = useApiHandler()

  const { request: requestAddContact, loading: addContactLoading } = useApiHandler()

  const auth = useSelector((state) => state.auth)
  const { projectId } = useParams()

  const [percentage, setPercentage] = useState(0)
  const [percentText, setPercentText] = useState("0%")
  const [updateProject, setUpdateProject] = useState(false)

  useEffect(() => {
    getProject()
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (data?.project) {
      let goalAmount = data?.project.fund ? data?.project.fund : 0
      let fundRaised = data?.project.fundRaised ? data?.project.fundRaised : 0
      let percent = ((fundRaised / goalAmount) * 100).toFixed(2)
      setPercentText(percent + "%")
      setPercentage(percent)
    }
  }, [data?.project])

  const getProject = async () => {
    request({
      api: ApiService.getProjectById,
      body: projectId,
      config: {
        displayError: true,
      },
    })
  }

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: "",
      email: "",
      phone: "",
      message: "",
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required("Required"),
      email: Yup.string().required("Required"),
      phone: Yup.string().required("Required"),
      message: Yup.string().required("Required"),
    }),
    onSubmit: (values) => {
      addContact(values)
    },
  })

  const addContact = async (values) => {
    try {
      const { name, email, phone, message } = values
      const body = {
        name: name,
        email: email,
        phone: commonFunctions.phoneNumberToText(phone),
        message: message,
      }

      requestAddContact(
        {
          api: ApiService.addContact,
          body: body,
          config: {
            displayError: true,
          },
        },
        (_, err) => {
          if (err) return
          renderSuccessToast("Message Sent")
          formik.resetForm()
        }
      )
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <section>
      <Loader open={loading || addContactLoading} />

      <UpdateProject
        open={updateProject}
        setOpen={setUpdateProject}
        project={data?.project}
        projectUpdateCallback={getProject}
      />

      {!loading && (
        <>
          {!data?.project.backgroundImg && (
            <div
              className="py-20 justify-center bg-no-repeat bg-cover bg-center h-[28rem]"
              style={{ backgroundImage: `url(${Img})` }}
            >
              <div className="container my-auto px-6 pt-10">
                <h2 className="text-4xl font-bold mb-2 text-white">{data?.project.projectName}</h2>
              </div>
            </div>
          )}

          {data?.project.backgroundImg && (
            <div
              className="py-20 justify-center bg-no-repeat bg-cover bg-center h-[28rem]"
              style={{
                backgroundImage: `url(${ApiService.getMedia(data?.project.backgroundImg?.filename)})`,
              }}
            >
              <div className="container my-auto px-6 pt-10">
                <h2 className="text-4xl font-bold mb-2 text-white">{data?.project.projectName}</h2>
              </div>
            </div>
          )}

          <div className="container sm:mx-auto px-[1rem] mt-10">
            <div className="lg:flex">
              <div className="w-full">
                <div className="text-2xl uppercase text-center">Project Description</div>
                <div className="mt-3">{data?.project.description}</div>
              </div>
              <div className="w-full mt-10 lg:pl-10 lg:mt-0">
                <div className="flex justify-between items-center mb-2">
                  <h3 className="text-xl font-bold">Goal Amount: $ {data?.project.fund}</h3>
                  <h3 className="text-xl font-bold">Fund Raised: $ {data?.project.fundRaised}</h3>
                </div>

                <div className="relative w-full h-4 bg-gray-300 rounded-sm overflow-hidden">
                  <div
                    className="absolute left-0 top-0 h-full bg-green-500 rounded-sm"
                    style={{ width: percentage + "%" }}
                  ></div>
                  <div
                    className="absolute inset-y-0 left-1/2 transform -translate-x-1/2 flex items-center justify-center text-white font-bold"
                    style={{ width: percentage + "%" }}
                  >
                    {percentText}
                  </div>
                </div>

                {data?.project.completed && (
                  <p className="px-4 py-1 mt-3 inline-flex text-sm leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                    Status: Completed
                  </p>
                )}

                {!data?.project.completed && (
                  <Button
                    onClick={() => (window.location.href = `/donate?dt=${3}&df=${data?.project._id}`)}
                    bg="#10a37f"
                    textColor="#fff"
                    className="mt-4"
                  >
                    Donate
                  </Button>
                )}

                {auth.isLoggedIn && auth.isAdmin && (
                  <Button
                    bg="#10a37f"
                    textColor="#fff"
                    className="mt-4 ml-2"
                    onClick={() => setUpdateProject(true)}
                  >
                    Edit project
                  </Button>
                )}
              </div>
            </div>
          </div>

          <div className="container sm:mx-auto px-[1rem] mt-10">
            <div className="lg:flex-col">
              <h3 className="text-2xl text-center">About</h3>
              <h1 className="text-4xl mb-4 text-center">THE CAUSE</h1>
              <div className="w-full mt-5 lg:mt-0">{data?.project.aboutTheCause}</div>
            </div>
          </div>

          <div className="container sm:mx-auto px-[1rem] mt-10">
            <div className="lg:flex-col">
              <h3 className="text-2xl text-center">PLAN OF</h3>
              <h1 className="text-4xl mb-4 text-center">ACTION</h1>
              <div className="w-full mt-5 lg:mt-0">{data?.project.whatWeDo}</div>
            </div>
          </div>

          {/* ============================================= */}

          <section>
            <div className="mx-auto grid max-w-screen-xl grid-cols-1">
              <div className="py-12 md:py-24">
                <div className="mx-auto bg-white lg:rounded-lg shadow border max-w-xl p-12">
                  <div className="text-center">
                    <h3 className="text-2xl">CONTACT US</h3>
                    <h1 className="text-4xl mb-4">Drop Us A Line</h1>
                  </div>
                  <form>
                    <div className="mb-6">
                      <TextField
                        label="Name"
                        name="name"
                        type="text"
                        value={formik.values.name}
                        onChange={formik.handleChange}
                        error={formik.errors.name}
                        touched={formik.touched.name}
                        onBlur={formik.handleBlur}
                        required
                      />
                    </div>
                    <div className="mb-6">
                      <TextField
                        label="Email"
                        name="email"
                        type="email"
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        error={formik.errors.email}
                        touched={formik.touched.email}
                        onBlur={formik.handleBlur}
                        required
                      />
                    </div>
                    <div className="mb-6">
                      <TextField
                        label="Phone"
                        name="phone"
                        type="text"
                        placeholder="(XXX) XXX-XXXX"
                        phoneNumberFormat={true}
                        value={formik.values.phone}
                        onChange={formik.handleChange}
                        error={formik.errors.phone}
                        touched={formik.touched.phone}
                        onBlur={formik.handleBlur}
                        required
                      />
                    </div>
                    <div className="mb-6">
                      <TextArea
                        label="Your message"
                        name="message"
                        type="text"
                        value={formik.values.message}
                        onChange={formik.handleChange}
                        error={formik.errors.message}
                        touched={formik.touched.message}
                        onBlur={formik.handleBlur}
                        required
                      />
                    </div>

                    <div>
                      <Button
                        onClick={() => formik.handleSubmit()}
                        bg="#10a37f"
                        textColor="#fff"
                        className="mt-1"
                      >
                        GET IN TOUCH
                      </Button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </section>

          <div className="container sm:mx-auto px-[1rem] mt-10">
            <div className="lg:flex-col">
              <div className="font-bold">
                Thank you to all our donors, we have met our fundraising goal.
              </div>
              <div className="font-light">
                On behalf of Dhvani, we want to thank you for helping us make a positive difference. Your
                support encourages our continued commitment to reaching our goal.
              </div>
            </div>
          </div>

          <div className="flex flex-wrap justify-center items-center mt-[5rem]">
            <div className="w-full lg:w-1/3 px-[50px] py-[140px] flex flex-col justify-center items-center bg-[#5ab9a5] text-white">
              <div className="text-sm">MAKE A</div>
              <div className="text-lg mb-6">DONATION</div>
              <p className="text-center mb-6">
                When you donate, you’re giving children renewed strength to fight poverty and can change
                lives. Your donation is tax deductible and we send you a tax receipt.
              </p>

              <button
                type="button"
                onClick={() => navigate("/donate")}
                className={`bg-[#EF884C] hover:opacity-80 text-[#fff] border-none rounded-2xl box-border cursor-pointer inline-flex font-normal justify-center leading-5 items-center position-relative transition duration-300 ease-in-out select-none focus:outline-none focus:shadow-outline-blue text-sm px-3 py-2`}
              >
                DONATE NOW
              </button>
            </div>
            <div className="w-full lg:w-1/3 px-[50px] py-[140px] flex flex-col justify-center items-center bg-[#000] text-white">
              <div className="text-sm">BECOME A</div>
              <div className="text-lg mb-6">MEMBER</div>
              <p className="text-center mb-6">
                There is nothing more fulfilling than realizing how much of a sustainable impact you as a
                member can make. Just a few dollars & hours of help can turn into a lifetime of
                opportunity!
              </p>
              <button
                type="button"
                onClick={() => navigate("/signup")}
                className={`bg-[#EFC94C] hover:opacity-80 text-[#fff] border-none rounded-2xl box-border cursor-pointer inline-flex font-normal justify-center leading-5 items-center position-relative transition duration-300 ease-in-out select-none focus:outline-none focus:shadow-outline-blue text-sm px-3 py-2`}
              >
                JOIN US
              </button>
            </div>
            <div className="w-full lg:w-1/3 px-[50px] py-[140px] flex flex-col justify-center items-center bg-[#d55e48] text-white">
              <div className="text-sm">SPREAD THE</div>
              <div className="text-lg mb-6">WORD</div>
              <p className="text-center mb-6">
                We’d appreciate your help spreading the word about events at Dhvani Foundation and create
                an impact by creating a conversation around all the causes and campaigns we support.
              </p>

              <button
                type="button"
                onClick={() => navigate("/donate")}
                className={`bg-[#EF884C] hover:opacity-80 text-[#fff] border-none rounded-2xl box-border cursor-pointer inline-flex font-normal justify-center leading-5 items-center position-relative transition duration-300 ease-in-out select-none focus:outline-none focus:shadow-outline-blue text-sm px-3 py-2`}
              >
                DONATE NOW
              </button>
            </div>
          </div>
        </>
      )}
    </section>
  )
}

export default ViewProject
