const logTypes = [
  {
    name: "None",
    value: null,
  },
  {
    name: "Authentication",
    value: "Authentication",
  },
  {
    name: "Payments",
    value: "Payments",
  },
  {
    name: "Cause",
    value: "Cause",
  },
  {
    name: "Event",
    value: "Event",
  },
  {
    name: "Project",
    value: "Project",
  },
]

const logStatus = [
  {
    name: "None",
    value: null,
  },
  {
    name: "Success",
    value: true,
  },
  {
    name: "Failed",
    value: false,
  },
]

const requestMethods = [
  {
    name: "None",
    value: null,
  },
  {
    name: "POST",
    value: "POST",
  },
  {
    name: "GET",
    value: "GET",
  },
  {
    name: "PUT",
    value: "PUT",
  },
  {
    name: "DELETE",
    value: "DELETE",
  },
]

export { logTypes, logStatus, requestMethods }
