import { useEffect, useState } from "react"
import ApiService from "services/ApiService"
import Loader from "components/Loader"
import CauseCard from "../../Causes/ListCard"
import { useSearchParams } from "react-router-dom"
import PreviousCauses from "./PreviousCauses"
import { HandleDate } from "utils/Helpers"
import useApiHandler from "hooks/useApiHandler"

const MySubmittedCauses = () => {
  const { request, loading, data } = useApiHandler()
  const [queryParams, _] = useSearchParams()

  const [currentTabItems, setCurrTabItems] = useState([])
  const [currentTab, setCurrTab] = useState(0)
  const [openPreviousCausesWindow, setOpenPreviuousCausesWindow] = useState(false)

  useEffect(() => {
    getMyCauses()
  }, [])

  useEffect(() => {
    if (data) {
      let ct = queryParams.get("ct")
      if (ct == 1) onClickTab(ct)
    }
  }, [data])

  const getMyCauses = async () => {
    let windowId = queryParams.get("windowId")

    request(
      {
        api: ApiService.getMyCauses,
        body: windowId,
        config: {
          displayError: true,
        },
      },
      (res, err) => {
        if (err) return

        let ct = queryParams.get("ct")
        if (ct == 1) {
          setCurrTab(1)
        } else {
          setCurrTabItems(res.data.currentCauses)
        }
      }
    )
  }

  const onClickTab = (tab) => {
    if (tab == 0) {
      setCurrTabItems(data?.currentCauses)
      setCurrTab(0)
    }
    if (tab == 1) {
      setCurrTabItems(data?.previousCauses)
      setCurrTab(1)
    }
  }

  return (
    <section className="p-5">
      <Loader open={loading} />

      <PreviousCauses
        open={openPreviousCausesWindow}
        setOpen={setOpenPreviuousCausesWindow}
        getCauses={getMyCauses}
      />

      <div className="mx-auto max-w-7xl py-6 px-4 sm:px-6 lg:max-w-[100rem] lg:px-8">
        <span className="text-xs sm:hidden">(Scroll right to find more tabs)</span>
        <div className="xl:w-full xl:mx-0 h-12 sm:block bg-white shadow rounded">
          <div className="flex px-5 flex-nowrap overflow-x-scroll">
            <button
              onClick={() => onClickTab(0)}
              className={`focus:outline-none focus:text-[#10a37f] text-sm border-[#10a37f] pt-3 rounded-t mr-12 cursor-pointer hover:text-[#10a37f] ${
                currentTab === 0 ? `text-[#10a37f]` : `text-gray-600`
              }`}
            >
              <div className="flex items-center mb-3">
                <span className="ml-1 font-normal whitespace-nowrap">
                  Current Causes ({data?.currentCauses?.length}){" "}
                </span>
              </div>
              <div
                className={`w-full h-1 bg-[#10a37f] rounded-t-md ${currentTab !== 0 && "hidden"}`}
              ></div>
            </button>
            <button
              onClick={() => onClickTab(1)}
              className={`focus:outline-none focus:text-[#10a37f] text-sm border-[#10a37f] pt-3 rounded-t mr-12 cursor-pointer hover:text-[#10a37f] ${
                currentTab === 1 ? `text-[#10a37f]` : `text-gray-600`
              }`}
            >
              <div className="flex items-center mb-3">
                <span className="ml-1 font-normal whitespace-nowrap">
                  Previous Causes ({data?.previousCauses?.length}){" "}
                </span>
              </div>
              <div
                className={`w-full h-1 bg-[#10a37f] rounded-t-md ${currentTab !== 1 && "hidden"}`}
              ></div>
            </button>
          </div>
        </div>

        {currentTab === 1 && (
          <div className="my-5 text-xs">
            Showing Cause Window
            {data?.previousCauseWindow && (
              <div className="bg-yellow-200 px-2 ml-1 py-1 rounded text-yellow-800 inline text-xs">
                {HandleDate(data?.previousCauseWindow?.causeStartDate)} ------{" "}
                {HandleDate(data?.previousCauseWindow?.causeEndDate)}
              </div>
            )}
            <button
              type="button"
              className="flex w-full justify-center rounded-md border border-transparent bg-[#10a37f] hover:opacity-80 px-4 py-1 mt-3 text-base font-medium text-white shadow-sm focus:outline-none sm:w-auto sm:text-sm"
              onClick={() => setOpenPreviuousCausesWindow(true)}
            >
              View previous causes
            </button>
          </div>
        )}

        {currentTabItems.length === 0 && (
          <div
            className="p-4 my-4 text-sm text-yellow-800 rounded-lg shadow-md bg-yellow-50 w-full"
            role="alert"
          >
            <span className="font-medium">No causes found.</span>
          </div>
        )}

        <div className="mt-6 grid grid-cols-1 gap-y-10 gap-x-6 sm:grid-cols-2 lg:grid-cols-4 xl:gap-x-8">
          {currentTabItems.map((cause, index) => (
            <CauseCard cause={cause} key={index} />
          ))}
        </div>
      </div>
    </section>
  )
}

export default MySubmittedCauses
