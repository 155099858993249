import Img from "../../../assets/about_image_comp.jpg"
import ApiService from "../../../services/ApiService"
import { useNavigate } from "react-router-dom"
import { useSelector } from "react-redux"
import { useState } from "react"
import EditEvent from "../EventRegistration/EditEvent"
import Loader from "../../../components/Loader"
import ConfirmationWindow from "../../../components/ConfirmationWindow"
import useApiHandler from "hooks/useApiHandler"
import Alert from "components/Alert"
import Button from "components/Button"

const EventCard = ({ event, refresh = () => {} }) => {
  const navigate = useNavigate()
  const auth = useSelector((state) => state.auth)

  const { ...onHoldApi } = useApiHandler()
  const { ...onSuspendApi } = useApiHandler()

  const [openEditEvent, setOpenEditEvent] = useState(false)
  const [openConfirmWindow, setOpenConfirmWindow] = useState(false)

  const handleOnHold = async () => {
    let formData = {
      eventId: event._id,
    }

    onHoldApi.request(
      {
        api: ApiService.setOnHold,
        body: formData,
      },
      (_, err) => {
        if (err) return

        refresh()
      }
    )
  }

  const handleOnSuspend = async () => {
    let formData = {
      eventId: event._id,
    }

    onSuspendApi.request(
      {
        api: ApiService.setOnSuspend,
        body: formData,
      },
      (_, err) => {
        if (err) return

        refresh()
      }
    )
  }

  return (
    <div className="bg-white overflow-hidden border border-gray-200 rounded-lg shadow">
      <Loader open={onHoldApi.loading || onSuspendApi.loading} />

      <EditEvent
        open={openEditEvent}
        setOpen={setOpenEditEvent}
        event={event}
        restart={true}
        refreshEvent={refresh}
      />
      <ConfirmationWindow
        open={openConfirmWindow}
        close={() => setOpenConfirmWindow(false)}
        okCallback={handleOnSuspend}
        message="Are you sure to suspend this event? You cannot undo."
      />

      {event.flyer && <RenderFlyer flyer={event.flyer} type="network" />}
      {!event.flyer && <RenderFlyer flyer={Img} type="local" />}

      <div className="px-4 py-2 sm:p-4">
        <span>
          <h5 className="mb-2 text-2xl font-medium text-gray-600 line-clamp-2">{event.eventName}</h5>
        </span>
        <p className="font-medium text-gray-500 mb-3 line-clamp-2">{event.eventDescription}</p>

        {event.isDisabled && (
          <Alert type="danger" className="mb-4">
            This event has been suspended.
          </Alert>
        )}

        {!event.completed && !event.isDisabled && (
          <>
            {!event.isHold && auth.isLoggedIn && auth.isAdmin && (
              <Button className="bg-[#F7BE38]" onClick={handleOnHold}>
                Hold
              </Button>
            )}
            {event.isHold && auth.isLoggedIn && auth.isAdmin && (
              <Button className="bg-[#F7BE38]" onClick={() => setOpenEditEvent(true)}>
                Restart Event
              </Button>
            )}
            {auth.isLoggedIn && auth.isAdmin && (
              <Button className="bg-[#f76538] ml-3" onClick={() => setOpenConfirmWindow(true)}>
                Suspend Event
              </Button>
            )}
            {/* {auth.isLoggedIn && auth.isAdmin && (
                            <Button
                                className="bg-[#f76538] mt-3"
                                onClick={() => setOpenConfirmWindow(true)}
                            >
                                Close Registration
                            </Button>
                        )} */}
            {!event.isHold && !event.isDisabled && (
              <div className="flex justify-between">
                {/* {!event.isUpcoming && ( */}
                {true && (
                  <Button className="mt-3" onClick={() => navigate(`/donate?dt=2&df=${event._id}`)}>
                    Donate
                  </Button>
                )}
                <span></span>

                <button
                  onClick={() => navigate(`/event-registration/${event._id}`)}
                  className={`mt-3 ml-4 border-none rounded-md box-border cursor-pointer inline-flex font-normal justify-center leading-5 items-center position-relative transition duration-300 ease-in-out select-none focus:outline-none focus:shadow-outline-blue text-sm px-3 py-2`}
                >
                  Register
                  <RightArraow />
                </button>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  )
}

const RenderFlyer = ({ flyer, type }) => {
  return (
    <img
      src={type === "network" ? ApiService.getMedia(flyer) : flyer}
      alt="Card"
      className="w-full object-cover object-center h-48 sm:h-64"
    />
  )
}

const RightArraow = () => {
  return (
    <svg
      aria-hidden="true"
      className="w-4 h-4 ml-2 -mr-1"
      fill="currentColor"
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
        clipRule="evenodd"
      ></path>
    </svg>
  )
}

export default EventCard
