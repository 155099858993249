import SelectDate from "components/SelectDate"
import LogsTable from "./LogsTable"
import useApiHandler from "hooks/useApiHandler"
import { useEffect, useState } from "react"
import ApiService from "services/ApiService"
import Button from "components/Button"
import Filters from "./Filters/Filters"

const Logs = () => {
  const { request, loading, error, message } = useApiHandler()

  const [logs, setLogs] = useState([])
  const [currentPage, setCurrentPage] = useState(1)
  const [totalItems, setTotalItems] = useState(1)
  const [totalPages, setTotalPages] = useState(1)
  const itemsPerPage = 100
  const [openFilters, setOpenFilters] = useState(false)

  useEffect(() => {
    getLogs()
  }, [currentPage])

  const objectToQueryString = (obj) => {
    return Object.keys(obj)
      .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(obj[key])}`)
      .join("&")
  }

  const getLogs = async (filters) => {
    let queryString = `limit=${itemsPerPage}&page=${currentPage}`
    if (filters) {
      queryString = queryString + "&" + objectToQueryString(filters)
    }

    request(
      {
        api: ApiService.getLogs,
        body: queryString,
      },
      (res, err) => {
        if (err) return

        setLogs(res?.data?.logs)
        setTotalItems(res?.data?.totalItems)
        setTotalPages(res?.data?.totalPages)
      }
    )
  }

  return (
    <div className="p-5">
      <Filters open={openFilters} setOpen={setOpenFilters} getLogs={getLogs} />

      <LogsTable
        logs={logs}
        loading={loading}
        error={error}
        errorMessage={message}
        pagination={{
          currentPage,
          itemsPerPage,
          totalItems,
          totalPages: totalPages,
        }}
        onNext={(nextPage) => setCurrentPage(nextPage)}
        onPrevious={(previousPage) => setCurrentPage(previousPage)}
      />
    </div>
  )
}

export default Logs
