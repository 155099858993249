import { useEffect, useState, useRef } from "react"
import { useParams } from "react-router-dom"
import Img from "assets/volunteer/group-hands.jpg"
import Button from "components/Button"
import Loader from "components/Loader"
import ApiService from "services/ApiService"
import UpdateCause from "./UpdateCause"
import Constants from "utils/Constants"
import useApiHandler from "hooks/useApiHandler"
import FileSelector from "components/FileSelector"

const ViewCause = () => {
  const { causeId } = useParams()

  const { request: requestCause, loading: causeLoading } = useApiHandler()

  const { request: requestUploadDocuments, loading: documentsLoading } = useApiHandler()

  const [cause, setCause] = useState(null)

  const [percentText, setPercentText] = useState("0%")
  const [openUpdateCause, setOpenUpdateCause] = useState(false)
  const [documents, setDocuments] = useState([])

  const [currentTab, setCurrTab] = useState(0)

  const documentsRef = useRef()

  useEffect(() => {
    getCause()
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (cause) {
      let goalAmount = cause?.causeFund ? cause?.causeFund : 0
      let fundRaised = cause?.fundRaised ? cause?.fundRaised : 0
      let percent = ((fundRaised / goalAmount) * 100).toFixed(0)
      setPercentText(percent + "%")
    }
  }, [cause])

  const getCause = async () => {
    requestCause(
      {
        api: ApiService.getCauseById,
        body: causeId,
      },
      (res, err) => {
        if (err) return

        setCause(res.data)
      }
    )
  }

  const onClickTab = (tab) => {
    setCurrTab(tab)
  }

  const onSelectDocuments = (e) => {
    setDocuments([...e.target.files])
  }

  const uploadDocuments = async () => {
    try {
      const formData = new FormData()
      formData.append("causeId", cause?._id)

      for (let i = 0; i < documents.length; i++) {
        formData.append("files", documents[i])
      }

      requestUploadDocuments(
        {
          api: ApiService.addCauseDocuments,
          body: formData,
        },
        (_, err) => {
          if (err) return

          setDocuments([])
          documentsRef.current.clearFiles()
          getCause()
        }
      )
    } catch (error) {
      console.error(error)
    }
  }

  const downloadMedia = async (filename) => {
    window.open(ApiService.downloadMedia(filename))
  }

  return (
    <section>
      <Loader open={causeLoading || documentsLoading} />

      <UpdateCause
        open={openUpdateCause}
        setOpen={setOpenUpdateCause}
        cause={cause}
        causeUpdateCallback={getCause}
      />

      {!causeLoading && (
        <>
          {!cause?.backgroundImg && (
            <div
              className="py-20 justify-center bg-no-repeat bg-cover bg-center h-[28rem]"
              style={{ backgroundImage: `url(${Img})` }}
            >
              <div className="container my-auto px-6 pt-10">
                <h2 className="text-4xl font-bold mb-2 text-white">{cause?.causeName}</h2>
              </div>
            </div>
          )}

          {cause?.backgroundImg && (
            <div
              className="py-20 justify-center bg-no-repeat bg-cover bg-center h-[28rem]"
              style={{
                backgroundImage: `url(${Constants.HOST_API + "media/getImage/" + cause?.backgroundImg})`,
              }}
            >
              <div className="container my-auto px-6 pt-10">
                <h2 className="text-4xl font-bold mb-2 text-white">{cause?.causeName}</h2>
              </div>
            </div>
          )}

          <div className="bg-gray-700 box-border w-full">
            <div className="max-w-7xl mx-auto md:flex items-center">
              <div className="p-4 md:w-1/2 md:inline-block">
                <div className="md:py-4 md:w-1/2 md:inline-block">
                  <span className="block text-center bg-red-600 text-white py-3 px-4 no-underline">
                    GOAL CAUSE{" "}
                    <strong className="border-b-2 font-bold text-center text-white">
                      ${cause?.causeFund}
                    </strong>
                  </span>
                </div>
                <div className="md:py-4 md:w-1/2 md:inline-block">
                  <span className="block text-center bg-gray-500 text-white py-3 px-4 no-underline">
                    ACHIEVED{" "}
                    <strong className="border-b-2 font-bold text-center text-white">
                      ${cause?.fundRaised}
                    </strong>
                  </span>
                </div>
              </div>
              <div className="p-4 md:w-1/2 md:flex items-center">
                <div className="text-center w-1/2 md:w-1/3 inline-block">
                  <h1 className="text-white font-semibold text-3xl leading-tight mb-0">
                    <strong>{percentText} </strong>
                  </h1>
                  <h5 className="text-gray-400 tracking-widest text-sm leading-tight uppercase mb-0">
                    OF OUR GOAL
                  </h5>
                </div>
                <div className="text-center w-1/3 inline-block">
                  <h1 className="text-white font-semibold text-3xl leading-tight mb-0">
                    <strong> {cause?.donations.length} </strong>
                  </h1>
                  <h5 className="text-gray-400 tracking-widest text-sm leading-tight uppercase mb-0">
                    DONATIONS
                  </h5>
                </div>
                {cause?.causeLevel === "FUNDING" && (
                  <div className="text-center md:w-1/3 md:inline-block mt-5 md:mt-0">
                    <button
                      onClick={() => (window.location.href = `/donate?dt=1&df=${cause._id}`)}
                      className="bg-teal-500 box-border inline-block rounded-full px-6 py-2 no-underline text-white font-semibold cursor-pointer"
                    >
                      DONATE NOW
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>

          {cause?.causeLevel !== "DECLINED_FOR_VOTING" && (
            <>
              {!cause?.completed && (
                <div className="text-center w-full inline-block my-5">
                  <button
                    onClick={() => setOpenUpdateCause(true)}
                    className="bg-teal-500 box-border inline-block rounded-full px-6 py-2 no-underline text-white font-semibold cursor-pointer"
                  >
                    EDIT DETAILS
                  </button>
                </div>
              )}
            </>
          )}

          <div className="mx-auto max-w-7xl py-6 px-4 sm:px-6 lg:max-w-[100rem] lg:px-8">
            <span className="text-xs sm:hidden">(Scroll right to find more tabs)</span>
            <div className="xl:w-full xl:mx-0 h-12 sm:block bg-white shadow rounded">
              <div className="flex border-b px-5 flex-nowrap overflow-x-auto">
                <button
                  onClick={() => onClickTab(0)}
                  className={`focus:outline-none focus:text-[#10a37f] text-sm border-[#10a37f] pt-3 rounded-t mr-12 cursor-pointer hover:text-[#10a37f] ${
                    currentTab === 0 ? `text-[#10a37f]` : `text-gray-600`
                  }`}
                >
                  <div className="flex items-center mb-3">
                    <span className="ml-1 font-normal whitespace-nowrap">About The Cause</span>
                  </div>
                  <div
                    className={`w-full h-1 bg-[#10a37f] rounded-t-md ${currentTab !== 0 && "hidden"}`}
                  ></div>
                </button>
                <button
                  onClick={() => onClickTab(1)}
                  className={`focus:outline-none focus:text-[#10a37f] text-sm border-[#10a37f] pt-3 rounded-t mr-12 cursor-pointer hover:text-[#10a37f] ${
                    currentTab === 1 ? `text-[#10a37f]` : `text-gray-600`
                  }`}
                >
                  <div className="flex items-center mb-3">
                    <span className="ml-1 font-normal whitespace-nowrap">Description</span>
                  </div>
                  <div
                    className={`w-full h-1 bg-[#10a37f] rounded-t-md ${currentTab !== 1 && "hidden"}`}
                  ></div>
                </button>

                <button
                  onClick={() => onClickTab(2)}
                  className={`focus:outline-none focus:text-[#10a37f] text-sm border-[#10a37f] pt-3 rounded-t mr-12 cursor-pointer hover:text-[#10a37f] ${
                    currentTab === 2 ? `text-[#10a37f]` : `text-gray-600`
                  }`}
                >
                  <div className="flex items-center mb-3">
                    <span className="ml-1 font-normal whitespace-nowrap">Documents</span>
                  </div>
                  <div
                    className={`w-full h-1 bg-[#10a37f] rounded-t-md ${currentTab !== 2 && "hidden"}`}
                  ></div>
                </button>

                <button
                  onClick={() => onClickTab(3)}
                  className={`focus:outline-none focus:text-[#10a37f] text-sm border-[#10a37f] pt-3 rounded-t mr-12 cursor-pointer hover:text-[#10a37f] ${
                    currentTab === 3 ? `text-[#10a37f]` : `text-gray-600`
                  }`}
                >
                  <div className="flex items-center mb-3">
                    <span className="ml-1 font-normal whitespace-nowrap">Donations</span>
                  </div>
                  <div
                    className={`w-full h-1 bg-[#10a37f] rounded-t-md ${currentTab !== 3 && "hidden"}`}
                  ></div>
                </button>
              </div>
            </div>

            {currentTab === 0 && <div className="my-5 ml-2">{cause?.aboutTheCause}</div>}

            {currentTab === 1 && <div className="my-5 ml-2">{cause?.causeDescription}</div>}

            {currentTab === 2 && (
              <div className="my-5 ml-2">
                <FileSelector
                  label="Documents"
                  helpText="(Multiple files accepted)"
                  description="Choose files"
                  onSelected={onSelectDocuments}
                  onClear={() => setDocuments([])}
                  multiple
                  ref={documentsRef}
                  maxSize={50}
                />
                {cause?.causeLevel !== "DECLINED_FOR_VOTING" && (
                  <div className="text-end mt-2">
                    <Button
                      disabled={documents.length === 0 || cause?.completed}
                      onClick={() => {
                        if (documentsRef.current.localError) return
                        uploadDocuments()
                      }}
                    >
                      Upload documents
                    </Button>
                  </div>
                )}

                <div className="mt-5">
                  List of documents
                  {cause?.documents.length > 0 && (
                    <ul className="md:w-[50%] text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-lg mt-2">
                      {cause?.documents.map((item, index) => (
                        <li
                          className="w-full px-4 py-2 border-b border-gray-200 rounded-t-lg flex justify-between"
                          key={index}
                        >
                          <div>
                            <span>{item.originalname}</span>
                          </div>
                          <div
                            className="cursor-pointer text-blue-500"
                            onClick={() => downloadMedia(item.filename)}
                          >
                            Download
                          </div>
                        </li>
                      ))}
                    </ul>
                  )}
                  {cause?.documents.length === 0 && (
                    <ul className="md:w-[50%] text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-lg mt-2">
                      <li className="w-full px-4 py-2 border-b border-gray-200 rounded-t-lg flex justify-between">
                        <div>No Documents</div>
                      </li>
                    </ul>
                  )}
                </div>
              </div>
            )}

            {currentTab === 3 && (
              <div>
                <div className="my-5 ml-2">Donations</div>
                <div class="overflow-x-auto w-[100%]">
                  <table class="min-w-full divide-y-2 divide-gray-200 bg-white rounded-md text-sm">
                    <thead class="text-left">
                      <tr>
                        <th class="whitespace-nowrap px-4 py-2 font-medium text-gray-900">Name</th>
                        <th class="whitespace-nowrap px-4 py-2 font-medium text-gray-900">Amount</th>
                      </tr>
                    </thead>

                    <tbody class="divide-y divide-gray-200">
                      {cause?.donations.map((donatin, index) => (
                        <tr key={index}>
                          <td className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">
                            {donatin["firstName"]} {donatin["lastName"]}
                          </td>
                          <td className="whitespace-nowrap px-4 py-2 text-gray-700">
                            $ {donatin["payment"]["amount"]}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            )}
          </div>
        </>
      )}
    </section>
  )
}

export default ViewCause
