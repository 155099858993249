import Flatpickr from "react-flatpickr"

const SelectDate = ({
  label,
  name,
  value,
  options,
  hasError = false,
  error = null,
  className = "",
  onChange = () => {},
  required,
  placeholder,
  disabled,
}) => {
  const onChangeDate = ([date]) => {
    onChange({ date })
  }

  const onChangeSelectRange = (selectedDates, dateStr, instance) => {
    onChange({ date: selectedDates })
  }

  const _options = {
    ...options,
    disableMobile: true,
  }

  return (
    <>
      <Flatpickr
        value={value}
        onChange={_options["mode"] === "range" ? onChangeSelectRange : onChangeDate}
        options={_options}
        render={({ defaultValue, value, ...props }, ref) => {
          return (
            <DateInputElement
              defaultValue={defaultValue}
              inputRef={ref}
              name={name}
              label={label}
              error={error}
              hasError={hasError}
              className={className}
              required={required}
              placeholder={placeholder}
              disabled={disabled}
            />
          )
        }}
      />
    </>
  )
}

const DateInputElement = ({
  value,
  className,
  error,
  defaultValue,
  inputRef,
  label,
  onBlur,
  name,
  hasError,
  required,
  placeholder,
  disabled,
  ...props
}) => {
  return (
    <>
      <label htmlFor={name} className="block text-sm font-medium leading-6 text-gray-900">
        {label} {required && <span className="text-md text-red-500">*</span>}
      </label>
      <input
        {...props}
        defaultValue={defaultValue}
        ref={inputRef}
        type="date"
        id={name}
        name={name}
        autoComplete="given-name"
        className={`mt-1 block w-full rounded-md shadow-sm sm:text-sm ${className} disabled:opacity-[.5] disabled:cursor-not-allowed ${
          hasError && error
            ? `border-red-500 border-2 focus:border-red-500 focus:ring-0`
            : `border-gray-300 focus:border-[#10a37f] focus:ring-[#10a37f]`
        } `}
        onBlur={onBlur}
        placeholder={placeholder}
        disabled={disabled}
      />
      {hasError && error && <span className="text-red-600 text-xs">{error}</span>}
    </>
  )
}

export default SelectDate
