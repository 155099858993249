import { useSelector } from "react-redux"
import Button from "components/Button"

import ApiService from "services/ApiService"
import useApiHandler from "hooks/useApiHandler"
import useToast from "hooks/useToast"

const ViewImage = ({ currentPath, getFolders }) => {
  const authState = useSelector((state) => state.auth)

  const { request, loading } = useApiHandler()

  const { renderSuccessToast } = useToast()

  const onClickClose = () => {
    const popup = document.getElementById("popup")
    popup.style.transform = `translateY(-100%)`
  }

  const downloadImg = () => {
    const selectedImage = document.getElementById("selectedImage")
    if (selectedImage !== null) {
      if (selectedImage.getAttribute("filename") !== null) {
        window.open(ApiService.downloadMedia(selectedImage.getAttribute("filename")))
      }
    }
  }

  const deleteImg = async () => {
    try {
      const popup = document.getElementById("popup")
      const selectedImage = document.getElementById("selectedImage")
      if (selectedImage !== null && selectedImage.getAttribute("fileid") !== null) {
        request(
          {
            api: ApiService.deleteMedia,
            body: selectedImage.getAttribute("fileid"),
          },
          (_, err) => {
            if (err) return

            popup.style.transform = `translateY(-100%)`
            getFolders(currentPath)
            renderSuccessToast("Image deleted!")
          }
        )
      }
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <>
      <div id="popup">
        <span className="actions">
          <Button onClick={onClickClose}>Close</Button>
          <Button onClick={downloadImg}>Download</Button>
          {authState?.isLoggedIn && authState?.isAdmin && (
            <Button onClick={deleteImg} loading={loading} loadingText="deleting...">
              Delete
            </Button>
          )}
        </span>
        <img id="selectedImage" />
      </div>
    </>
  )
}

export default ViewImage
