import moment from "moment"
import { useState } from "react"
import ApiService from "../../../services/ApiService"
import { useFormik } from "formik"
import Loader from "../../../components/Loader"
import TextField from "../../../components/TextField"
import TextArea from "../../../components/Textarea"
import * as Yup from "yup"
import Dialog from "./WarningDialog"
import SelectDate from "../../../components/SelectDate"
import Card from "../../../components/Card"
import Button from "../../../components/Button"
import useApiHandler from "hooks/useApiHandler"
import useToast from "hooks/useToast"

const AddProject = () => {
  const { request, loading } = useApiHandler()
  const { renderSuccessToast } = useToast()

  const [projectStartDate, setProjectStartDate] = useState(null)
  const [projectEndDate, setProjectEndDate] = useState(null)
  const [projectStartDateError, setProjectStartDateError] = useState(null)
  const [projectEndDateError, setProjectEndDateError] = useState(null)
  const [dialog, setDialog] = useState(false)
  const [dialogText, setDialogText] = useState("")
  const [projectBackground, setProjectBackground] = useState(null)

  const [minDates, setMinDates] = useState({
    projectStartDate: "today",
    projectEndDate: "today",
  })

  const [allFieldsValidated, setAllFieldsValidated] = useState(false)

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      projectName: "",
      projectDescription: "",
      goalAmount: "",
      fundsRaised: 0,
      aboutTheCause: "",
      whatWeDo: "",
    },
    validationSchema: Yup.object().shape({
      projectName: Yup.string().required("Required"),
      projectDescription: Yup.string().required("Required"),
      goalAmount: Yup.number()
        .typeError("Must be a number")
        .positive("Must be greater than 0")
        .required("Required"),
      fundsRaised: Yup.number().required("Required").typeError("Must be a number"),
      aboutTheCause: Yup.string().required("Required"),
    }),
    onSubmit: (values) => {
      if (!allFieldsValidated) return

      addProject(values)
    },
  })

  const validateOtherFields = () => {
    let hasIssues = false

    if (projectStartDate === null) {
      setProjectStartDateError("Required")
      hasIssues = true
    }
    if (projectEndDate === null) {
      setProjectEndDateError("Required")
      hasIssues = true
    }

    setAllFieldsValidated(!hasIssues)
  }

  const addProject = async (values) => {
    let { projectName, projectDescription, goalAmount, fundsRaised, aboutTheCause, whatWeDo } = values

    const formData = new FormData()
    formData.append("projectName", projectName)
    formData.append("projectDescription", projectDescription)
    formData.append("projectStartDate", projectStartDate)
    formData.append("projectEndDate", projectEndDate)
    formData.append("fund", goalAmount)
    formData.append("fundsRaised", fundsRaised)
    formData.append("aboutTheCause", aboutTheCause)
    formData.append("whatWeDo", whatWeDo)
    formData.append("projectBackground", projectBackground)

    request(
      {
        api: ApiService.addProject,
        body: formData,
        config: {
          displayError: true,
        },
      },
      (_, err) => {
        if (err) return

        formik.resetForm()
        setProjectBackground(null)
        setProjectStartDate(null)
        setProjectEndDate(null)

        renderSuccessToast("Project Created")
      }
    )
  }

  const onSelectProjectBackground = (e) => {
    setProjectBackground(e.target.files[0])
  }

  return (
    <section>
      <Loader open={loading} />
      <Dialog open={dialog} setOpen={setDialog} text={dialogText} />

      <header className="bg-white shadow">
        <div className="mx-auto py-3 px-4 sm:px-6 lg:px-8 flex justify-between">
          <h1 className="text-2xl font-bold tracking-tight text-gray-900">Add Project</h1>
        </div>
      </header>

      <Card className="p-5">
        <form className="space-y-4 m-4 md:space-y-6">
          <div className="grid grid-cols-1 gap-6 mt-4 sm:grid-cols-2">
            <div>
              <TextField
                label="Project Name"
                name="projectName"
                type="text"
                value={formik.values.projectName}
                onChange={formik.handleChange}
                error={formik.errors.projectName}
                touched={formik.touched.projectName}
                onBlur={formik.handleBlur}
                required
              />
            </div>

            <div>
              <TextField
                label="Project Description"
                name="projectDescription"
                type="text"
                value={formik.values.projectDescription}
                onChange={formik.handleChange}
                error={formik.errors.projectDescription}
                touched={formik.touched.projectDescription}
                onBlur={formik.handleBlur}
                required
              />
            </div>

            <div>
              <SelectDate
                label="Project Start Date"
                name="projectStartDate"
                value={projectStartDate}
                onChange={({ date }) => {
                  setProjectStartDate(new Date(date).toISOString())
                  setProjectStartDateError(null)
                  var duration = moment.duration({
                    days: 1,
                  })
                  setMinDates({
                    ...minDates,
                    projectEndDate: moment(date).add(duration).toDate(),
                  })
                }}
                options={{
                  minDate: minDates["projectStartDate"],
                }}
                hasError={projectStartDateError}
                error={projectStartDateError}
                required
              />
            </div>

            <div>
              <SelectDate
                label="Project End Date"
                name="projectEndDate"
                value={projectEndDate}
                onChange={({ date }) => {
                  setProjectEndDate(new Date(date).toISOString())
                  // setProjectEndDate(date);
                  setProjectEndDateError(null)
                }}
                options={{
                  minDate: minDates["projectEndDate"],
                  onOpen: [
                    function (selectedDates, dateStr, instance) {
                      console.log(projectStartDate)
                      if (projectStartDate === null) {
                        setDialog(true)
                        setDialogText("Please select Project start date")
                        instance.close()
                      }
                    },
                  ],
                }}
                hasError={projectEndDateError}
                error={projectEndDateError}
                required
              />
            </div>

            <div>
              <TextField
                label="Goal Amount"
                name="goalAmount"
                type="text"
                value={formik.values.goalAmount}
                onChange={formik.handleChange}
                error={formik.errors.goalAmount}
                touched={formik.touched.goalAmount}
                onBlur={formik.handleBlur}
                required
              />
            </div>

            <div>
              <TextField
                label="Funds Raised"
                name="fundsRaised"
                type="text"
                value={formik.values.fundsRaised}
                onChange={formik.handleChange}
                error={formik.errors.fundsRaised}
                touched={formik.touched.fundsRaised}
                onBlur={formik.handleBlur}
                required
              />
            </div>

            <div>
              <TextArea
                label="About the cause"
                name="aboutTheCause"
                type="text"
                value={formik.values.aboutTheCause}
                onChange={formik.handleChange}
                error={formik.errors.aboutTheCause}
                touched={formik.touched.aboutTheCause}
                onBlur={formik.handleBlur}
                required
              />
            </div>

            <div>
              <TextArea
                label="Plan of action"
                name="whatWeDo"
                type="text"
                value={formik.values.whatWeDo}
                onChange={formik.handleChange}
                error={formik.errors.whatWeDo}
                touched={formik.touched.whatWeDo}
                onBlur={formik.handleBlur}
              />
            </div>
          </div>

          <label className="block text-sm font-medium text-gray-700 !mb-1">
            Project background image
          </label>
          <div className="relative border-2 border-dashed border-gray-400 rounded-md p-6 !mt-0 flex flex-col justify-center items-center">
            <div className="text-gray-600">
              <p className="mt-1 text-sm leading-normal">
                {!projectBackground && (
                  <>
                    <label
                      htmlFor="file-upload"
                      className="text-blue-500 cursor-pointer hover:underline"
                    >
                      Choose a file
                    </label>{" "}
                    to upload
                  </>
                )}
                {projectBackground && (
                  <>
                    Selected file {"->"} {projectBackground.name} <br />
                    <label
                      htmlFor="file-upload"
                      className="text-blue-500 cursor-pointer hover:underline mt-5"
                    >
                      change
                    </label>
                    <label
                      onClick={() => setProjectBackground(null)}
                      className="text-red-500 cursor-pointer hover:underline ml-5"
                    >
                      Clear
                    </label>
                  </>
                )}
              </p>
            </div>
            <input
              id="file-upload"
              name="file-upload"
              type="file"
              className="hidden"
              onChange={onSelectProjectBackground}
            />
          </div>

          <div className="text-end">
            <Button
              onClick={() => {
                validateOtherFields()
                formik.handleSubmit()
              }}
            >
              Add Project
            </Button>
          </div>
        </form>
      </Card>
    </section>
  )
}

export default AddProject
