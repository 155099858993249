import { useCallback, useState } from "react"
import { useDispatch } from "react-redux"
import { addError } from "redux/pageErrorHandler"

const useApiHandler = () => {
  const dispatch = useDispatch()

  const [loading, setLoading] = useState(false)
  const [data, setData] = useState(null)
  const [error, setError] = useState(false)
  const [message, setMessage] = useState("")
  const [errorCode, setErrorCode] = useState(null)
  const [event, setEvent] = useState(null)

  const request = useCallback(
    async (
      {
        api,
        body,
        config = {
          displayError: true,
          continuousLoading: false,
        },
      },
      callback = () => {}
    ) => {
      setLoading(true)
      try {
        let response = await api(body)
        if (response.status === 200) {
          setData(response.data)
          setError(false)

          if (response.data.message) {
            setMessage(response.data.message)
          }

          if (!config.continuousLoading) {
            setLoading(false)
          }

          callback(response, null)
        }
      } catch (error) {
        console.log(error)
        let err = error?.response?.data
        setEvent(err?.event)
        const { errorCode, message } = err
        setError(true)
        setMessage(message ? message : "")
        setErrorCode(errorCode ? errorCode : null)
        if (config.displayError) {
          dispatch(addError(message))
        }
        setLoading(false)
        callback(null, error)
      }
    },
    [dispatch]
  )

  return { data, loading, error, message, errorCode, request, event: event }
}

export default useApiHandler
