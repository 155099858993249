import { useState } from "react"
import { useFormik } from "formik"
import ApiService from "../../services/ApiService"
import Loader from "../../components/Loader"
import TextField from "../../components/TextField"
import * as Yup from "yup"
import Constants from "../../utils/Constants"
import Card from "../../components/Card"
import Button from "../../components/Button"
import useApiHandler from "../../hooks/useApiHandler"
import Alert from "../../components/Alert"
import commonFunctions from "../../utils/CommonFunctions"
import { useNavigate } from "react-router-dom"

const VerificationMail = () => {
  const queryParams = commonFunctions.getQueryParams()
  const navigate = useNavigate()

  const { loading, error, message, data, request } = useApiHandler()

  const [tranType, setTranType] = useState("Offline")

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: queryParams.get("input") ? queryParams.get("input") : "",
    },
    validationSchema: Yup.object().shape({
      email: Yup.string().required("Required"),
    }),
    onSubmit: () => {
      onClickRenewal()
    },
  })

  const onClickRenewal = () => {
    const body = {
      payment: {
        transactionType:
          tranType === "Paypal"
            ? Constants.MEMBERSHIP_TRANSACTION_TYPE_PAYPAL
            : Constants.MEMBERSHIP_TRANSACTION_TYPE_OFFLINE,
        amount: Constants.MEMBERSHIP_AMOUNT,
        intent: Constants.MEMBERSHIP_INTENT,
        currency: Constants.MEMBERSHIP_CURRENCY,
        paymentType: Constants.MEMBERSHIP_PAYMENT_TYPE_RENEWAL,
      },
      email: formik.values.email,
    }

    request(
      {
        api: tranType === "Paypal" ? ApiService.membershipRenewal : ApiService.membershipRenewalOffline,
        body: body,
        config: {
          displayError: false,
          continuousLoading: true,
        },
      },
      (response, err) => {
        if (err) return

        if (response.status === 200) {
          if (tranType === "Paypal") {
            let link = response?.data?.url || null
            if (link) {
              window.open(link, "_self")
            }
          } else {
            navigate("/membership-renewal/offline")
          }
        }
      }
    )
  }

  return (
    <section>
      <Loader open={loading} />
      <div className="flex flex-col items-center px-6 py-8 mx-auto lg:py-0 my-[6rem]">
        <Card className="sm:max-w-md shadow">
          <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
            <h1 className="text-xl font-medium leading-tight text-gray-700 md:text-2xl">
              Membership Renewal
            </h1>
            <form className="space-y-4 md:space-y-6">
              <div className="col-span-6 sm:col-span-3">
                <TextField
                  label="Email or Username"
                  name="email"
                  type="text"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  error={formik.errors.email}
                  touched={formik.touched.email}
                  onBlur={formik.handleBlur}
                />
              </div>

              {!loading && !error && message !== null && <Alert>{message}</Alert>}

              {!loading && error && message !== null && <Alert type="danger">{message}</Alert>}

              <div className="flex justify-end">
                <Button
                  onClick={() => {
                    setTranType("Offline")
                    formik.handleSubmit()
                  }}
                >
                  Renew Offline
                </Button>
                <br />
                <Button
                  onClick={() => {
                    setTranType("Paypal")
                    formik.handleSubmit()
                  }}
                  className="ml-2"
                >
                  Renew PAYPAL
                </Button>
              </div>
            </form>
          </div>
        </Card>
      </div>
    </section>
  )
}

export default VerificationMail
