import CustomDialog from "components/Dialog"
import Button from "components/Button"
import ReactJson from "react-json-view"

const ViewJson = ({ open, setOpen, data = {}, name }) => {
  return (
    <CustomDialog open={open} header={name} size="lg">
      <CustomDialog.Content>
        <ReactJson src={data} name={name} displayObjectSize={false} displayDataTypes={false} />
      </CustomDialog.Content>
      <CustomDialog.Footer>
        <Button onClick={() => setOpen(false)} variant="secondary" className="w-full">
          Cancel
        </Button>
      </CustomDialog.Footer>
    </CustomDialog>
  )
}

export default ViewJson
