import { removeError } from "redux/pageErrorHandler"
import { useSelector, useDispatch } from "react-redux"
import { useEffect, useState } from "react"
import Dialog from "../Dialog"
import Button from "../Button"

const PageErrorHandler = () => {
  const pageErrorState = useSelector((state) => state.pageErrors)
  const dispatch = useDispatch()

  const [open, setOpen] = useState(false)
  const [errors, setErrors] = useState([])

  useEffect(() => {
    setErrors(pageErrorState.errors)
    if (pageErrorState.errors.length > 0 && !open) {
      setOpen(true)
    }
    window.addEventListener("beforeunload", removeErrorFn)

    return () => {
      window.removeEventListener("beforeunload", removeErrorFn)
    }
  }, [dispatch, pageErrorState.errors])

  const onCloseDialog = (v) => {
    removeErrorFn()
    setOpen(v)
  }

  const removeErrorFn = () => {
    pageErrorState.errors.forEach((error) => {
      dispatch(removeError(error.id))
    })
  }

  return (
    <>
      <Dialog
        open={open}
        setOpen={onCloseDialog}
        // size='lg'
      >
        <Dialog.Content>
          <ul className="list-disc text-red-500">
            {errors.map((item, index) => (
              <span key={index} className="text-xl">
                {item.message}
              </span>
            ))}
          </ul>
        </Dialog.Content>
        <Dialog.Footer>
          <Button variant="secondary" onClick={() => onCloseDialog(false)}>
            Close
          </Button>
        </Dialog.Footer>
      </Dialog>
    </>
  )
}

export default PageErrorHandler
