import { Fragment, useCallback, useEffect } from "react"
import { useParams } from "react-router-dom"
import ApiService from "../../services/ApiService"
import Card from "../../components/Card"
import Button from "../../components/Button"

import useApiHandler from "../../hooks/useApiHandler"

const VerificationMail = () => {
  const { token } = useParams()

  const { loading, error, message, request } = useApiHandler()

  const verificationMail = useCallback(
    async (token) => {
      request({
        api: ApiService.verifyMail,
        body: { token },
        config: { displayError: false },
      })
    },
    [request]
  )

  useEffect(() => {
    console.log(token)
    verificationMail(token)
  }, [token, verificationMail])

  const backToSignIn = () => {
    window.location.href = "/signin"
  }

  return (
    <Fragment>
      <div className="flex flex-col items-center px-6 py-8 mx-auto lg:py-0 my-[6rem]">
        <Card className="sm:max-w-md shadow">
          <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
            <div className="mt-3 text-center flex flex-col items-center">
              {loading && displayVerifying()}

              {!loading && !error && displayVerified(backToSignIn)}

              {!loading && error && message !== "" && displayNotVerified(backToSignIn, message)}
            </div>
          </div>
        </Card>
      </div>
    </Fragment>
  )
}

const displayVerifying = () => {
  return (
    <Fragment>
      <div className="w-8 h-8 border-4 border-dashed rounded-full animate-spin border-[#10a37f]"></div>
      <h3 className="text-lg leading-6 font-medium text-gray-900">Verifying your email, please wait.</h3>
    </Fragment>
  )
}

const displayVerified = (backToSignIn) => {
  return (
    <Fragment>
      <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          className="text-green-600"
        >
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
        </svg>
      </div>

      <h3 className="text-lg leading-6 font-medium text-gray-900">Email Verification successfull</h3>
      <div className="mt-2 px-7 py-3">
        <div className="mt-5">
          <Button onClick={backToSignIn}>Go to Signin</Button>
        </div>
      </div>
    </Fragment>
  )
}

const displayNotVerified = (backToSignIn, message) => {
  return (
    <Fragment>
      <h3 className="text-lg leading-6 font-medium text-red-600"> {message} </h3>
      <div className="mt-2 px-7 py-3">
        <Button onClick={backToSignIn}>Go to Signin</Button>
      </div>
    </Fragment>
  )
}

export default VerificationMail
