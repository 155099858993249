import { useEffect, useState } from "react"
import commonFunctions from "utils/CommonFunctions"

const TextField = ({
  autoFocus = false,
  label,
  name,
  value,
  onChange,
  onClick = () => {},
  onFocus = () => {},
  onBlur,
  type = "text",
  error,
  className = "",
  touched = false,
  required,
  disabled = false,
  placeholder = "",
  phoneNumberFormat = false,
  helpText = ''
}) => {
  const [textFieldType, setTextFieldType] = useState("text")
  const [isPasswordVisible, setIsPasswordVisible] = useState(false)

  useEffect(() => {
    setTextFieldType(type)
  }, [type])

  const togglePasswordVisibility = () => {
    if (textFieldType === "text") {
      setTextFieldType("password")
      setIsPasswordVisible(false)
    } else {
      setTextFieldType("text")
      setIsPasswordVisible(true)
    }
  }

  return (
    <span>
      <label htmlFor={name} className="block text-sm font-medium text-gray-700">
        {label} {required && <span className="text-md text-red-500">*</span>} 
        {helpText !== "" && <span className="text-xs">{helpText}</span>}
      </label>
      <div className="relative">
        <input
          type={textFieldType}
          name={name}
          disabled={disabled}
          id={name}
          autoFocus={autoFocus}
          autoComplete="given-name"
          className={`mt-1 block w-full rounded-md shadow-sm sm:text-sm ${className} disabled:opacity-[.5] disabled:cursor-not-allowed ${
            error && touched
              ? `border-red-500 border-2 focus:border-red-500 focus:ring-0`
              : `border-gray-300 focus:border-[#10a37f] focus:ring-[#10a37f]`
          } `}
          onChange={(e) => {
            if (textFieldType === 'number') {
              const re = /^[0-9\b]+$/;
              console.log(e.target.value)
              if (e.target.value !== '' && !re.test(e.target.value)) {
                return
              }
            }
            if (phoneNumberFormat) {
              e.target.value = commonFunctions.phoneNumberFormat(e.target.value)
            }
            onChange(e)
          }}
          value={phoneNumberFormat ? commonFunctions.phoneNumberFormat(value) : value}
          onBlur={onBlur}
          onClick={onClick}
          onFocus={onFocus}
          placeholder={placeholder}
          onWheel={() => {
            document.activeElement.blur()
          }}
        />
        {type === "password" && PasswordIcon(isPasswordVisible, togglePasswordVisibility)}
      </div>
      {touched && error && <span className="text-red-600 text-xs">{error}</span>}
    </span>
  )
}

const PasswordIcon = (isPasswordVisible, togglePasswordVisibility = () => {}) => {
  return (
    <div
      className="absolute inset-y-0 right-0 flex items-center px-4 text-gray-600 cursor-pointer"
      onClick={togglePasswordVisibility}
    >
      {isPasswordVisible ? (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="w-5 h-5"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M3.98 8.223A10.477 10.477 0 001.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.45 10.45 0 0112 4.5c4.756 0 8.773 3.162 10.065 7.498a10.523 10.523 0 01-4.293 5.774M6.228 6.228L3 3m3.228 3.228l3.65 3.65m7.894 7.894L21 21m-3.228-3.228l-3.65-3.65m0 0a3 3 0 10-4.243-4.243m4.242 4.242L9.88 9.88"
          />
        </svg>
      ) : (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="w-5 h-5"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z"
          />
          <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
        </svg>
      )}
    </div>
  )
}

export default TextField
