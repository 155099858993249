import Img from "../../assets/volunteer/group-hands.jpg"
import Blog1 from "../../assets/volunteer/blog-1.jpg"
import Blog2 from "../../assets/volunteer/blog-2.jpg"
import Blog3 from "../../assets/volunteer/blog-3.jpg"

import SelectMenu from "../../components/SelectMenu"
import TextField from "../../components/TextField"
import Loader from "../../components/Loader"
import { useFormik } from "formik"

import * as Yup from "yup"
import { useEffect, useState } from "react"
import ApiService from "../../services/ApiService"
import Button from "../../components/Button"
import useApiHandler from "hooks/useApiHandler"
import useToast from "hooks/useToast"
import commonFunctions from "utils/CommonFunctions"

const Volunteer = () => {
  const { request: requestEvents, loading: loadingEvents } = useApiHandler()

  const { request: requestAddVol, loading: loadingAddVol } = useApiHandler()

  const { renderSuccessToast } = useToast()

  const [events, setEvents] = useState([])
  const [selectEvtErr, setSelectEvtErr] = useState(null)
  const [eventId, setEventId] = useState(null)

  const emailRefExp =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: "",
      email: "",
      phone: "",
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required("Name is required"),
      email: Yup.string()
        .email()
        .required("Email is required")
        .matches(emailRefExp, "email must be a valid email"),
      phone: Yup.string().required("Phone is required"),
    }),
    onSubmit: (values, { resetForm }) => {
      // values["eventId"] = event;
      addVolunteer(values, resetForm)
    },
  })

  const addVolunteer = async (values, resetForm) => {
    if (eventId === null) {
      setSelectEvtErr("Please select an event.")
      return
    }

    const body = {
      eventId: eventId,
      name: values.name,
      email: values.email,
      phone: commonFunctions.phoneNumberToText(values.phone),
    }

    requestAddVol(
      {
        api: ApiService.addVolunteer,
        body: body,
        config: {
          displayError: true,
        },
      },
      (_, err) => {
        if (err) return

        resetForm()
        setEventId(null)
        renderSuccessToast("Registered as volunteer.")
      }
    )
  }

  const getAllEvents = async () => {
    requestEvents(
      {
        api: ApiService.getAllEvents,
        config: {
          displayError: true,
        },
      },
      (res, err) => {
        if (err) return

        let eventsItems = [
          {
            name: "General",
            value: "General",
          },
        ]
        res.data?.events.map((evts) => {
          eventsItems.push({
            name: evts["eventName"],
            value: evts["_id"],
          })
        })
        setEvents(eventsItems)
      }
    )
  }

  useEffect(() => {
    getAllEvents()
  }, [])

  return (
    <div>
      <Loader open={loadingAddVol || loadingEvents} />

      <div
        className="py-20 justify-center bg-no-repeat bg-cover bg-center h-[25rem]"
        style={{ backgroundImage: `url(${Img})` }}
      >
        <div className="container my-auto px-6 pt-10">
          <h2 className="text-4xl font-bold mb-2 text-white">Become A Volunteer</h2>
        </div>
      </div>

      <div className="flex flex-wrap justify-center my-10">
        <div className="md:w-[20%] p-2 relative">
          <img
            className="h-full w-full justify-center bg-no-repeat bg-cover bg-center"
            src={Blog1}
            alt="Image 1"
          />
          <div className="overlay absolute inset-2 bg-black opacity-20"></div>
          <div className="text absolute inset-0 flex items-center justify-center text-white font-bold flex-col">
            <div>STEP 1</div>
            <div className="text-xl">Submit Details</div>
          </div>
        </div>
        <div className="md:w-[20%]  p-2 relative">
          <img
            className="h-full w-full justify-center bg-no-repeat bg-cover bg-center"
            src={Blog2}
            alt="Blog 2"
          />
          <div className="overlay absolute inset-2 bg-black opacity-20"></div>
          <div className="text absolute inset-0 flex items-center justify-center text-white font-bold flex-col">
            <div>STEP 2</div>
            <div className="text-xl">Team Review</div>
          </div>
        </div>
        <div className="md:w-[20%]  p-2 relative">
          <img
            className="h-full w-full justify-center bg-no-repeat bg-cover bg-center"
            src={Blog3}
            alt="Image 3"
          />
          <div className="overlay absolute inset-2 bg-black opacity-20"></div>
          <div className="text absolute inset-0 flex items-center justify-center text-white font-bold flex-col">
            <div>STEP 3</div>
            <div className="text-xl">Explore Opportunities</div>
          </div>
        </div>
      </div>

      <div className="flex justify-center my-10 px-2">
        <div className="text-center">
          <div>
            Thank you for your interest. Together we can make a real difference in people’s lives.
          </div>
          <div className="text-4xl">Sign-up to be a volunteer and help us to make a difference.</div>
        </div>
      </div>

      <div className="flex justify-center my-10 px-5">
        <form className="space-y-4 md:space-y-4 w-full max-w-lg">
          <div className="">
            <TextField
              label="Name"
              name="name"
              type="text"
              value={formik.values.name}
              onChange={formik.handleChange}
              error={formik.errors.name}
              touched={formik.touched.name}
              onBlur={formik.handleBlur}
            />
          </div>
          <div className="col-span-6 sm:col-span-3">
            <TextField
              label="Email"
              name="email"
              type="email"
              value={formik.values.email}
              onChange={formik.handleChange}
              error={formik.errors.email}
              touched={formik.touched.email}
              onBlur={formik.handleBlur}
            />
          </div>

          <div className="col-span-6 sm:col-span-3">
            <TextField
              label="Phone"
              name="phone"
              type="text"
              placeholder="(XXX) XXX-XXXX"
              phoneNumberFormat={true}
              value={formik.values.phone}
              onChange={formik.handleChange}
              error={formik.errors.phone}
              touched={formik.touched.phone}
              onBlur={formik.handleBlur}
            />
          </div>

          <div className="col-span-6 sm:col-span-3">
            <label htmlFor="chooseEvent" className="block text-sm font-medium text-gray-700">
              Choose an event
            </label>
            <SelectMenu
              items={events}
              onChange={(data) => {
                setEventId(data.value)
                setSelectEvtErr(null)
              }}
              hasError={selectEvtErr}
              error={selectEvtErr}
              value={eventId}
            />
          </div>

          <div>
            Please reach out to contact@dhvanifoundation.com for any additional information about
            volunteering.
          </div>

          <Button onClick={formik.handleSubmit}>Register</Button>
        </form>
      </div>
    </div>
  )
}

export default Volunteer
