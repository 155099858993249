import { useEffect, useState } from "react"
import SelectMenu from "../../../components/SelectMenu"
import ApiService from "../../../services/ApiService"
import { HandleDate } from "../../../utils/Helpers"
import useApiHandler from "hooks/useApiHandler"
import Button from "components/Button"
import CustomDialog from "components/Dialog"
import Alert from "components/Alert"
import ProgressLoading from "components/ProgressLoading"

const PreviousCauses = ({ open, setOpen, getAdminCauses = () => {} }) => {
  const { request, loading, data, error, message } = useApiHandler()

  const [selectSubmission, setSelectSubmission] = useState(null)
  const [selectSubmissionError, setSelectSubmissionError] = useState(null)

  useEffect(() => {
    if (open) getAllSubmissions()
  }, [open])

  const [dropdownItems, setDropdwonItems] = useState([])
  useEffect(() => {
    if (data) {
      let allSubs = data?.submissions.map((sub) => {
        return {
          name: HandleDate(sub["causeStartDate"]) + " ----- " + HandleDate(sub["causeEndDate"]),
          value: sub._id,
        }
      })
      setDropdwonItems(allSubs)
    }
  }, [data])

  const getAllSubmissions = async () => {
    request({
      api: ApiService.getAllSubmissions,
      body: { onlyPrevious: true },
      config: {
        displayError: true,
      },
    })
  }

  const getCauses = async () => {
    if (selectSubmission == null || selectSubmission.value == null) {
      setSelectSubmissionError("Required")
      return
    }
    window.location.href = "/my-submitted-causes?windowId=" + selectSubmission.value + "&" + "ct=1"
    closeDialog()
  }

  const closeDialog = () => {
    setSelectSubmission(null)
    setOpen(false)
  }

  return (
    <CustomDialog open={open} header="Previous Causes" exceptions={["SET_OVERFLOW_UNSET"]}>
      <CustomDialog.Content>
        {loading && <ProgressLoading />}
        {!loading && (
          <form className="space-y-4 md:space-y-6">
            <div>
              <SelectMenu
                label="Cause windows"
                items={dropdownItems}
                onChange={(data) => {
                  setSelectSubmission(data)
                  setSelectSubmissionError(null)
                }}
                value={selectSubmission?.value}
                hasError={selectSubmissionError}
                error={selectSubmissionError}
                required
              />
            </div>
          </form>
        )}

        {error && <Alert type="danger">{message}</Alert>}
      </CustomDialog.Content>
      <CustomDialog.Footer>
        <Button className="sm:ml-3 w-full" loading={loading} onClick={getCauses}>
          Get causes
        </Button>
        <Button variant="secondary" onClick={closeDialog} className="w-full">
          Cancel
        </Button>
      </CustomDialog.Footer>
    </CustomDialog>
  )
}

export default PreviousCauses
