import { Fragment, useRef, useState } from "react"
import TextField from "components/TextField"
import Card from "components/Card"
import FileSelector from "components/FileSelector"
import Button from "components/Button"

import * as Yup from "yup"
import { useFormik } from "formik"
import useApiHandler from "hooks/useApiHandler"
import useToast from "hooks/useToast"
import ApiService from "services/ApiService"

const ManageSponsers = () => {
  const { request, loading } = useApiHandler()

  const { renderSuccessToast } = useToast()

  const fileRef = useRef()

  const [image, setImage] = useState(null)

  const [fieldsValidated, setFieldsValidated] = useState(true)
  const [imageError, setImageError] = useState(null)

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      sponserName: "",
    },
    validationSchema: Yup.object().shape({
      sponserName: Yup.string().required("Required"),
    }),
    onSubmit: () => {
      if (!fieldsValidated) return

      addSponser()
    },
  })

  const handleOtherFields = () => {
    if (!image) {
      setFieldsValidated(false)
      setImageError("Please select image")
    } else {
      setFieldsValidated(true)
    }
  }

  const addSponser = () => {
    try {
      const formData = new FormData()
      formData.append("sponserName", formik.values.sponserName.trim())
      formData.append("image", image)

      request(
        {
          api: ApiService.addSponser,
          body: formData,
        },
        (_, err) => {
          if (err) return

          formik.resetForm()
          setImage(null)
          setImageError(null)
          fileRef.current.clearFiles()
          renderSuccessToast("Sponser added")
        }
      )
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <Fragment>
      <header className="bg-white shadow">
        <div className="mx-auto py-3 px-4 sm:px-6 lg:px-8 flex justify-between">
          <h1 className="text-xl tracking-tight text-gray-900">Add Sponser</h1>

          <div className="flex items-center">
            <div className="ml-3">
              <Button variant="secondary" onClick={() => (window.location.href = "/view-sponsers")}>
                View Sponsers
              </Button>
            </div>
          </div>
        </div>
      </header>

      <div>
        <Card className="p-5">
          <form className="space-y-4 m-4 md:space-y-6">
            <div className="grid grid-cols-1 gap-6 mt-4 sm:grid-cols-2">
              <div>
                <TextField
                  label="Sponser Name"
                  name="sponserName"
                  type="text"
                  value={formik.values.sponserName}
                  onChange={formik.handleChange}
                  error={formik.errors.sponserName}
                  touched={formik.touched.sponserName}
                  onBlur={formik.handleBlur}
                  required
                />
              </div>
            </div>

            <FileSelector
              label="Choose sponser image"
              description="Choose a image to upload"
              preview={false}
              onSelected={(e) => {
                setImage(e.target.files[0])
                setImageError(null)
              }}
              required
              error={imageError}
              ref={fileRef}
            />

            <div className="text-end">
              <Button
                onClick={() => {
                  handleOtherFields()
                  formik.handleSubmit()
                }}
                loading={loading}
                loadingText="Uploading...."
              >
                Add Sponser
              </Button>
            </div>
          </form>
        </Card>
      </div>
    </Fragment>
  )
}

export default ManageSponsers
