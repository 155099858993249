import { useFormik } from "formik"
import ApiService from "../../services/ApiService"
import Loader from "../../components/Loader"
import TextField from "../../components/TextField"
import * as Yup from "yup"
import Card from "../../components/Card"
import useApiHandler from "../../hooks/useApiHandler"
import Button from "../../components/Button"
import Alert from "../../components/Alert"

const ForgotPassword = () => {
  const { loading, error, message, request } = useApiHandler()

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: "",
    },
    validationSchema: Yup.object().shape({
      email: Yup.string().required("Required"),
    }),
    onSubmit: (values) => {
      passwordReset(values)
    },
  })

  const passwordReset = async (values) => {
    const { email } = values

    request({
      api: ApiService.send_pass_reset_mail,
      body: { email },
      config: { displayError: false },
    })
  }

  return (
    <section>
      <Loader open={loading} />

      <div className="flex flex-col items-center px-6 py-8 mx-auto lg:py-0 my-[6rem]">
        <Card className="sm:max-w-md shadow">
          <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
            <h1 className="text-xl font-medium leading-tight text-gray-700 md:text-2xl">
              Forgot Password
            </h1>
            <form className="space-y-4 md:space-y-6">
              <div className="col-span-6 sm:col-span-3">
                <TextField
                  label="Email or Username"
                  name="email"
                  type="text"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  error={formik.errors.email}
                  touched={formik.touched.email}
                  onBlur={formik.handleBlur}
                />
              </div>

              {!loading && !error && message !== "" && <Alert type="success">{message}</Alert>}

              {!loading && error && message !== "" && <Alert type="danger">{message}</Alert>}

              <Button onClick={formik.handleSubmit}>Send Reset Link</Button>

              <div className="text-sm font-light text-gray-500">
                <a href="/signin" className="font-medium text-primary-600 hover:underline">
                  Go back
                </a>
              </div>
            </form>
          </div>
        </Card>
      </div>
    </section>
  )
}

export default ForgotPassword
