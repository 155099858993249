class EventUtils {
  eventTypes = [
    { name: "Cricket", value: "cricket" },
    { name: "Tennis", value: "tennis" },
    { name: "Poker", value: "poker" },
    { name: "Badminton", value: "badminton" },
    { name: "Run", value: "run" },
    { name: "Volleyball", value: "volleyball" },
  ]

  defaultCost = [
    {
      name: "Event",
      key: "default",
    },
  ]

  categories = {
    cricket: this.defaultCost,
    tennis: [
      {
        name: "Adult Singles",
        key: "adult-singles",
      },
      {
        name: "Adult Doubles",
        key: "adult-doubles",
      },
      {
        name: "Youth Singles",
        key: "youth-singles",
      },
      {
        name: "Youth Doubles",
        key: "youth-doubles",
      },
      {
        name: "Kids Singles",
        key: "kids-singles",
      },
    ],
    poker: this.defaultCost,
    badminton: this.defaultCost,
    run: this.defaultCost,
    volleyball: this.defaultCost,
  }
}

export default new EventUtils()
