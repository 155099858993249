import Img from "../../assets/volunteer/group-hands.jpg"

const Privacy = () => {
  return (
    <div>
      <div
        className="py-20 justify-center bg-no-repeat bg-cover bg-center h-[25rem]"
        style={{ backgroundImage: `url(${Img})` }}
      >
        <div className="container my-auto px-6 pt-10">
          <h2 className="text-4xl font-bold mb-2 text-white">Privacy Policy</h2>
        </div>
      </div>

      <section className="container mx-auto px-6 my-16">
        <span className="tracking-wide text-lg">
          Privacy Policy – This Privacy Policy governs the manner in which Dhvani Foundation collects,
          uses, maintains and discloses information collected from users (each, a “User”) of the Dhvani
          Foundation website (“Site”). This privacy policy applies to the Site and all products and
          services offered by Dhvani Foundation.
          <br />
          <br />
          Personal identification information – We may collect personal identification information from
          Users in a variety of ways, including, but not limited to, when Users visit our site, subscribe
          to the newsletter, fill out a form, and in connection with other activities, services, features
          or resources we make available on our Site. Users may be asked for, as appropriate, name, email
          address, mailing address, phone number. Users may, however, visit our Site anonymously. We will
          collect personal identification information from Users only if they voluntarily submit such
          information to us. Users can always refuse to supply personally identification information,
          except that it may prevent them from engaging in certain Site related activities.
          <br />
          <br />
          Non-personal identification information – We may collect non-personal identification
          information about Users whenever they interact with our Site. Non-personal identification
          information may include the browser name, the type of computer and technical information about
          Users means of connection to our Site, such as the operating system and the Internet service
          providers utilized and other similar information.
          <br />
          <br />
          Web browser cookies – Our Site may use “cookies” to enhance User experience. User’s web browser
          places cookies on their hard drive for record-keeping purposes and sometimes to track
          information about them. User may choose to set their web browser to refuse cookies, or to alert
          you when cookies are being sent. If they do so, note that some parts of the Site may not
          function properly.
          <br />
          <br />
          How we use collected information – Dhvani Foundation collects and uses Users personal
          information for the following purposes:
          <br />
          <br />
          To personalize user experience – We may use information in the aggregate to understand how our
          Users as a group use the services and resources provided on our Site.
          <br />
          <br />
          To improve our Site – We continually strive to improve our website offerings based on the
          information and feedback we receive from you.
          <br />
          <br />
          To improve customer service – Your information helps us to more effectively respond to your
          customer service requests and support needs.
          <br />
          <br />
          To send periodic emails – The email address Users provide will only be used to respond to their
          inquiries, and/or other requests or questions. If User decides to opt-in to our mailing list,
          they will receive emails that may include company news, updates, related product or service
          information, etc. If at any time the User would like to unsubscribe from receiving future
          emails, they may do so by contacting us via our Site.
          <br />
          <br />
          How we protect your information – We adopt appropriate data collection, storage, and processing
          practices and security measures to protect against unauthorized access, alteration, disclosure
          or destruction of your personal information, username, password, transaction information and
          data stored on our Site.
          <br />
          <br />
          Sharing your personal information – We do not sell, trade, or rent Users personal
          identification information to others. We may share generic aggregated demographic information
          not linked to any personal identification information regarding visitors and users with our
          business partners, trusted affiliates and advertisers for the purposes outlined above.
          <br />
          <br />
          Third-party websites – Users may find advertising or other content on our Site that link to the
          sites and services of our partners, suppliers, advertisers, sponsors, licensors and other third
          parties. We do not control the content or links that appear on these sites and are not
          responsible for the practices employed by websites linked to or from our Site. In addition,
          these sites or services, including their content and links, may be constantly changing. These
          sites and services may have their own privacy policies and customer service policies. Browsing
          and interaction on any other website, including websites which have a link to our Site, is
          subject to that website’s own terms and policies.
          <br />
          <br />
          Changes to this privacy policy – Dhvani Foundation has the discretion to update this privacy
          policy at any time. When we do, we will revise the updated date at the bottom of this page. We
          encourage Users to frequently check this page for any changes to stay informed about how we are
          helping to protect the personal information we collect. You acknowledge and agree that it is
          your responsibility to review this privacy policy periodically and become aware of
          modifications .<br />
          <br />
          Your acceptance of these terms – By using this Site, you signify your acceptance of this
          policy. If you do not agree to this policy, please do not use our Site. Your continued use of
          the Site following the posting of changes to this policy will be deemed your acceptance of
          those changes.
          <br />
          <br />
          Contact us – If you have any questions about this Privacy Policy, the practices of this site,
          or your dealings with this site, please contact us at: contact@dhvanifoundation.org
        </span>
      </section>
    </div>
  )
}

export default Privacy
