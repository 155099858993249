import "flatpickr/dist/themes/airbnb.css"
import { useState } from "react"
import Dialog from "./Dialog.js"
import moment from "moment"
import ApiService from "services/ApiService.js"
import Loader from "components/Loader/index.js"
import { useEffect } from "react"
import SelectDate from "components/SelectDate"
import useApiHandler from "hooks/useApiHandler.js"
import useToast from "hooks/useToast.js"
import Button from "components/Button/index.js"
import Alert from "components/Alert/index.js"

const ApplicationParamaters = () => {
  const {
    request: requestSubmissions,
    loading: submissionsLoading,
    error: submissionsError,
    message: submissionsErrorMessage,
    data: submissions,
  } = useApiHandler()

  const {
    request: requestAddSubmission,
    loading: addSubmissionLoading,
    error: addSubmissionError,
    message: addSubmissionErrorMessage,
  } = useApiHandler()

  const { renderSuccessToast } = useToast()

  const [causeStartDate, setCauseStartDate] = useState(null)
  const [causeEndDate, setCauseEndDate] = useState(null)
  const [voteStartDate, setVoteStartDate] = useState(null)
  const [voteEndDate, setVoteEndDate] = useState(null)

  const [causeStartDateError, setCauseStartDateError] = useState(null)
  const [causeEndDateError, setCauseEndDateError] = useState(null)
  const [voteStartDateError, setVoteStartDateError] = useState(null)
  const [voteEndDateError, setVoteEndDateError] = useState(null)

  const [minDates, setMinDates] = useState({
    causeStartDate: "today",
    causeEndDate: "today",
    voteStartDate: "today",
    voteEndDate: "today",
  })

  const [open, setOpen] = useState(false)
  const [dialogText, setDialogText] = useState("")

  useEffect(() => {
    getLastestSubmission()
    // eslint-disable-next-line
  }, [])

  const addSubmission = async () => {
    if (
      causeEndDate === null ||
      causeStartDate === null ||
      voteStartDate === null ||
      voteEndDate === null
    ) {
      if (causeStartDate === null) setCauseStartDateError("Cause Start Date is required.")
      if (causeEndDate === null) setCauseEndDateError("Cause End Date is required.")
      if (voteStartDate === null) setVoteStartDateError("Vote Start Date is required.")
      if (voteEndDate === null) setVoteEndDateError("Vote End Date is required.")

      return
    }

    const body = {
      causeStartDate,
      causeEndDate,
      voteStartDate,
      voteEndDate,
    }

    requestAddSubmission(
      {
        api: ApiService.addSubmission,
        body: body,
        config: {
          displayError: true,
        },
      },
      (_, err) => {
        if (err) return

        getLastestSubmission()
        renderSuccessToast("Submitted")
        resetForm()
      }
    )
  }

  const resetForm = () => {
    setCauseStartDate(null)
    setCauseEndDate(null)
    setVoteStartDate(null)
    setVoteEndDate(null)

    setCauseStartDateError(null)
    setCauseEndDateError(null)
    setVoteStartDateError(null)
    setVoteEndDateError(null)
  }

  const getLastestSubmission = async () => {
    requestSubmissions({
      api: ApiService.getLatestSubmission,
      config: {
        displayError: true,
      },
    })
  }

  return (
    <section>
      <Loader open={addSubmissionLoading || submissionsLoading} />

      <header className="bg-white shadow">
        <div className="mx-auto py-3 px-4 sm:px-6 lg:px-8 flex justify-between">
          <h1 className="text-2xl font-bold tracking-tight text-gray-900">Application Parameters</h1>
        </div>
      </header>
      <Dialog open={open} setOpen={setOpen} text={dialogText} />

      <div className="flex flex-row  justify-center px-6 py-8 mx-auto lg:py-0 my-[3rem]">
        <div className="w-full bg-white rounded-lg shadow border md:mt-0 sm:max-w-[50rem] xl:p-0">
          <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
            <form className="space-y-4 md:space-y-6">
              <div className="grid grid-cols-1 gap-6 mt-4 sm:grid-cols-2">
                <div>
                  <SelectDate
                    label="Cause Start Date"
                    name="causeStartDate"
                    value={causeStartDate}
                    onChange={({ date }) => {
                      setCauseStartDate(date)
                      setCauseStartDateError(null)
                      var duration = moment.duration({
                        days: 1,
                      })
                      setMinDates({
                        ...minDates,
                        causeEndDate: moment(date).add(duration).toDate(),
                      })
                    }}
                    options={{
                      minDate: minDates["causeStartDate"],
                    }}
                    hasError={causeStartDateError}
                    error={causeStartDateError}
                    placeholder={moment.utc(submissions?.submission?.causeStartDate).format("L")}
                    required
                  />
                </div>

                <div>
                  <SelectDate
                    label="Cause End Date"
                    name="causeEndDate"
                    value={causeEndDate}
                    onChange={({ date }) => {
                      setCauseEndDate(date)
                      setCauseEndDateError(null)
                      var duration = moment.duration({
                        days: 1,
                      })
                      setMinDates({
                        ...minDates,
                        voteStartDate: moment(date).add(duration).toDate(),
                      })
                    }}
                    options={{
                      minDate: minDates["causeEndDate"],
                      onOpen: [
                        function (selectedDates, dateStr, instance) {
                          if (causeStartDate === null) {
                            setOpen(true)
                            setDialogText("Please select Cause start date")
                            instance.close()
                          }
                        },
                      ],
                    }}
                    hasError={causeEndDateError}
                    error={causeEndDateError}
                    placeholder={moment.utc(submissions?.submission?.causeEndDate).format("L")}
                    required
                  />
                </div>

                <div>
                  <SelectDate
                    label="Vote Start Date"
                    name="voteStartDate"
                    value={voteStartDate}
                    onChange={({ date }) => {
                      setVoteStartDate(date)
                      setVoteStartDateError(null)
                      var duration = moment.duration({
                        days: 1,
                      })
                      setMinDates({
                        ...minDates,
                        voteEndDate: moment(date).add(duration).toDate(),
                      })
                    }}
                    options={{
                      minDate: minDates["voteStartDate"],
                      onOpen: [
                        function (selectedDates, dateStr, instance) {
                          console.log("*^*&%%&^*")
                          if (causeEndDate === null) {
                            setOpen(true)
                            setDialogText("Please select Cause end date")
                            instance.close()
                          }
                        },
                      ],
                    }}
                    hasError={voteStartDateError}
                    error={voteStartDateError}
                    placeholder={moment.utc(submissions?.submission?.voteStartDate).format("L")}
                    required
                  />
                </div>

                <div>
                  <SelectDate
                    label="Vote End Date"
                    name="voteEndDate"
                    value={voteEndDate}
                    onChange={({ date }) => {
                      setVoteEndDate(date)
                      setVoteEndDateError(null)
                    }}
                    options={{
                      minDate: minDates["voteEndDate"],
                      onOpen: [
                        function (selectedDates, dateStr, instance) {
                          if (voteStartDate === null) {
                            setOpen(true)
                            setDialogText("Please select Cause end date")
                            instance.close()
                          }
                        },
                      ],
                    }}
                    hasError={voteEndDateError}
                    error={voteEndDateError}
                    placeholder={moment.utc(submissions?.submission?.voteEndDate).format("L")}
                    required
                  />
                </div>
              </div>

              <div className="text-end">
                <Button
                  onClick={() => {
                    addSubmission()
                  }}
                  disabled={!causeStartDate || !causeEndDate || !voteStartDate || !voteEndDate}
                >
                  Submit
                </Button>
              </div>

              {addSubmissionError && <Alert type="danger">{addSubmissionErrorMessage}</Alert>}
              {submissionsError && <Alert type="danger">{submissionsErrorMessage}</Alert>}
            </form>
          </div>
        </div>
      </div>

      <section className="container mx-auto px-5 pb-[5rem]">
        Previous Submissions
        <div className="overflow-x-auto mt-4">
          <table className="min-w-full divide-y-2 divide-gray-200 bg-white rounded-md text-sm">
            <thead className="text-left">
              <tr>
                <th className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">
                  Cause Start Date
                </th>
                <th className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">Cause End Date</th>
                <th className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">
                  Vote Start Date
                </th>
                <th className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">Vote End Date</th>
              </tr>
            </thead>

            <tbody className="divide-y divide-gray-200">
              {submissions?.previousSubmissions?.length > 0 &&
                submissions?.previousSubmissions?.map((sub, index) => (
                  <tr key={index} className={`${index === 0 ? "bg-green-200" : ""}`}>
                    <td className="whitespace-nowrap px-4 py-2 text-gray-700">
                      {moment.utc(sub.causeStartDate).format("DD-MMM-YYYY")}
                    </td>
                    <td className="whitespace-nowrap px-4 py-2 text-gray-700">
                      {moment.utc(sub.causeEndDate).format("DD-MMM-YYYY")}
                    </td>
                    <td className="whitespace-nowrap px-4 py-2 text-gray-700">
                      {moment.utc(sub.voteStartDate).format("DD-MMM-YYYY")}
                    </td>
                    <td className="whitespace-nowrap px-4 py-2 text-gray-700">
                      {moment.utc(sub.voteEndDate).format("DD-MMM-YYYY")}
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </section>
    </section>
  )
}

export default ApplicationParamaters
