import { useEffect, useState } from "react"
import SelectMenu from "../../../components/SelectMenu"
import ApiService from "../../../services/ApiService"
import useApiHandler from "hooks/useApiHandler"
import useToast from "hooks/useToast"
import Button from "components/Button"
import CustomDialog from "components/Dialog"
import Alert from "components/Alert"

const DeleteEvent = ({ open, setOpen, events = [], getAllEvents = () => {} }) => {
  const { request, loading, error, message } = useApiHandler()
  const { renderSuccessToast } = useToast()

  const [selectedEvent, setSelectedEvent] = useState(null)
  const [selectedEventError, setSelectedEventError] = useState(null)

  const [dropdownItems, setDropdwonItems] = useState([])
  useEffect(() => {
    let allEvents = events.map((evt) => {
      return {
        name: evt.eventName,
        value: evt._id,
      }
    })
    setDropdwonItems(allEvents)
  }, [events])

  const deleteEvent = async () => {
    if (selectedEvent === null || selectedEvent.value === null) {
      setSelectedEventError("Please select event to delete.")
      return
    }

    request(
      {
        api: ApiService.deleteEvent,
        body: selectedEvent.value,
        config: {
          displayError: false,
        },
      },
      (_, err) => {
        if (err) return

        setSelectedEvent(null)
        getAllEvents()

        renderSuccessToast("Event deleted")
      }
    )
  }

  const closeDialog = () => {
    setSelectedEventError(null)
    getAllEvents()
    setOpen(false)
  }

  return (
    <CustomDialog open={open} header="Delete Event" exceptions={["SET_OVERFLOW_UNSET"]}>
      <CustomDialog.Content>
        <form className="space-y-4 md:space-y-6">
          <div>
            <SelectMenu
              label="Events"
              items={dropdownItems}
              onChange={(data) => {
                setSelectedEvent(data)
                setSelectedEventError(null)
              }}
              value={selectedEvent?.value}
              hasError={selectedEventError}
              error={selectedEventError}
              required
            />
          </div>
        </form>

        {error && <Alert type="danger">{message}</Alert>}
      </CustomDialog.Content>
      <CustomDialog.Footer>
        <Button className="sm:ml-3 w-full" loading={loading} onClick={deleteEvent}>
          Continue
        </Button>
        <Button variant="secondary" onClick={closeDialog} className="w-full">
          Cancel
        </Button>
      </CustomDialog.Footer>
    </CustomDialog>
  )
}

export default DeleteEvent
