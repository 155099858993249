import moment from "moment"

const HandleDate = (date) => {
  if (!date) return

  let onlyDate = date.split("T")[0]
  return moment(onlyDate).format("LL")
  // console.log(moment(onlyDate).format('LL'), Date(onlyDate))
}

export { HandleDate }
