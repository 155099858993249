import ApiService from "services/ApiService"
import useApiHandler from "hooks/useApiHandler"
import { useEffect, useState } from "react"

export default function OurSponser() {
  const { request } = useApiHandler()

  const [sponsers, setSponsers] = useState([])

  useEffect(() => {
    getSponsers()
  }, [])

  const getSponsers = () => {
    request(
      {
        api: ApiService.getSponsers,
        config: {
          displayError: false,
        },
      },
      (res, err) => {
        if (err) {
          return
        }

        setSponsers(res?.data?.sponsers)
      }
    )
  }

  if (sponsers.length === 0) {
    return <></>
  }

  return (
    <div className="bg-white p-5 sm:p-5 mt-5">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <h2 className="text-center text-lg font-semibold leading-8 text-gray-900">Our Sponsers</h2>
        <div className="mx-auto mt-10 grid max-w-lg grid-cols-4 items-center gap-x-8 gap-y-10 sm:max-w-xl sm:grid-cols-6 sm:gap-x-10 lg:mx-0 lg:max-w-none lg:grid-cols-5">
          {sponsers.map((sponser, index) => (
            <span className="flex">
              <img
                className="col-span-2 max-h-12 w-full object-contain lg:col-span-1"
                key={index}
                src={ApiService.getMedia(sponser.image)}
                alt={sponser.name}
                width={158}
                height={48}
              />
            </span>
          ))}
        </div>
      </div>
    </div>
  )
}
