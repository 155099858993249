import { useEffect, useState, useCallback } from "react"
import ApiService from "../../../services/ApiService"
import Loader from "components/Loader"
import Button from "components/Button"
import Card from "components/Card"
import EventCard from "../ListCard"
import { useNavigate } from "react-router-dom"
import useApiHandler from "hooks/useApiHandler"

const PreviousEvents = () => {
  const navigate = useNavigate()

  const [events, setEvents] = useState([])
  const { loading, request } = useApiHandler()

  const getPreviousEvents = useCallback(async () => {
    request(
      {
        api: ApiService.getPreviousEvents,
        body: null,
      },
      (response, err) => {
        if (err) return

        setEvents(response.data.events)
      }
    )
  }, [request])

  useEffect(() => {
    getPreviousEvents()
  }, [getPreviousEvents])

  const navigateTo = (to) => {
    navigate(to)
  }

  return (
    <section>
      <Loader open={loading} />

      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:max-w-[100rem] lg:px-8 mt-10 xs:flex-col justify-between md:flex">
        <div className="font-bold text-2xl">Previous Events</div>
        <div className="flex justify-between md:block mt-5 md:mt-0">
          <Button onClick={() => navigateTo("/upcoming-events")}>Upcoming Events</Button>
          <Button onClick={() => navigateTo("/current-events")} className="ml-3">
            Current Events
          </Button>
        </div>
      </div>

      <div className="mx-auto max-w-7xl py-6 px-4 sm:px-6 lg:max-w-[100rem] lg:px-8">
        <div className="mt-6 grid grid-cols-1 gap-y-10 gap-x-6 sm:grid-cols-2 lg:grid-cols-4 xl:gap-x-8">
          {events.map((event, index) => (
            <EventCard event={event} key={index} />
          ))}
        </div>

        {!loading && events.length === 0 && (
          <Card className="text-center p-5">
            <h3 className="text-lg font-medium text-gray-700 ">No events found</h3>
          </Card>
        )}
      </div>
    </section>
  )
}

export default PreviousEvents
