import { useEffect, useState, useCallback } from "react"
import FullCalendar from "@fullcalendar/react"
import dayGridPlugin from "@fullcalendar/daygrid"
import AddEvent from "./AddEvent"
import ApiService from "services/ApiService"
import { useSelector } from "react-redux"
import moment from "moment"
import Loader from "components/Loader"
import useApiHandler from "hooks/useApiHandler"
import DeleteEvent from "./DeleteEvent"

const EventCalender = () => {
  const auth = useSelector((state) => state.auth)
  const [openAddEvent, setOpenAddEvent] = useState(false)
  const [openDeleteEvent, setOpenDeleteEvent] = useState(false)

  const [events, setEvents] = useState([])
  const { request, loading } = useApiHandler()

  const [customButtons, setCustomButtons] = useState({})
  const [headerToolbar, setHeaderToolBar] = useState({})

  const getAllEvents = useCallback(async () => {
    request(
      {
        api: ApiService.getAllEvents,
        body: { filter: "all" },
      },
      (response, err) => {
        if (err) return

        console.log(response.data)
        var duration = moment.duration({ days: 1 })
        let allEvents = response.data.events.map((evnt) => {
          let calenderConfig = {}
          calenderConfig["title"] = evnt.eventName
          calenderConfig["start"] = moment.utc(evnt.eventStartDate).format("YYYY-MM-DD")
          calenderConfig["end"] = moment.utc(evnt.eventEndDate).add(duration).format("YYYY-MM-DD")
          calenderConfig["backgroundColor"] = evnt.completed ? "gray" : "#10a37f"
          calenderConfig["borderColor"] = evnt.completed ? "gray" : "#10a37f"
          if (!evnt.completed) {
            calenderConfig["url"] = `/event-registration/${evnt._id}`
          }
          return {
            ...evnt,
            ...calenderConfig,
          }
        })
        setEvents(allEvents)
      }
    )
  }, [request])

  useEffect(() => {
    if (auth.isLoggedIn && auth.isAdmin) {
      setCustomButtons({
        addEventButton: {
          text: "Add Event",
          click: () => setOpenAddEvent(true),
        },
        deleteEventButton: {
          text: "Delete Event",
          click: () => setOpenDeleteEvent(true),
        },
      })
      setHeaderToolBar({
        left: "addEventButton deleteEventButton",
        center: "title",
        right: "today prevYear,prev,next,nextYear",
      })
    } else {
      setHeaderToolBar({
        center: "title",
        right: "today prevYear,prev,next,nextYear",
      })
    }
  }, [auth.isLoggedIn, auth.isAdmin])

  useEffect(() => {
    getAllEvents()
  }, [getAllEvents])

  return (
    <section>
      <Loader open={loading} />

      <div className="2xl:mx-[20rem] sm:mx-[5rem] my-10">
        {/* <div className="max-w-screen-xl mx-auto my-10"> */}
        <FullCalendar
          plugins={[dayGridPlugin]}
          initialView="dayGridMonth"
          defaultAllDay="true"
          events={events}
          customButtons={customButtons}
          headerToolbar={headerToolbar}
          editable
        />
      </div>

      <AddEvent open={openAddEvent} setOpen={setOpenAddEvent} getAllEvents={getAllEvents} />
      <DeleteEvent
        open={openDeleteEvent}
        setOpen={setOpenDeleteEvent}
        getAllEvents={getAllEvents}
        events={events}
      />
    </section>
  )
}

export default EventCalender
