import { useCallback, useEffect } from "react"
import ApiService from "../../../services/ApiService"
import Constants from "../../../utils/Constants"
import { useNavigate, useParams } from "react-router-dom"
import useApiHandler from "../../../hooks/useApiHandler"
import Alert from "../../../components/Alert"
import Button from "../../../components/Button"
import Card from "../../../components/Card"

const MembershipRenewalSuccess = () => {
  const navigate = useNavigate()
  const { user_id } = useParams()

  const { loading, error, message, request } = useApiHandler()

  const executePayment = useCallback(() => {
    var queryString = window.location.search
    var params = new URLSearchParams(queryString)
    var paymentId = params.get("paymentId")
    var payerId = params.get("PayerID")

    const body = {
      payerId: payerId,
      paymentId: paymentId,
      userId: user_id,
      payment: {
        transactionType: Constants.MEMBERSHIP_TRANSACTION_TYPE_PAYPAL,
        amount: Constants.MEMBERSHIP_AMOUNT,
        intent: Constants.MEMBERSHIP_INTENT,
        currency: Constants.MEMBERSHIP_CURRENCY,
        paymentType: Constants.MEMBERSHIP_PAYMENT_TYPE_RENEWAL,
      },
    }

    request({
      api: ApiService.membershipRenewalSuccess,
      body: body,
      config: {
        displayError: false,
      },
    })
  }, [request, user_id])

  useEffect(() => {
    executePayment()
  }, [executePayment])

  const backTo = () => {
    navigate("/my-membership")
  }

  return (
    <>
      <div className="flex flex-row  justify-center px-6 py-8 mx-auto lg:py-0 my-[6rem]">
        <Card className="sm:max-w-[50rem]">
          <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
            <div className="mt-3 text-center flex flex-col items-center">
              {loading && (
                <>
                  <div className="w-8 h-8 border-4 border-dashed rounded-full animate-spin border-[#10a37f]"></div>
                  <h3 className="text-lg leading-6 font-medium text-gray-900">
                    Completing your payment please wait.
                  </h3>{" "}
                </>
              )}

              {!loading && !error && (
                <>
                  <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      className="text-green-600"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M5 13l4 4L19 7"
                      ></path>
                    </svg>
                  </div>

                  <h3 className="text-lg leading-6 font-medium text-gray-900">
                    Your membership is updated
                  </h3>
                  <div className="mt-2 px-7 py-3">
                    <div className="mt-5">
                      <Button onClick={backTo}>Go back</Button>
                    </div>
                  </div>
                </>
              )}

              {!loading && error && (
                <>
                  <Alert type="danger">{message}</Alert>
                  <div className="mt-2 px-7 py-3">
                    <Button onClick={backTo}>Go back</Button>
                  </div>
                </>
              )}
            </div>
          </div>
        </Card>
      </div>
    </>
  )
}

export default MembershipRenewalSuccess
