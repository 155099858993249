import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import Loader from "../../../components/Loader"
import ApiService from "../../../services/ApiService"
import UploadModal from "./Upload"
import ProjectCard from "../ListItem"
import Card from "../../../components/Card"
import useApiHandler from "hooks/useApiHandler"

const PreviousProjects = () => {
  const { request, loading } = useApiHandler()

  const [projects, setProjects] = useState([])

  const { isAdmin, isLoggedIn } = useSelector((state) => state.auth)
  const [openUploadModal, setOpenUploadModel] = useState(false)

  useEffect(() => {
    getPreviousProjects()
    // eslint-disable-next-line
  }, [])

  const getPreviousProjects = async () => {
    request(
      {
        api: ApiService.getPreviousProjects,
        config: {
          displayError: true,
        },
      },
      (res, err) => {
        if (err) return

        setProjects(res.data.projects)
      }
    )
  }

  return (
    <section>
      <UploadModal
        open={openUploadModal}
        setOpen={setOpenUploadModel}
        onUploadComplete={getPreviousProjects}
      />
      <Loader open={loading} />

      <header className="bg-white shadow">
        <div className="mx-auto py-3 px-4 sm:px-6 lg:px-8 flex justify-between">
          <h1 className="text-2xl font-bold tracking-tight text-gray-900">Previous Projects</h1>

          {isLoggedIn && isAdmin && (
            <div className="flex items-center">
              <div className="ml-3">
                <button
                  onClick={() => setOpenUploadModel(true)}
                  className="inline w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-none"
                >
                  Upload Projects (Excel)
                </button>
              </div>
            </div>
          )}
        </div>
      </header>

      <div className="mx-auto max-w-7xl py-6 px-4 sm:px-6 lg:max-w-[100rem] lg:px-8">
        <div className="mt-6 grid grid-cols-1 gap-y-10 gap-x-6 sm:grid-cols-2 lg:grid-cols-4 xl:gap-x-8">
          {projects.map((project, index) => (
            <ProjectCard key={index} project={project} />
          ))}
        </div>

        {!loading && projects.length === 0 && (
          <Card className="text-center p-5">
            <h3 className="text-lg font-medium text-gray-700 ">No projects found</h3>
          </Card>
        )}
      </div>
    </section>
  )
}

export default PreviousProjects
