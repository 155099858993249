import { useEffect, useState } from "react"
import CauseCard from "../../Causes/ListCard"
import Loader from "../../../components/Loader"
import ApiService from "../../../services/ApiService"
import { HandleDate } from "../../../utils/Helpers"
import PreviousCauses from "./PreviousCauses"
import { useSearchParams } from "react-router-dom"
import Card from "components/Card"

import useApiHandler from "hooks/useApiHandler"

const SubmittedCauses = () => {
  const { request, loading, data } = useApiHandler()

  const [queryParams, _] = useSearchParams()

  const [currentTabItems, setCurrTabItems] = useState([])
  const [currentTab, setCurrTab] = useState(0)
  const [openPreviousCausesWindow, setOpenPreviuousCausesWindow] = useState(false)

  const [pageError, setPageError] = useState("")

  useEffect(() => {
    getAdminCauses()
    // eslint-disable-next-line
  }, [])

  const getAdminCauses = async () => {
    try {
      let windowId = queryParams.get("windowId")
      request(
        {
          api: ApiService.getAdminCauses,
          body: windowId,
          config: {
            displayError: true,
          },
        },
        (res, err) => {
          if (err) return

          if (res?.data?.message === "NO_ACTIVE_SUBMISSION") {
            setPageError("NO_ACTIVE_SUBMISSION")
            return
          }
        }
      )
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    if (currentTab >= 0 && data !== null) {
      onClickTab(currentTab)
    }
    // eslint-disable-next-line
  }, [data])

  const onClickTab = (tabIndex) => {
    setCurrTab(tabIndex)
    if (data) {
      if (tabIndex === 0) setCurrTabItems(data.submitted)
      if (tabIndex === 1) setCurrTabItems(data.approved_for_voting)
      if (tabIndex === 2) setCurrTabItems(data.declined_for_voting)
      if (tabIndex === 3) setCurrTabItems(data.funding)
      if (tabIndex === 4) setCurrTabItems(data.lost)
      if (tabIndex === 5) setCurrTabItems(data.completed)
    }
  }

  if (pageError !== "" && pageError === "NO_ACTIVE_SUBMISSION") {
    return (
      <Card className="text-center p-5">
        <h3 className="text-lg font-medium text-gray-700 ">
          There is no active submission at the moment.
        </h3>
      </Card>
    )
  }

  return (
    <section className="p-5">
      <Loader open={loading} />
      <PreviousCauses
        open={openPreviousCausesWindow}
        setOpen={setOpenPreviuousCausesWindow}
        getAdminCauses={getAdminCauses}
      />

      <div className="mx-auto max-w-7xl py-6 px-4 sm:px-6 lg:max-w-[100rem] lg:px-8">
        <div className="mb-5 text-xs">
          Current Cause Window
          {data?.causeWindow && (
            <div className="bg-yellow-200 px-2 ml-1 py-1 rounded text-yellow-800 inline text-xs">
              {HandleDate(data?.causeWindow?.causeStartDate)} ------{" "}
              {HandleDate(data?.causeWindow?.causeEndDate)}
            </div>
          )}
          <button
            type="button"
            className="flex w-full justify-center rounded-md border border-transparent bg-[#10a37f] hover:opacity-80 px-4 py-1 mt-3 text-base font-medium text-white shadow-sm focus:outline-none sm:w-auto sm:text-sm"
            onClick={() => setOpenPreviuousCausesWindow(true)}
          >
            View previous causes
          </button>
        </div>

        <span className="text-xs sm:hidden">(Scroll right to find more tabs)</span>
        <div className="xl:w-full xl:mx-0 h-12 sm:block bg-white shadow rounded">
          <div className="flex border-b px-5 flex-nowrap overflow-x-auto">
            <button
              onClick={() => onClickTab(0)}
              className={`focus:outline-none focus:text-[#10a37f] text-sm border-[#10a37f] pt-3 rounded-t mr-12 cursor-pointer hover:text-[#10a37f] ${
                currentTab === 0 ? `text-[#10a37f]` : `text-gray-600`
              }`}
            >
              <div className="flex items-center mb-3">
                <span className="ml-1 font-normal whitespace-nowrap">
                  Submitted ({data?.submitted.length}){" "}
                </span>
              </div>
              <div
                className={`w-full h-1 bg-[#10a37f] rounded-t-md ${currentTab !== 0 && "hidden"}`}
              ></div>
            </button>
            <button
              onClick={() => onClickTab(1)}
              className={`focus:outline-none focus:text-[#10a37f] text-sm border-[#10a37f] pt-3 rounded-t mr-12 cursor-pointer hover:text-[#10a37f] ${
                currentTab === 1 ? `text-[#10a37f]` : `text-gray-600`
              }`}
            >
              <div className="flex items-center mb-3">
                <span className="ml-1 font-normal whitespace-nowrap">
                  Approved for voting ({data?.approved_for_voting.length}){" "}
                </span>
              </div>
              <div
                className={`w-full h-1 bg-[#10a37f] rounded-t-md ${currentTab !== 1 && "hidden"}`}
              ></div>
            </button>
            <button
              onClick={() => onClickTab(2)}
              className={`focus:outline-none focus:text-[#10a37f] text-sm border-[#10a37f] pt-3 rounded-t mr-12 cursor-pointer hover:text-[#10a37f] ${
                currentTab === 2 ? `text-[#10a37f]` : `text-gray-600`
              }`}
            >
              <div className="flex items-center mb-3">
                <span className="ml-1 font-normal whitespace-nowrap">
                  Declined for voting ({data?.declined_for_voting.length}){" "}
                </span>
              </div>
              <div
                className={`w-full h-1 bg-[#10a37f] rounded-t-md ${currentTab !== 2 && "hidden"}`}
              ></div>
            </button>
            <button
              onClick={() => onClickTab(3)}
              className={`focus:outline-none focus:text-[#10a37f] text-sm border-[#10a37f] pt-3 rounded-t mr-12 cursor-pointer hover:text-[#10a37f] ${
                currentTab === 3 ? `text-[#10a37f]` : `text-gray-600`
              }`}
            >
              <div className="flex items-center mb-3">
                <span className="ml-1 font-normal whitespace-nowrap">
                  Funding ({data?.funding.length}){" "}
                </span>
              </div>
              <div
                className={`w-full h-1 bg-[#10a37f] rounded-t-md ${currentTab !== 3 && "hidden"}`}
              ></div>
            </button>
            <button
              onClick={() => onClickTab(4)}
              className={`focus:outline-none focus:text-[#10a37f] text-sm border-[#10a37f] pt-3 rounded-t mr-12 cursor-pointer hover:text-[#10a37f] ${
                currentTab === 4 ? `text-[#10a37f]` : `text-gray-600`
              }`}
            >
              <div className="flex items-center mb-3">
                <span className="ml-1 font-normal whitespace-nowrap">Lost ({data?.lost.length}) </span>
              </div>
              <div
                className={`w-full h-1 bg-[#10a37f] rounded-t-md ${currentTab !== 4 && "hidden"}`}
              ></div>
            </button>
            <button
              onClick={() => onClickTab(5)}
              className={`focus:outline-none focus:text-[#10a37f] text-sm border-[#10a37f] pt-3 rounded-t mr-12 cursor-pointer hover:text-[#10a37f] ${
                currentTab === 5 ? `text-[#10a37f]` : `text-gray-600`
              }`}
            >
              <div className="flex items-center mb-3">
                <span className="ml-1 font-normal whitespace-nowrap">
                  Completed ({data?.completed.length}){" "}
                </span>
              </div>
              <div
                className={`w-full h-1 bg-[#10a37f] rounded-t-md ${currentTab !== 5 && "hidden"}`}
              ></div>
            </button>
          </div>
        </div>

        {currentTabItems.length === 0 && (
          <div
            className="p-4 my-4 text-sm text-yellow-800 rounded-lg border bg-yellow-50 w-full"
            role="alert"
          >
            <span className="font-medium">No causes found.</span>
          </div>
        )}

        <div className="mt-6 grid grid-cols-1 gap-y-10 gap-x-6 sm:grid-cols-2 lg:grid-cols-4 xl:gap-x-8">
          {currentTabItems.map((cause, index) => (
            <CauseCard cause={cause} key={index} admin={true} getCauses={getAdminCauses} />
          ))}
        </div>
      </div>
    </section>
  )
}

export default SubmittedCauses
