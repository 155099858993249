import toast from "react-hot-toast"

const useToast = () => {
  const renderSuccessToast = (message) => {
    toast.success(message)
  }

  const renderErrorToast = (message) => {
    toast.error(message)
  }

  return {
    renderSuccessToast,
    renderErrorToast,
  }
}

export default useToast
