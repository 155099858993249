import Constants from "../../utils/Constants"

const Offline = () => {
  return (
    <>
      <div className="flex flex-row  justify-center px-6 py-8 mx-auto lg:py-0 my-[6rem]">
        <div className="w-full bg-white rounded-lg shadow border md:mt-0 sm:max-w-[50rem] xl:p-0">
          <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
            <div className="mt-3 flex flex-col items-center">
              <h3 className="text-lg leading-6 font-medium text-gray-900">
              Thank you for your contribution!
              </h3>
              <div className="mt-2 py-3">
                <p className="text-md text-gray-500">
                  You can complete the donation via Zelle to contact@dhvanifoundation.org, or by mailing a check to:
                  <b>
                    <br />
                  Dhvani Foundation <br />
                  3910 Old Highway 94 S, STE 110 <br />
                  Saint Charles, MO 63304 <br />
                  United States. <br />
                  </b>
                </p>
                <p className="text-md text-gray-500 mt-5">
                Please make the check payable to "Dhvani Foundation" and include  your name in the comments. If paying via Zelle, include your name in the comments.
                </p>
                <p className="text-md text-gray-500 mt-5">For additional questions, please call us at 315-266-9777 or email us at contact@dhvanifoundation.org.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Offline
