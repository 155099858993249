import { Fragment, useEffect, useState } from "react"

const Alert = ({ children, type = "success", className = "" }) => {
  const [cls, setCls] = useState("")
  useEffect(() => {
    if (type === "success") {
      setCls("bg-green-100 text-green-800")
    } else if (type === "danger") {
      setCls("bg-red-100 text-red-800")
    } else {
      setCls("bg-yellow-100 text-yellow-800")
    }
  }, [children, type])

  return (
    <Fragment>
      {children && (
        <div
          className={`rounded-md py-2 px-6 text-base text-center relative font-medium ${cls} ${className}`}
          role="alert"
        >
          <b>{children}</b>
          {/* <span className="absolute right-0 mt-[0.15rem] mx-3 cursor-pointer" onClick={onClose}>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                        </svg>
                    </span> */}
        </div>
      )}
    </Fragment>
  )
}

export default Alert
