import ImageCard from "../components/Image"

const Images = ({ images = [] }) => {
  return (
    <>
      <div className="container mx-auto space-y-2 lg:space-y-0 lg:gap-2 lg:grid lg:grid-cols-3">
        {images.length > 0 && images.map((image, index) => <ImageCard key={index} data={image} />)}

        {images.length === 0 && <div>No images found.</div>}
      </div>
    </>
  )
}

export default Images
