import { Fragment, useEffect, useState } from "react"
import Loading from "../Svgs/Loading"

const Button = ({
  children,
  className = "",
  onClick = () => {},
  style = {},
  loading = false,
  loadingText = "Loading...",
  disabled = false,
  variant = "primary",
  fullWidth = false,
  ref = null,
  bg = "",
  textColor = "",
}) => {
  const [isDisabled, setIsDisabled] = useState(false)

  let classes = ""
  if (variant === "primary")
    classes = `disabled:cursor-not-allowed disabled:opacity-60 inline-flex  justify-center rounded-md border border-transparent ${
      bg === "" ? "bg-[#10a37f]" : bg
    } bg-[#10a37f] hover:opacity-80 px-4 py-2 text-base font-medium ${
      textColor === "" ? "text-white" : textColor
    } focus:outline-none sm:text-sm ${fullWidth ? "sm:w-full w-full" : "sm:w-auto"}`
  if (variant === "secondary")
    classes =
      "mt-3 inline-flex justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none  sm:mt-0 sm:w-auto sm:text-sm"

  classes += " " + className

  useEffect(() => {
    if (typeof disabled === "function") {
      setIsDisabled(disabled())
    } else {
      setIsDisabled(disabled)
    }
  }, [disabled])

  return (
    <button
      type="button"
      onClick={onClick}
      className={classes}
      style={style}
      disabled={loading || isDisabled}
      ref={ref}
    >
      {!loading && children}
      {loading && (
        <Fragment>
          <Loading />
          {loadingText}
        </Fragment>
      )}
    </button>
  )
}

export default Button
