import { useFormik } from "formik"
import * as Yup from "yup"
import TextField from "../../../../components/TextField"
import AddTeammate from "../AddTeammate"
import { useState } from "react"
import Table from "../../../../components/Table/Table"
import commonFunctions from "utils/CommonFunctions"
import Button from "components/Button"
import RadioButton from "components/RadioButton"

const Cricket = ({ registerEvent, event = null }) => {
  const [openAddPlayer, setOpenAddPlayer] = useState(false)
  const [category, setCategory] = useState(null)
  const [categoryError, setCategoryError] = useState(false)
  const [registerThrough, setRegisterThrough] = useState(null)
  const [players, setPLayers] = useState([])
  const costType = "default"

  const columns = [
    {
      name: "Firstname",
      selector: "firstName",
      cell: (row) => <span>{row.firstName}</span>,
    },
    {
      name: "Lastname",
      selector: "lastName",
    },
    {
      name: "Contact number",
      selector: "contactNumber",
    },
    {
      name: "Date of birth",
      selector: "dob",
    },
    {
      name: "Actions",
      selector: "action",
      style: { width: "40px" },
      cell: (row, index) => (
        <div className="flex items-center space-x-2">
          <button
            title="Mark As Unread"
            onClick={(e) => {
              e.preventDefault()
              handleRemovePLayer(index)
            }}
            className="text-gray-700 px-2 py-1 border border-gray-300 rounded-lg shadow hover:bg-gray-200 transition duration-100"
          >
            Remove
          </button>
        </div>
      ),
    },
  ]

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      teamName: "",
      captainName: "",
      email: "",
      confirmEmail: "",
      phone: "",
      alternatePhone: "",
      grade: null,
    },
    validationSchema: Yup.object().shape({
      teamName: Yup.string().required("Required"),
      captainName: Yup.string().required("Required"),
      email: Yup.string().required("Required"),
      confirmEmail: Yup.string()
        .required("Required")
        .oneOf([Yup.ref("email"), null], "Email must match"),
      phone: Yup.string().required("Required"),
      // grade: ['kids-13-under', 'kids-17-under'].includes(category) ? Yup.string().required("Required") : null,
    }),
    onSubmit: (values) => {
      if (category === 'mens' || category === 'kids 17 and under') {
        alert('Category selected was closed')
      } else if (category === null) {
        setCategoryError(true)
      } else {
        values["players"] = players
        values["registerThrough"] = registerThrough
        values["eventCost"] = event.eventCost[costType]
        values["teamType"] = category
        values["phone"] = commonFunctions.phoneNumberToText(values["phone"])
        values["alternatePhone"] = commonFunctions.phoneNumberToText(values["alternatePhone"])
        registerEvent(values)
      }
    },
  })

  const handleAddPlayer = (player) => {
    setPLayers([...players, player])
  }

  const handleRemovePLayer = (index) => {
    let newPlayers = [...players]
    newPlayers.splice(index, 1)
    setPLayers(newPlayers)
  }

  return (
    <section>
      <AddTeammate open={openAddPlayer} setOpen={setOpenAddPlayer} handleAddPlayer={handleAddPlayer} eventType="cricket" category={category} />

      <form className="space-y-4 md:space-y-6">
        <div className="grid grid-cols-1 gap-6 mt-4 sm:grid-cols-2">
          <div>
            <TextField
              label="Name of your team"
              name="teamName"
              type="text"
              value={formik.values.teamName}
              onChange={formik.handleChange}
              error={formik.errors.teamName}
              touched={formik.touched.teamName}
              onBlur={formik.handleBlur}
              required
            />
          </div>
          <div>
            <TextField
              label="Captain name"
              name="captainName"
              type="text"
              value={formik.values.captainName}
              onChange={formik.handleChange}
              error={formik.errors.captainName}
              touched={formik.touched.captainName}
              onBlur={formik.handleBlur}
              required
            />
          </div>

          <div>
            <TextField
              label="Email"
              name="email"
              type="text"
              value={formik.values.email}
              onChange={formik.handleChange}
              error={formik.errors.email}
              touched={formik.touched.email}
              onBlur={formik.handleBlur}
              required
            />
          </div>
          <div>
            <TextField
              label="Confirm Email"
              name="confirmEmail"
              type="text"
              value={formik.values.confirmEmail}
              onChange={formik.handleChange}
              error={formik.errors.confirmEmail}
              touched={formik.touched.confirmEmail}
              onBlur={formik.handleBlur}
              required
            />
          </div>

          <div>
            <TextField
              label="Phone number"
              name="phone"
              type="text"
              placeholder="(XXX) XXX-XXXX"
              phoneNumberFormat="true"
              value={formik.values.phone}
              onChange={formik.handleChange}
              error={formik.errors.phone}
              touched={formik.touched.phone}
              onBlur={formik.handleBlur}
              required
            />
          </div>
          <div>
            <TextField
              label="Alternate phone number"
              name="alternatePhone"
              type="text"
              placeholder="(XXX) XXX-XXXX"
              phoneNumberFormat="true"
              value={formik.values.alternatePhone}
              onChange={formik.handleChange}
              error={formik.errors.alternatePhone}
              touched={formik.touched.alternatePhone}
              onBlur={formik.handleBlur}
            />
          </div>
        </div>

        <div>
          <label htmlFor={"category"} className="block text-sm font-medium text-gray-700 mb-3">
            Team Type
            <span className="text-md text-red-500">*</span>
          </label>
          <div className="flex items-center mb-2">
            <RadioButton
              disabled
              name="mens"
              value="mens"
              onChange={(e) => {
                setCategory(e.target.value)
                setCategoryError(false)
              }}
              error={categoryError}
            >
              Men's
            </RadioButton>
          </div>
          <div className="flex items-center mb-2">
            <RadioButton
              name="womens"
              value="womens"
              onChange={(e) => {
                setCategory(e.target.value)
                setCategoryError(false)
              }}
              error={categoryError}
            >
              Women's
            </RadioButton>
          </div>
          <div className="flex items-center mb-2">
            <RadioButton
              name="kids-13-under"
              value="kids 13 and under"
              onChange={(e) => {
                setCategory(e.target.value)
                setCategoryError(false)
              }}
              error={categoryError}
            >
              Kid's 13 and under
            </RadioButton>
          </div>
          <div className="flex items-center mb-2">
            <RadioButton
              disabled
              name="kids-17-under"
              value="kids 17 and under"
              onChange={(e) => {
                setCategory(e.target.value)
                setCategoryError(false)
              }}
              error={categoryError}
            >
              Kid's 17 and under
            </RadioButton>
          </div>
          {/* <div className="flex items-center">
                        <input
                            id="mixed"
                            type="radio"
                            value="mixed"
                            name="default-radio"
                            onChange={e => {
                                setCategory(e.target.value)
                                setCategoryError(false)
                            }}
                            className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 focus:ring-2"
                        />
                        <label
                            htmlFor="mixed"
                            className={`ml-2 text-sm font-medium  ${categoryError ? 'text-red-600' : 'text-gray-900'}`}
                        >
                            Mixed
                        </label>
                    </div> */}
          {categoryError && <span className="text-red-600 text-xs">Required</span>}
        </div>

        {/* {['kids-13-under', 'kids-17-under'].includes(category) && <div className="grid grid-cols-1 gap-6 mt-4 sm:grid-cols-2">
          <div className="mt-5">
          
          <label  className="block text-sm font-medium text-gray-700 mb-3">
            Which grade will your child be entering
            <span className="text-md text-red-500">*</span>
          </label>
            <TextField
              label="Grade"
              name="grade"
              type="number"
              value={formik.values.grade}
              onChange={formik.handleChange}
              error={formik.errors.grade}
              touched={formik.touched.grade}
              onBlur={formik.handleBlur}
              required
            />
          </div>
        </div>} */}

        <div>
          <label htmlFor={"category"} className="block text-sm font-medium text-gray-700 mb-3">
            Team Info
          </label>
          <Button
            onClick={() => {
              if (players.length >= event?.maxPlayers) {
                alert("You can only add up to " + event?.maxPlayers + " players. ")
              } else {
                setOpenAddPlayer(true)
              }
            }}
          >
            Add Player
          </Button>
          <div className="text-sm">You can add players up to {event?.maxPlayers}</div>

          <Table columns={columns} data={players} loading={false} disableFooter={true} />
        </div>
      </form>

      <div className="bg-gray-50 py-3 sm:flex sm:flex-row-reverse">
        <Button
          className="sm:mb-0 sm:ml-3 w-full mb-3"
          onClick={() => {
            setRegisterThrough("OFFLINE")
            formik.handleSubmit()
          }}
        >
          Register Offline
        </Button>

        <Button
          className="w-full"
          onClick={() => {
            setRegisterThrough("PAYPAL")
            formik.handleSubmit()
          }}
        >
          Register With Paypal
        </Button>
      </div>
    </section>
  )
}

export default Cricket
