const Folder = ({ folders = [], onClick = () => {} }) => {
  return (
    <>
      <div className="grid md:grid-cols-4 md:gap-4">
        {folders.length > 0 &&
          folders.map((folder, index) => (
            <div
              key={index}
              className="bg-white rounded-md border overflow-hidden hover:ring-2 ring-[#10a37f] cursor-pointer mt-2"
              onClick={() => onClick(folder.path)}
            >
              <div className="p-4">
                <h3 className="font-medium text-gray-700"> {folder.folderName} </h3>
              </div>
            </div>
          ))}
        {folders.length === 0 && <div>No folders found.</div>}
      </div>
    </>
  )
}

export default Folder
