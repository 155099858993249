import { useFormik } from "formik"
import ApiService from "../../services/ApiService"
import TextField from "../../components/TextField"
import * as Yup from "yup"
import Card from "../../components/Card"
import Button from "../../components/Button"
import Alert from "../../components/Alert"
import CommonFunctions from "../../utils/CommonFunctions"

import useApiHandler from "../../hooks/useApiHandler"

const VerificationMail = () => {
  const queryParams = CommonFunctions.getQueryParams()
  const { loading, error, message, request } = useApiHandler()

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: queryParams.get("input") ? queryParams.get("input") : "",
    },
    validationSchema: Yup.object().shape({
      email: Yup.string().required("Required"),
    }),
    onSubmit: (values) => {
      sendVerificationMail(values)
    },
  })

  const sendVerificationMail = async (values) => {
    const { email } = values
    request({
      api: ApiService.sendVerificationMail,
      body: { email },
      config: { displayError: false },
    })
  }

  return (
    <section>
      <div className="flex flex-col items-center px-6 py-8 mx-auto lg:py-0 my-[6rem]">
        <Card className="sm:max-w-md shadow">
          <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
            <h1 className="text-xl font-medium leading-tight text-gray-700 md:text-2xl">
              Verify your account
            </h1>
            <form className="space-y-4 md:space-y-6">
              <div className="col-span-6 sm:col-span-3">
                <TextField
                  label="Email or Username"
                  name="email"
                  type="text"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  error={formik.errors.email}
                  touched={formik.touched.email}
                  onBlur={formik.handleBlur}
                />
              </div>

              {!loading && !error && message !== null && <Alert type="success">{message}</Alert>}

              {!loading && error && message !== null && <Alert type="danger">{message}</Alert>}

              <Button
                disabled={loading}
                loading={loading}
                loadingText="Sending mail..."
                onClick={formik.handleSubmit}
              >
                Send Verification Link
              </Button>

              <div className="text-sm font-light text-gray-500">
                <a href="/signin" className="font-medium text-primary-600 hover:underline">
                  Go back
                </a>
              </div>
            </form>
          </div>
        </Card>
      </div>
    </section>
  )
}

export default VerificationMail
