import moment from "moment"

const formatDate = (date) => {
  const parsedDate = moment.utc(date, "ddd MMM DD YYYY HH:mm:ss [GMT]ZZ (z)")
  parsedDate.startOf("day")
  return parsedDate.toISOString()
}

const phoneNumberFormat = (number) => {
  const currentValue = number.replace(/\D/g, "")
  let cvLength = currentValue.length

  if (currentValue.length < 4) return currentValue
  if (cvLength < 7) return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3)}`

  return `(${currentValue.substring(0, 3)}) ${currentValue.substring(3, 6)}-${currentValue.substring(
    6,
    10
  )}`
}

const phoneNumberToText = (number) => {
  let currentValue = number.replace(/\D/g, "")
  return currentValue
}

const formatDateWithAbbrivated = (date) => {
  const inputDateString = "2023-07-27T00:00:00.000Z"
  const dateObject = new Date(inputDateString)

  // Step 2: Create a function to get the abbreviated month name
  function getAbbreviatedMonthName(monthIndex) {
    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]
    return months[monthIndex]
  }

  // Step 3: Get the components needed for the desired format
  const dayOfWeek = dateObject.toLocaleString("en-US", { weekday: "short" })
  const month = getAbbreviatedMonthName(dateObject.getMonth())
  const day = dateObject.getDate()
  const year = dateObject.getFullYear()
  const time = dateObject.toLocaleString("en-US", { timeZoneName: "short" })

  // Step 4: Construct the desired format
  const desiredFormat = `${dayOfWeek} ${month} ${day} ${year} ${time}`

  console.log(desiredFormat)
}

const getQueryParams = () => {
  let params = new URLSearchParams(window.location.search)
  return params
}

const commonFunctions = {
  formatDate,
  formatDateWithAbbrivated,
  getQueryParams,
  phoneNumberFormat,
  phoneNumberToText,
}

export default commonFunctions
