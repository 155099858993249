import { useState } from "react"
import * as XLSX from "xlsx"
import ApiService from "services/ApiService"
import Dialog from "components/Dialog"
import Button from "components/Button"
import FileSelector from "components/FileSelector"
import Alert from "components/Alert"
import ProgressLoading from "components/ProgressLoading"
import useApiHandler from "hooks/useApiHandler"
import useToast from "hooks/useToast"

export default function Modal({ open, setOpen, onUploadComplete }) {
  const { request, loading } = useApiHandler()

  const { renderSuccessToast } = useToast()

  const [file, setFile] = useState(null)
  const [uploadError, setUploadError] = useState(false)

  const onChangeFile = (e) => {
    setUploadError(false)
    setFile(e.target.files[0])
  }

  const onSelectFile = () => {
    if (!file) {
      setUploadError("Please select file")
      return
    }

    const reader = new FileReader()
    const rABS = !!reader.readAsBinaryString

    reader.onload = (e) => {
      const bstr = e.target.result
      const wb = XLSX.read(bstr, { type: rABS ? "binary" : "array" })

      const wsname = wb.SheetNames[0]
      const ws = wb.Sheets[wsname]

      const data = XLSX.utils.sheet_to_json(ws)
      uploadFile(data)
    }

    if (rABS) {
      reader.readAsBinaryString(file)
    } else {
      reader.readAsArrayBuffer(file)
    }
  }

  const uploadFile = async (projects) => {
    request(
      {
        api: ApiService.uploadMembers,
        body: projects,
        config: {
          displayError: false,
        },
      },
      (_, err) => {
        if (err) {
          return
        }

        onUploadComplete()
        setOpen(false)

        renderSuccessToast("Members uploaded.")
      }
    )
  }

  return (
    <Dialog open={open} header="Upload members">
      <Dialog.Content>
        {!loading && (
          <FileSelector
            description="Choose a file to upload"
            preview={false}
            onSelected={onChangeFile}
            onClear={() => setFile(null)}
            accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
          />
        )}

        <Alert type="error">{uploadError}</Alert>
        {loading && <ProgressLoading />}
      </Dialog.Content>
      <Dialog.Footer>
        <Button className="sm:ml-3 w-full" onClick={onSelectFile}>
          Upload
        </Button>
        <Button
          variant="secondary"
          className="w-full"
          onClick={() => {
            setFile(null)
            setOpen(false)
          }}
        >
          Cancel
        </Button>
      </Dialog.Footer>
    </Dialog>
  )
}
